import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InvoicesService } from '../invoices.service';
import { AppRouteNames } from '../../../../app-route-names';
import { InvoiceDto } from '../../../../../../../shared/models/invoice-dto';

@Component({
  selector: 'app-invoice-single-view',
  templateUrl: './invoice-single-view.component.html',
  styleUrl: './invoice-single-view.component.less',
})
export class InvoiceSingleViewComponent {
  isLoaded: boolean = false;
  singleInvoice?: InvoiceDto;
  constructor(
    private route: ActivatedRoute,
    private invoicesService: InvoicesService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.invoicesService.getInvoiceDetails(params['id']).subscribe({
        next: (invoice) => {
          this.singleInvoice = invoice;
          this.isLoaded = true;
        },
        error: (err) => {
          this.router.navigate([AppRouteNames.PARTNER_INVOICES]);
        },
      });
    });
  }
}
