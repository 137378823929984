import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../AuthComponents/auth/auth.service';

@Injectable({ providedIn: 'root' })
class PartnerPermissionService {
  user = {
    authLevel: '',
  };

  constructor(private authService: AuthService) {}

  canActivate(state: RouterStateSnapshot): boolean {
    this.user = this.authService.getUserData();
    if (this.user.authLevel == 'PARTNER') {
      this.authService.navigateToFirstPage();
      return false;
    }
    return true;
  }
}

export const PartnerPermissionGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean => {
  return inject(PartnerPermissionService).canActivate(state);
};
