import { Component, OnInit } from '@angular/core';
import { AuthService } from './AuthComponents/auth/auth.service';
import { locale } from "devextreme/localization";
import {AppConstants} from "../../../shared/app-constants";
import {registerLocaleData} from "@angular/common";
import localeHu from '@angular/common/locales/hu';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.less',
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private translate: TranslateService
  ) {
    this.translate.addLangs(['en', 'ro','sr','hu']);
    this.translate.setDefaultLang('hu');
    let user = this.authService.getUserData();
    if (user) {
      this.translate.use(user.language.toLowerCase());
    }else {
      if (this.translate.getBrowserLang() != undefined) {
        this.translate.use(<string>this.translate.getBrowserLang());
      }
    }
  }

  ngOnInit(): void {
    registerLocaleData(localeHu);
    locale(AppConstants.DEFAULT_LOCALE);
    this.authService.autoLogin();
  }
}
