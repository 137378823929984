import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { AppConstants } from '../../../../../../../shared/app-constants';
import { StandMoveDto } from '../../../../../../../shared/models/stand-move-dto';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../../../data.service';
import { StandsService } from '../stands.service';
import {
  FocusedRowChangedEvent,
  SelectionChangedEvent,
} from 'devextreme/ui/data_grid';
import { Router } from '@angular/router';
import { AppRouteNames } from '../../../../app-route-names';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-stands-list',
  templateUrl: './stands-list.component.html',
  styleUrl: './stands-list.component.less',
})
export class StandsListComponent {
  @ViewChild('standsListGrid') standsListGrid?: DxDataGridComponent;
  @Output() standToDetails = new EventEmitter<StandMoveDto>();
  standsList = {} as CustomStore;
  sendBackStandsData: { sendGlassIdList: number[] } = {
    sendGlassIdList: [],
  };

  isDesktop: boolean = false;
  isFullWidth: boolean = true;
  isSendBackPopup: boolean = false;
  isAnyStandSelected: boolean = false;

  standsNumber: number = 0;

  private readonly TYPE = 'stand_move/list';
  private readonly LIST_ID = 'id';
  readonly AppConstants = AppConstants;

  constructor(
    public standsService: StandsService,
    public http: HttpClient,
    public dataService: DataService,
    private router: Router
  ) {
    this.loadList();
  }

  ngOnInit(): void {
    this.isDesktop = this.dataService.isDesktop();
  }

  ngAfterViewInit(): void {
    this.dataService.defaultGrid(this.standsListGrid!, this.TYPE);
  }

  loadList() {
    this.standsList = this.dataService.fetchCustomStore(
      this.http,
      this.TYPE,
      this.LIST_ID
    );
  }

  closePopup() {
    this.isSendBackPopup = false;
  }

  setFullWidth() {
    this.standToDetails.emit(undefined);
    this.standsListGrid!.focusedRowIndex = -1;
    this.isFullWidth = true;
  }

  anyStandSelected(e: SelectionChangedEvent) {
    this.isAnyStandSelected = e.selectedRowsData.length > 0;
  }

  selectedSendBack() {
    this.sendBackStandsData.sendGlassIdList =
      this.standsListGrid!.instance.getSelectedRowsData().map(
        (item: StandMoveDto) => item.id
      );
    this.standsNumber =
      this.standsListGrid?.instance.getSelectedRowsData().length!;
    this.isSendBackPopup = true;
  }

  sendBackStands() {
    console.log(this.sendBackStandsData);
  }

  checkStand(event: FocusedRowChangedEvent) {
    if (event.row) {
      this.standsService.fetchStandDetails(event.row.data.id).subscribe({
        next: (stand) => {
          if (this.dataService.isMobile()) {
            this.router.navigate([
              `${AppRouteNames.PARTNER_STAND_GLASSES}/${stand.id}`,
            ]);
            return;
          }
          this.standToDetails.emit(stand);
          this.standsService.isSummaryVisible.next(false);
          this.isFullWidth = false;
        },
      });
    }
  }

  stateSave = (state: any) => {
    this.dataService.stateStoringSave(this.TYPE, state);
  };
}
