<div class="generic-block h100p justify-vertically">
  <div class="order-details-card-top">
    <div class="generic-block-header-row">
      <h3 class="card-title teal-5">
        {{ "orders.details.title" | translate }}
      </h3>
      <div class="button-row gap-2">
        <dx-button class="tertiary font-lg" (click)="copyUrl()"
          ><i class="bi bi-copy"></i
          ><span>{{
            "orders.details.copyDetailsUrl" | translate
          }}</span></dx-button
        >
        <dx-button
          class="tertiary font-lg"
          (click)="goToUrl()"
          *ngIf="!isSingleView"
        >
          <i class="bi bi-box-arrow-up-right"></i>
        </dx-button>
      </div>
    </div>
    <div class="detailer-featured with-icon m-bottom-1-5">
      <div class="icon">
        <i
          class="bi bi-arrow-clockwise"
          *ngIf="(orderToDetails?.order)!.status == 'IN_PROGRESS'"
        ></i>
        <i
          class="bi bi-clock-history"
          *ngIf="(orderToDetails?.order)!.status == 'CONFIRMED'"
        ></i>
        <i
          class="bi bi-truck"
          *ngIf="(orderToDetails?.order)!.status == 'UNDER_DELIVERY'"
        ></i>
        <i
          class="bi bi-check-circle"
          *ngIf="(orderToDetails?.order)!.status == 'COMPLETED'"
        ></i>
        <i
          class="bi bi-x-circle"
          *ngIf="(orderToDetails?.order)!.status == 'DELETED'"
        ></i>
      </div>

      <div class="order-main-details-card-body-data">
        <p class="detailer-featured-title">
          {{ "orders.details.confirmationName" | translate }}
        </p>
        <p class="detailer-featured-value">
          {{ orderToDetails?.order?.confirmationName }}
        </p>
      </div>
      <div
        class="order-main-details-card-body-data"
        *ngIf="orderToDetails?.order?.expectedDeliveryDate"
      >
        <p class="detailer-featured-title">
          {{ "orders.details.expectedDeliveryDate" | translate }}
        </p>
        <p class="detailer-featured-value">
          {{ orderToDetails?.order?.expectedDeliveryDate | ceDateFormat }}
        </p>
      </div>
      <div class="order-main-details-card-body-data">
        <p class="detailer-featured-title">
          {{ "orders.details.status" | translate }}
        </p>
        <p class="detailer-featured-value">
          {{ getStatusText((orderToDetails?.order)!.status) }}
        </p>
      </div>
      <div
        class="order-main-details-card-body-data"
        *ngIf="orderToDetails?.delivery?.deliveryDate"
      >
        <p class="detailer-featured-title">
          {{ "orders.details.estimatedDeliveryDate" | translate }}
        </p>
        <p class="detailer-featured-value">
          {{ orderToDetails?.delivery?.deliveryDate | ceDateTimeFormat }}
        </p>
      </div>
    </div>
    <div
      class="delivery-buttons button-row m-bottom-1-5"
      *ngIf="isMobile && (orderToDetails?.order)!.status == 'UNDER_DELIVERY'"
    >
      <dx-button
        (onClick)="callPhone()"
        class="primary btn-sm font-lg bg-teal-5"
      >
        <i class="bi bi-telephone-outbound"></i>
        <span>>{{ "orders.details.callSupplier" | translate }}</span>
      </dx-button>
      <a
        href="tel:{{ orderToDetails?.delivery?.supplierTel }}"
        class="phone-number"
      >
        {{ orderToDetails?.delivery?.supplierTel }}</a
      >
    </div>
    <div class="generic-data-group col-2">
      <div class="generic-data-block">
        <p class="generic-data-block-title">
          {{ "orders.details.dateOfOrder" | translate }}
        </p>
        <p class="generic-data-block-value">
          {{ orderToDetails?.order?.dateOfOrder | ceDateFormat }}
        </p>
      </div>
      <div class="generic-data-block">
        <p class="generic-data-block-title">
          {{ "orders.details.amount" | translate }}
        </p>
        <p class="generic-data-block-value">
          {{ orderToDetails?.order?.amount | ceNumberFormat }}
          {{ orderToDetails?.order?.currency }}
        </p>
      </div>
      <div class="generic-data-block">
        <p class="generic-data-block-title">
          {{ "orders.details.squareMeter" | translate }}
        </p>
        @if (orderToDetails?.order?.squareMeter) {
        <p class="generic-data-block-value">
          {{ orderToDetails?.order?.squareMeter | ceNumberFormat }} m<sup
            >2</sup
          >
        </p>
        } @else {
        <p></p>
        }
      </div>
      <div class="generic-data-block">
        <p class="generic-data-block-title">
          {{ "orders.details.standGlassList" | translate }}
        </p>
        @for (stand of orderToDetails?.standNumberList; track stand) {
        <p class="generic-data-block-value">{{ stand }}</p>
        }
      </div>
      <div class="generic-data-block">
        <p class="generic-data-block-title">
          {{ "orders.details.dateOfLastStatusChange" | translate }}
        </p>
        <p class="generic-data-block-value">
          {{ orderToDetails?.order?.dateOfLastStatusChange | ceDateFormat }}
        </p>
      </div>
      <div class="generic-data-block">
        <p class="generic-data-block-title">
          {{ "orders.details.confirmationDate" | translate }}
        </p>
        <p class="generic-data-block-value">
          {{ orderToDetails?.order?.confirmationDate | ceDateFormat }}
        </p>
      </div>
      <div class="generic-data-block">
        <p class="generic-data-block-title">
          {{ "orders.details.comment" | translate }}
        </p>
        <p class="generic-data-block-value">
          {{ orderToDetails?.order?.comment }}
        </p>
      </div>
      <div class="generic-data-block has-icon-button">
        <p class="generic-data-block-title">
          {{ "orders.details.partnerComment" | translate }}
        </p>
        <dx-button
          *ngIf="authService.partnerCanAddCommentToOrder()"
          class="tertiary font-lg"
          (click)="createOwnComment()"
          ><i class="bi bi-pencil-square"></i
        ></dx-button>
        <p class="generic-data-block-value">
          {{ orderToDetails?.order?.partnerComment }}
        </p>
      </div>
    </div>
    @if (orderToDetails?.delivery?.shippingPostalCode) {
    <h4 class="card-title secondary m-top-2">
      {{ "orders.details.shippingSection.title" | translate }} ({{
        orderToDetails?.delivery?.deliveryNote
      }})
    </h4>
    <div class="generic-data-group col-2">
      <div class="generic-data-block">
        <p class="generic-data-block-title">
          {{ "orders.details.shippingSection.supplierName" | translate }}
        </p>
        <p class="generic-data-block-value">
          {{ orderToDetails?.delivery?.supplierName }}
        </p>
      </div>
      <div class="generic-data-block">
        <p class="generic-data-block-title">
          {{ "orders.details.shippingSection.shippingAddress" | translate }}
        </p>
        <p class="generic-data-block-value">
          {{ orderToDetails?.delivery?.shippingPostalCode }}
          {{ orderToDetails?.delivery?.shippingCity }}
          {{ orderToDetails?.delivery?.shippingStreet }}
        </p>
      </div>
      <div class="generic-data-block" *ngIf="!isMobile">
        <p class="generic-data-block-title">
          {{ "orders.details.shippingSection.supplierTel" | translate }}
        </p>
        <p class="generic-data-block-value">
          {{ orderToDetails?.delivery?.supplierTel }}
        </p>
      </div>
      <div class="generic-data-block">
        <p class="generic-data-block-title">
          {{ "orders.details.shippingSection.quantity" | translate }}
        </p>
        <p class="generic-data-block-value">
          {{ orderToDetails?.delivery?.quantity }}
        </p>
      </div>
    </div>
    }
  </div>
  <div *ngIf="!isSingleView" class="order-details-card-bottom">
    <h3 class="card-title teal-5 m-top-2">
      {{ "orders.details.buttons.title" | translate }}
    </h3>
    <div class="bottom-button-grid">
      <dx-button (click)="downloadOrder()" class="tertiary font-lg">
        <i class="bi bi-arrow-left-right"></i>
        <span>{{ "orders.details.buttons.downloadOrder" | translate }}</span>
      </dx-button>
      <dx-button
        (click)="showInvoicesPopup()"
        [disabled]="
          (orderToDetails?.invoiceList)!.length < 1 ||
          !user?.partnerParams?.includes('INVOICES')
        "
        class="tertiary font-lg"
      >
        <i class="bi bi-file-earmark-medical"></i>
        <span>{{
          "orders.details.buttons.showInvoicesPopup" | translate
        }}</span>
      </dx-button>
      <dx-button
        (click)="downloadStatements()"
        [disabled]="(orderToDetails?.performanceStatementList)!.length < 1"
        class="tertiary font-lg"
      >
        <i class="bi bi-file-earmark-check"></i>
        <span>{{
          "orders.details.buttons.downloadPerformanceStatement" | translate
        }}</span>
      </dx-button>
      <dx-button (click)="openSzallitasiFeltetelek()" class="tertiary font-lg">
        <i class="bi bi-truck"></i>
        <span>{{
          "orders.details.buttons.deliveryConditions" | translate
        }}</span>
      </dx-button>
<!--      <dx-button-->
<!--        [disabled]="true"-->
<!--        *ngIf="user?.partnerParams?.includes('STAND_GLASSES')"-->
<!--        class="tertiary font-lg"-->
<!--      >-->
<!--        <i class="bi bi-bookshelf"></i>-->
<!--        <span>{{ "orders.details.buttons.stands" | translate }}</span>-->
<!--      </dx-button>-->
        <dx-button (click)="downloadStandGlassPdf()" class="tertiary font-lg">
            <i class="bi bi-file-earmark-medical"></i>
            <span>{{ "orders.details.buttons.downloadStandGlassPdf" | translate }}</span>
        </dx-button>
        <dx-button (click)="downloadStandGlassCsv()" class="tertiary font-lg">
            <i class="bi bi-file-earmark-medical"></i>
            <span>{{ "orders.details.buttons.downloadStandGlassCsv" | translate }}</span>
        </dx-button>
      <dx-button
        (click)="showComplaintsPopup()"
        *ngIf="user?.partnerParams?.includes('COMPLAINTS') && user!.original"
        class="tertiary font-lg"
      >
        <i class="bi bi-envelope-exclamation"></i>
        <span>{{
          "orders.details.buttons.showComplaintsPopup" | translate
        }}</span>
      </dx-button>
      <dx-button
        (click)="showMoreDeliveryPopup()"
        *ngIf="
          orderToDetails!.deliveryList &&
          orderToDetails!.deliveryList!.length > 0
        "
        class="tertiary font-lg"
      >
        <i class="bi bi-truck"></i>
        <span>{{ "orders.details.buttons.moreDelivers" | translate }}</span>
      </dx-button>
    </div>
  </div>
</div>
<div class="popup" *ngIf="isOwnCommentPopup">
  <div class="popup-container">
    <dx-button
      (onClick)="closePopup()"
      class="primary bg-teal-5 close icon-only"
    >
      <i class="bi bi-x-lg"></i>
    </dx-button>
    <div class="generic-block" (click)="$event.stopPropagation()">
      <h3 class="card-title teal-5">
        {{ "orders.details.commentPopup.title" | translate }}
      </h3>
      <form>
        <dx-form
          #addOwnCommentForm
          [formData]="ownComment"
          [showValidationSummary]="true"
          labelLocation="top"
          [showColonAfterLabel]="false"
          [colCount]="1"
        >
          <dxi-item
            dataField="comment"
            editorType="dxTextArea"
            [editorOptions]="{ maxLength: 200 }"
            ><dxo-label
              [text]="'orders.details.commentPopup.comment' | translate"
            ></dxo-label>
          </dxi-item>
          <dxi-item type="group">
            <div class="button-row right">
              <dx-button
                class="primary btn-sm font-lg bg-teal-5"
                [useSubmitBehavior]="true"
                (onClick)="onSubmit()"
              >
                <i class="bi bi-check-circle"></i>
                <span>{{
                  "orders.details.commentPopup.save" | translate
                }}</span>
              </dx-button>
            </div>
          </dxi-item>
        </dx-form>
      </form>
    </div>
  </div>
</div>
<div class="popup" *ngIf="isInvoicesPopup">
  <div class="popup-container">
    <dx-button
      (onClick)="closePopup()"
      class="primary bg-teal-5 close icon-only"
    >
      <i class="bi bi-x-lg"></i>
    </dx-button>
    <div class="generic-block" (click)="$event.stopPropagation()">
      <div class="generic-block-header-row">
        <h3 class="card-title teal-5">
          {{ "orders.details.invoicesPopup.title" | translate }}
        </h3>
      </div>

      <div class="generic-data-group col-1 m-bottom-1-5">
        <div
          class="generic-data-block has-icon-button single-row"
          *ngFor="let invoice of orderToDetails?.invoiceList"
        >
          <p class="generic-data-block-value">{{ invoice.invoiceNumber }}</p>
          <dx-button
            class="invoice-list-invoice-download tertiary teal-3 font-lg"
            (click)="downloadInvoice(invoice.id, invoice.invoiceNumber)"
          >
            <i class="bi bi-file-earmark-arrow-down"></i>
          </dx-button>
        </div>
      </div>
      <div class="button-row right">
        <dx-button
          (onClick)="downloadAllInvoices()"
          class="primary bg-teal-5 btn-sm font-lg"
        >
          <i class="bi bi-download"></i>
          <span>{{
            "orders.details.invoicesPopup.downloadAllButton" | translate
          }}</span>
        </dx-button>
      </div>
    </div>
  </div>
</div>
<div class="popup" *ngIf="isComplaintsPopup">
  <div class="popup-container">
    <dx-button
      (onClick)="closePopup()"
      class="primary bg-teal-5 close icon-only"
    >
      <i class="bi bi-x-lg"></i>
    </dx-button>
    <div class="generic-block" (click)="$event.stopPropagation()">
      <h3 class="card-title teal-5">
        {{ "orders.details.complaintsPopup.title" | translate }}
      </h3>
      <app-complaints
        [complaintData]="{
          type: 'ORDER',
          id: orderToDetails!.order!.confirmationName.toString()
        }"
        (formSent)="formSent($event)"
      ></app-complaints>
    </div>
  </div>
</div>
<div class="popup" *ngIf="isMoreDeliveryPopup">
  <div class="popup-container">
    <dx-button
      (onClick)="closePopup()"
      class="primary bg-teal-5 close icon-only"
    >
      <i class="bi bi-x-lg"></i>
    </dx-button>
    <div class="generic-block" (click)="$event.stopPropagation()">
      <h3 class="card-title teal-5">
        {{ "orders.details.moreDeliversPopup.title" | translate }}
      </h3>
      @for (delivery of orderToDetails!.deliveryList; track delivery) {
      <h4 class="card-title secondary m-top-2">
        {{ "orders.details.shippingSection.title" | translate }} ({{
          delivery.deliveryNote
        }})
      </h4>
      <div class="generic-data-group col-2">
        <div class="generic-data-block">
          <p class="generic-data-block-title">
            {{ "orders.details.shippingSection.supplierName" | translate }}
          </p>
          <p class="generic-data-block-value">
            {{ delivery.supplierName }}
          </p>
        </div>
        <div class="generic-data-block">
          <p class="generic-data-block-title">
            {{ "orders.details.shippingSection.shippingAddress" | translate }}
          </p>
          <p class="generic-data-block-value">
            {{ delivery.shippingPostalCode }}
            {{ delivery.shippingCity }}
            {{ delivery.shippingStreet }}
          </p>
        </div>
        <div class="generic-data-block" *ngIf="!isMobile">
          <p class="generic-data-block-title">
            {{ "orders.details.shippingSection.quantity" | translate }}
          </p>
          <p class="generic-data-block-value">
            {{ delivery.supplierTel }}
          </p>
        </div>
        <div class="generic-data-block">
          <p class="generic-data-block-title">
            {{ "orders.details.expectedDeliveryDate" | translate }}
          </p>
          <p class="generic-data-block-value">
            {{ delivery.deliveryDate | ceDateTimeFormat }}
          </p>
        </div>
        <div class="generic-data-block">
          <p class="generic-data-block-title">
            {{ "orders.details.shippingSection.quantity" | translate }}
          </p>
          <p class="generic-data-block-value">
            {{ delivery.quantity }}
          </p>
        </div>
      </div>
      }
    </div>
  </div>
</div>
