<div class="breadcrumb-actions-row container-width">
  <app-breadcrumb class="breadcrumb-group"></app-breadcrumb>
</div>
<div
  class="invoices-data container-width main-grid mg-66-33"
  [ngClass]="{ 'full-width': invoiceToDetails == undefined }"
>
  <div class="invoices-data-details left-col h100p">
    <app-invoices-summary
      (setListFullWidth)="setListFullWidth($event)"
    ></app-invoices-summary>
    <app-invoices-list
      #invoiceList
      (invoiceToDetails)="getInvoiceToDetails($event)"
      class="h100p"
      id="invoiceList"
    ></app-invoices-list>
  </div>
  <div class="invoices-data-single" *ngIf="invoiceToDetails != undefined">
    <app-invoice-details
      [isSingleView]="false"
      [invoiceToDetails]="invoiceToDetails"
    ></app-invoice-details>
  </div>
</div>
