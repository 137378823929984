import { Component } from '@angular/core';
import { SpinnerService } from './spinner.service';
import {TranslateService} from "@ngx-translate/core";

export interface SpinnerData {
  text?: string;
  isLoading: boolean;
}

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.less',
})
export class SpinnerComponent {
  isLoading: boolean = false;
  text?: string;

  constructor(
      private spinnerService: SpinnerService,
      private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.spinnerService.isLoading.subscribe((val) => {
      this.isLoading = val.isLoading;
      this.text = val.text;
    });
  }

  nowLoading() {
    this.isLoading = true;
    this.text = this.translate.instant("common.loading");
  }
  nowSaving() {
    this.isLoading = true;
    this.text = this.translate.instant("common.saving");
  }
  notShowing() {
    this.isLoading = false;
  }
}
