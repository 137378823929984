<div class="generic-block h100p">
  <div class="generic-block-header-row">
    <h3 class="card-title teal-5">{{ "stands.title" | translate }}</h3>
    <div class="button-row gap-2">
      <dx-button
        (click)="selectedSendBack()"
        *ngIf="isDesktop && standsService.isPartnerCanSendBackStand"
        class="tertiary font-lg"
        [disabled]="!isAnyStandSelected"
      >
        <i class="bi bi-arrow-90deg-up"></i>
        <span>{{ "stands.standsReturnButton" | translate }}</span>
      </dx-button>
      <dx-button
        (click)="setFullWidth()"
        [disabled]="isFullWidth"
        *ngIf="isDesktop"
        class="tertiary font-lg tab-l-hidden"
      >
        <i class="bi bi-arrows-angle-expand"></i>
        <span>{{ "common.table.setFullWidthButton" | translate }}</span>
      </dx-button>
      <dx-button
        (click)="columnChooser.instance.showColumnChooser()"
        *ngIf="isDesktop"
        class="tertiary font-lg mob-icon-only"
      >
        <i class="bi bi-layout-sidebar-inset"></i>
        <span>{{ "common.table.columnChooserButton" | translate }}</span>
      </dx-button>
    </div>
  </div>
  <dx-data-grid
    #standsListGrid
    id="standsListGrid"
    [dataSource]="standsList"
    (onFocusedRowChanged)="checkStand($event)"
    [customizeColumns]="dataService.defaultColumns"
    (onSelectionChanged)="anyStandSelected($event)"
    [filterSyncEnabled]="true"
  >
    <dxo-column-chooser #columnChooser [enabled]="true" mode="select">
      <dxo-selection [allowSelectAll]="false" [selectByClick]="true" />
      <dxo-position />
    </dxo-column-chooser>
    <dxo-paging [pageSize]="dataService.PAGE_SIZE" />
    <dxo-state-storing
      [enabled]="true"
      type="custom"
      [customSave]="stateSave"
    />
    <dxo-selection
      selectAllMode="allPages"
      showCheckBoxesMode="always"
      [mode]="standsService.isPartnerCanSendBackStand ? 'multiple' : 'none'"
    ></dxo-selection>
    <dxi-column
      dataField="id"
      dataType="string"
      [caption]="'stands.column.id' | translate"
      [visible]="false"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="150"
    ></dxi-column>
    <dxi-column
      dataField="barcode"
      dataType="string"
      [caption]="'stands.column.barcode' | translate"
      [visible]="true"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="150"
    ></dxi-column>
    <dxi-column
      dataField="status"
      dataType="string"
      [caption]="'stands.column.status' | translate"
      [visible]="true"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="150"
    >
      <dxo-lookup
        [allowClearing]="true"
        [dataSource]="standsService.STANDSTATUS_DATA"
        displayExpr="text"
        valueExpr="id"
      ></dxo-lookup>
    </dxi-column>
    <dxi-column
      dataField="movementDate"
      dataType="date"
      [caption]="'stands.column.movementDate' | translate"
      [visible]="true"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="150"
    ></dxi-column>
    <dxi-column
      dataField="rentalDays"
      dataType="number"
      [caption]="'stands.column.rentalDays' | translate"
      [visible]="true"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="100"
    ></dxi-column>
    <dxi-column
      dataField="city"
      dataType="string"
      [caption]="'stands.column.city' | translate"
      [visible]="true"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="100"
    ></dxi-column>
    <dxi-column
      dataField="address"
      dataType="string"
      [caption]="'stands.column.address' | translate"
      [visible]="true"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="150"
    ></dxi-column>
    <dxi-column
      dataField="rentalStart"
      dataType="date"
      [caption]="'stands.column.rentalStartDate' | translate"
      [visible]="true"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="150"
    ></dxi-column>
    <dxi-column
      dataField="rentalCost"
      dataType="number"
      [caption]="'stands.column.rentalCost' | translate"
      [visible]="true"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="150"
    ></dxi-column>
    <dxi-column
      dataField="currency"
      dataType="string"
      [caption]="'stands.column.currency' | translate"
      [visible]="true"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="150"
    ></dxi-column>
    <dxi-column
      dataField="returnStandIndicatedDate"
      dataType="date"
      [caption]="'stands.column.returnStandIndicatedDate' | translate"
      [visible]="true"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="150"
    ></dxi-column>
    <dxi-column
      dataField="returnDate"
      dataType="date"
      [caption]="'stands.column.returnDate' | translate"
      [visible]="true"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="150"
    ></dxi-column>
    <dxi-column
      dataField="standNumber"
      dataType="string"
      [caption]="'stands.column.standNumber' | translate"
      [visible]="true"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="150"
    ></dxi-column>
    <dxi-column
      dataField="minDays"
      dataType="number"
      [caption]="'stands.column.minDays' | translate"
      [visible]="true"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="80"
    ></dxi-column>
    <dxi-column
      dataField="deliveryNoteNumber"
      dataType="string"
      [caption]="'stands.column.deliveryNoteNumber' | translate"
      [visible]="true"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="80"
    ></dxi-column>
  </dx-data-grid>
</div>
<div class="popup" *ngIf="isSendBackPopup">
  <div class="popup-container">
    <dx-button
      (onClick)="closePopup()"
      class="primary bg-teal-5 close icon-only"
    >
      <i class="bi bi-x-lg"></i>
    </dx-button>
    <div class="generic-block" (click)="$event.stopPropagation()">
      <h3 class="card-title teal-5">
        {{ "stands.standsReturnPopup.title" | translate }}
      </h3>
      <p>
        {{
          "stands.standsReturnPopup.message"
            | translate : { standsNumber: standsNumber }
        }}
      </p>
      <form>
        <dx-form
          #sendBackStandsForm
          [formData]="sendBackStandsData"
          labelLocation="top"
          [showColonAfterLabel]="false"
        >
          <dxi-item itemType="group">
            <dxi-item dataField="message" editorType="dxTextArea">
              <dxo-label
                [text]="'stands.standsReturnPopup.messageLabel' | translate"
                [visible]="false"
              ></dxo-label>
            </dxi-item>
            <dxi-item
              dataField="isUrgent"
              editorType="dxCheckBox"
              [editorOptions]="{
                text: 'stands.standsReturnPopup.urgentOption' | translate
              }"
            >
              <dxo-label text="" [visible]="false"></dxo-label>
            </dxi-item>
            <dxi-item
              dataField="isNeedCrane"
              editorType="dxCheckBox"
              [editorOptions]="{
                text: 'stands.standsReturnPopup.needCraneOption' | translate
              }"
            >
              <dxo-label text="" [visible]="false"></dxo-label>
            </dxi-item>
          </dxi-item>
        </dx-form>
        <div class="button-row right m-top-1-5">
          <dx-button
            class="primary btn-sm font-lg bg-teal-5"
            [useSubmitBehavior]="true"
            (onClick)="sendBackStands()"
          >
            <i class="bi bi-arrow-90deg-up"></i>
            <span>{{
              "stands.standsReturnPopup.sendBackStandsButton" | translate
            }}</span>
          </dx-button>
        </div>
      </form>
    </div>
  </div>
</div>
