<div class="breadcrumb-actions-row container-width">
  <app-breadcrumb class="breadcrumb-group"></app-breadcrumb>
</div>
<div class="documents container-width main-grid">
  <div class="generic-block">
    <div class="generic-block-header-row">
      <h3 class="card-title teal-5">{{ "documents.title" | translate }}</h3>
      <div class="action-buttons">
        <dx-select-box
          #languageSelector
          [dataSource]="AppConstants.USER_LANGUAGES"
          [(value)]="selectedLang"
          displayExpr="name"
          valueExpr="id"
          (onValueChanged)="languageChanged()"
        ></dx-select-box>
      </div>
    </div>
    <div class="document-card" *ngFor="let document of documents">
      <div class="document-card-texts">
        <p class="document-card-name">{{ document.name }}</p>
        <p class="document-card-text">{{ document.text }}</p>
      </div>
      <div class="document-card-download">
        <a href="{{ document.src }}" target="_blank"
          ><i class="bi bi-download"></i
        ></a>
      </div>
    </div>
  </div>


</div>
