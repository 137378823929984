<div class="breadcrumb-actions-row container-width">
  <app-breadcrumb class="breadcrumb-group"></app-breadcrumb>
</div>
<div class="contacts generic-block-grid col-3 container-width">
  <div class="generic-block">
    <h3 class="card-title teal-5">{{ "common.ceCompanyData.name" | translate }}</h3>
    <div class="generic-data-group col-2">
      <div class="generic-data-block">
        <p class="generic-data-block-title">{{ "connection.addressLabel" | translate }}</p>
        <p class="generic-data-block-value">{{ "common.ceCompanyData.address" | translate }}</p>
      </div>
      <div class="generic-data-block">
        <p class="generic-data-block-title">{{ "connection.phoneLabel" | translate }}</p>
        <p class="generic-data-block-value">{{ "common.ceCompanyData.phone" | translate }}</p>
      </div>
      <div class="generic-data-block">
        <p class="generic-data-block-title">{{ "connection.emailLabel" | translate }}</p>
        <p class="generic-data-block-value">{{ "common.ceCompanyData.email" | translate }}</p>
      </div>
    </div>
  </div>
  <div class="generic-block">
    <h3 class="card-title teal-5">{{ "connection.salesmanProfileBlockTitle" | translate }}</h3>
    <div class="generic-data-group col-2">
      <div class="generic-data-block">
        <p class="generic-data-block-title">{{ "connection.nameLabel" | translate }}</p>
        <p class="generic-data-block-value">{{ salesmanProfile.name }}</p>
      </div>
      <div class="generic-data-block">
        <p class="generic-data-block-title">{{ "connection.phoneLabel" | translate }}</p>
        <p class="generic-data-block-value">{{ salesmanProfile.tel }}</p>
      </div>
      <div class="generic-data-block">
        <p class="generic-data-block-title">{{ "connection.emailLabel" | translate }}</p>
        <p class="generic-data-block-value">{{ salesmanProfile.email }}</p>
      </div>
    </div>
  </div>
</div>
