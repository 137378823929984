<div class="breadcrumb-actions-row container-width">
  <app-breadcrumb class="breadcrumb-group"></app-breadcrumb>
  <div class="action-buttons">
    <dx-button (onClick)="addNewOffer()"*ngIf="authService.partnerCanGetRequest()" class="primary bg-teal-5 btn-lg">
      <i class="bi bi-question-circle"></i><span>{{ "orders.addNewOfferButton" | translate }}</span>
    </dx-button>
  </div>
</div>
<div
  class="orders-data container-width main-grid mg-66-33"
  [ngClass]="{ 'full-width': orderToDetails == undefined }"
>
  <div class="orders-data-details left-col">
    <app-orders-summary
      (setListFullWidth)="setListFullWidth($event)"
    ></app-orders-summary>
    <app-orders-list
      #ordersList
      (orderToDetails)="getOrderToDetails($event)"
      class="h100p"
    ></app-orders-list>
  </div>
  <div class="orders-data-single" *ngIf="orderToDetails != undefined">
    <app-order-details
      [isSingleView]="false"
      [orderToDetails]="orderToDetails"
      (reloadList)="reloadList($event)"
    ></app-order-details>
  </div>
</div>
<div class="popup" *ngIf="isPopup">
  <div class="popup-container">
    <dx-button
      (onClick)="closePopup()"
      class="primary bg-teal-5 icon-only close"
    >
      <i class="bi bi-x-lg"></i>
    </dx-button>
    <div class="generic-block" (click)="$event.stopPropagation()">
      <h3 class="card-title teal-5">{{ "orders.newOfferPopup.title" | translate }}</h3>
      <form class="no-summary">
        <dx-form
          #addOrderForm
          [formData]="orderData"
          [showValidationSummary]="true"
          labelLocation="top"
          [showColonAfterLabel]="false"
          [colCount]="1"
        >
          <dxi-item
            dataField="type"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: NEW_TYPES,
              displayExpr: 'name',
              valueExpr: 'id'
            }"
            [colSpan]="1"
          >
            <dxo-label [text]="'orders.newOfferPopup.types' | translate"></dxo-label>
          </dxi-item>
          <dxi-item dataField="subject" editorType="dxTextBox"
            ><dxo-label [text]="'orders.newOfferPopup.subject' | translate"></dxo-label>
            <dxi-validation-rule
              type="required"
              [message]="'error.requiredMessage' | translate"
            ></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="message" editorType="dxTextArea" [editorOptions]="{height:200}"
            ><dxo-label [text]="'orders.newOfferPopup.message' | translate"></dxo-label>
            <dxi-validation-rule
              type="required"
              [message]="'error.requiredMessage' | translate"
            ></dxi-validation-rule>
            <dxi-validation-rule
              type="stringLength"
              [min]="25"
              [message]="'error.minCharMessage' | translate:{number:25}"
            >
            </dxi-validation-rule>
            <dxi-validation-rule
              type="stringLength"
              [max]="2001"
              [message]="'error.maxCharMessage' | translate:{number:2001}"
            >
            </dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="deliveryAddress" editorType="dxTextBox"
            ><dxo-label [text]="'orders.newOfferPopup.deliveryAddress' | translate"></dxo-label>
          </dxi-item>
          <dxi-item
            #fileUploader
            dataField="attachments"
            editorType="dxFileUploader"
            [editorOptions]="{
              maxFileSize: 10000000000,
              accept: AppConstants.ACCEPTED_FILE_TYPE.join(','),
              uploadMode: 'useButton',
              multiple: true,
            }"
          >
            <dxo-label [text]="'common.fileUploader.attachments' | translate"></dxo-label>
            <dxi-validation-rule
              #fileValidation
              type="custom"
              [validationCallback]="validateFiles"
              [message]="'error.attachmentsInvalidMessage' | translate"
            ></dxi-validation-rule>
          </dxi-item>
          <dxi-item type="group">
            <p>
                {{ "common.fileUploader.attachmentsMessage" | translate:{fileCount:AppConstants.FILE_COUNT_LIMIT, fileSize:fileSize, maxFileSize:AppConstants.FILE_SIZE_MAXIMUM_MB} }}
            </p>
          </dxi-item>
          <dxi-item type="group">
            <div class="button-row right">
              <dx-button
                #submitRequestOrderButton
                [useSubmitBehavior]="true"
                (onClick)="onSubmit()"
                class="btn-sm primary bg-teal-5 font-lg"
              >
                <i class="bi bi-envelope-arrow-up"></i>
                <span>{{ "orders.newOfferPopup.sendButton" | translate }}</span>
              </dx-button>
            </div>
          </dxi-item>
        </dx-form>
      </form>
    </div>
  </div>
</div>
<app-spinner *ngIf="!isLoaded"></app-spinner>
