<div class="header container-width" [class.logged-in]="isUserLoggedIn | async">
  <div class="logo-container">
    <a [routerLink]="['/']">
      <img
        class="header-logo"
        src="../../../assets/img/ceglass-logo-header.png"
        alt="CE Glass"
      />
    </a>
    <span class="header-title">{{ "header.title" | translate }}</span>
  </div>

  <div
    class="menu menu-desktop"
    *ngIf="
      (isUserLoggedIn | async) && userType == 'PARTNER' && !userNeedsPassChange
    "
  >
    <dx-menu>
      <ng-container *ngFor="let menu of dataService.MENU">
        <dxi-item
          *ngIf="menus.includes(menu.menuParam)"
          [routerLink]="menu.routerLink"
          routerLinkActive="active"
          [ngClass]="{
            'teal-3': menu.menuParam == 'QUESTIONNAIRE',
            'teal-5': menu.menuParam == 'PROFILE'
          }"
        >
          <a>
            <i class="bi bi-{{ menu.iconClassName }}"></i>
            {{ menu.menuName | translate }}
          </a>
        </dxi-item>
      </ng-container>
    </dx-menu>
    <button id="hamburger-button" (click)="toggleMenu()">
      <i class="bi bi-list"></i>
    </button>
  </div>
  <div
    [ngClass]="{ 'menu-open': menuOpen }"
    class="menu hamburger-menu"
    id="hamburger-menu"
    *ngIf="(isUserLoggedIn | async) && userType == 'PARTNER'"
  >
    <dx-menu>
      <ng-container *ngFor="let menu of dataService.MENU">
        <dxi-item
          *ngIf="menus.includes(menu.menuParam)"
          [routerLink]="menu.routerLink"
          (click)="toggleMenu()"
          [ngClass]="{
            'teal-3': menu.menuParam == 'QUESTIONNAIRE',
            'teal-5': menu.menuParam == 'PROFILE'
          }"
        >
          <a>
            <i class="bi bi-{{ menu.iconClassName }}"></i>
            {{ menu.menuName | translate }}
          </a>
        </dxi-item>
      </ng-container>
    </dx-menu>
    <dx-button
      class=""
      (onClick)="onLogout()"
      [text]="'header.logout' | translate"
    >
      <i class="bi bi-door-open"></i> {{ "header.logout" | translate }}
    </dx-button>
  </div>
  <div
    class="menu"
    *ngIf="
      (isUserLoggedIn | async) &&
      (userType == 'SALES' || userType == 'ADMIN') &&
      !userNeedsPassChange
    "
  >
    <dx-button (onClick)="onLogout()" class="btn-sm primary bg-teal-5">
      <i class="bi bi-door-open"></i> {{ "header.logout" | translate }}
    </dx-button>
  </div>
  <div class="menu" *ngIf="userNeedsPassChange">
    <dx-button (onClick)="onLogout()" class="btn-sm primary bg-teal-5">
      <i class="bi bi-door-open"></i> {{ "header.logout" | translate }}
    </dx-button>
  </div>
</div>
