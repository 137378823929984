<div class="change-pass-form card">
  <h3 class="card-title teal-5">{{ "changePassword.title" | translate }}</h3>
  <form #passChangeForm (ngSubmit)="onSubmit()" class="form-lg">
    <dx-form
      [formData]="passChangeData"
      [showValidationSummary]="true"
      labelLocation="top"
    >
      <dxi-item
        dataField="password"
        [editorOptions]="newPasswordEditorOptions"
        [tabIndex]="0"
      >
        <dxo-label [text]="'changePassword.newPassword' | translate "></dxo-label>
        <dxi-validation-rule
          type="stringLength"
          [max]="255"
          [trim]="false"
          [message]="'error.maxCharMessage' | translate:{number:255}"
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item itemType="group">
        <p>
          <span class="min-num-char" [ngClass]="{ check: minNumCheck }">
            @if (minNumCheck) { <i class="bi bi-check-circle-fill"></i> } @else
            { <i class="bi bi-x-circle-fill"></i> }
          </span>
            {{ "changePassword.minNumCheck" | translate }}
        </p>
        <p>
          <span class="min-num-char">
            @if (minSmallCharCheck) {
            <i class="bi bi-check-circle-fill"></i> } @else {
            <i class="bi bi-x-circle-fill"></i> }
          </span>
            {{ "changePassword.minSmallCharCheck" | translate }}
        </p>
        <p>
          <span class="min-num-char">
            @if (minBigCharCheck) {
            <i class="bi bi-check-circle-fill"></i> } @else {
            <i class="bi bi-x-circle-fill"></i> }
          </span>
            {{ "changePassword.minBigCharCheck" | translate }}
        </p>
        <p>
          <span class="min-num-char">
            @if (minSpecCharCheck) {
            <i class="bi bi-check-circle-fill"></i> } @else {
            <i class="bi bi-x-circle-fill"></i> }
          </span>
            {{ "changePassword.minSpecCharCheck" | translate }}
        </p>
      </dxi-item>
      <dxi-item
        dataField="confirmPassword"
        [editorOptions]="confirmPasswordEditorOptions"
        [tabIndex]="0"
      >
        <dxo-label [text]="'changePassword.reNewPassword' | translate"></dxo-label>
        <dxi-validation-rule
          type="compare"
          [comparisonTarget]="passwordComparison"
          [message]="'error.passwordCompareMessage' | translate"
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item itemType="group" *ngIf="passwordCompare">
        <p class="alert-strip">{{ "error.passwordCompareMessage" | translate }}</p>
      </dxi-item>

      <dxi-item itemType="group" *ngIf="passwordSuccess">
        <p class="success-strip">
            {{ "changePassword.successMessage" | translate }}
        </p>
      </dxi-item>
    </dx-form>
    <div class="button-row right m-top-1-5">
      <dx-button
        #submitPassBtn
        [useSubmitBehavior]="true"
        class="primary btn-sm font-lg bg-teal-5"
      >
        <i class="bi bi-box-arrow-in-right"></i><span>{{ "changePassword.changeButton" | translate }}</span>
      </dx-button>
    </div>
  </form>
</div>
