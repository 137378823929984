import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'langSelect',
})
export class LangSelectPipe implements PipeTransform {
  transform(value: any, lang: string) {
    return value[lang];
  }
}
