<div class="generic-block m-bottom-1 generic-block-highlight">
  <div class="generic-block-header-row no-padding">
    <h3 class="card-title white">{{ "invoices.summary.title" |translate }}</h3>
    <div class="button-row gap-2">
      @if (isSummaryVisible) {
        <dx-button (click)="toggleSummary()" class="tertiary font-lg lighter"><i class="bi bi-arrows-angle-contract"></i><span>{{ "common.summary.close" |translate }}</span></dx-button>
      } @else {
        <dx-button (click)="toggleSummary()" class="tertiary font-lg lighter"><i class="bi bi-arrows-angle-expand"></i><span>{{ "common.summary.open" |translate }}</span></dx-button>
      }
    </div>
  </div>
  <div class="invoices-summary-cards m-top-1-5" *ngIf="isSummaryVisible">
    <div *ngFor="let invoice of invoicesSummary">
      <app-invoices-summary-card
        [invoicesSummary]="invoice"
      ></app-invoices-summary-card>
    </div>
  </div>
</div>
