import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './AuthComponents/auth/auth.component';
import { SignedGuard } from './Guards/signed.guard';
import { PartnerPermissionGuard } from './Guards/partner.guard';
import { PassChangeComponent } from './AuthComponents/pass-change/pass-change.component';
import { DashboardComponent } from './ViewComponents/partner-view/dashboard/dashboard.component';
import { ForgottenPassComponent } from './AuthComponents/forgotten-pass/forgotten-pass.component';
import { SalesmanViewComponent } from './ViewComponents/salesman-view/salesman-view.component';
import { SalesPermissionGuard } from './Guards/sales.guard';
import { PartnerViewComponent } from './ViewComponents/partner-view/partner-view.component';
import { OrdersComponent } from './ViewComponents/partner-view/orders/orders.component';
import { AuthGuard } from './Guards/auth.guard';
import { PermissionGuard } from './Guards/permissions.guard';
import { InvoicesComponent } from './ViewComponents/partner-view/invoices/invoices.component';
import { DocumentsComponent } from './ViewComponents/partner-view/documents/documents.component';
import { ConnectionsComponent } from './ViewComponents/partner-view/connections/connections.component';
import { DefaultRouteComponent } from './AuthComponents/default-route/default-route.component';
import { AppRouteNames } from './app-route-names';
import { PartnerProfileComponent } from './ViewComponents/partner-view/partner-profile/partner-profile.component';
import { InvoiceSingleViewComponent } from './ViewComponents/partner-view/invoices/invoice-single-view/invoice-single-view.component';
import { QuestionnaireComponent } from './ViewComponents/partner-view/questionnaire/questionnaire.component';
import { OrderSingleViewComponent } from './ViewComponents/partner-view/orders/order-single-view/order-single-view.component';
import { ComplaintsViewComponent } from './ViewComponents/partner-view/complaints-view/complaints-view.component';
import { StandsComponent } from './ViewComponents/partner-view/stands/stands.component';
import { StandSingleViewComponent } from './ViewComponents/partner-view/stands/stand-single-view/stand-single-view.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: AppRouteNames.LOGIN,
    pathMatch: 'full',
  },
  {
    path: AppRouteNames.LOGIN,
    component: AuthComponent,
    canActivate: [SignedGuard],
  },
  {
    path: AppRouteNames.PARTNER_MAIN_COMPONENT,
    component: PartnerViewComponent,
    canActivate: [AuthGuard, SalesPermissionGuard],
    data: {
      breadcrumb: '',
    },
    children: [
      {
        path: AppRouteNames.RELATIVE_DASHBOARD,
        component: DashboardComponent,
        canActivate: [PermissionGuard],
        data: {
          expectedRole: 'DASHBOARD',
        },
      },
      {
        path: AppRouteNames.RELATIVE_ORDERS,
        data: {
          expectedRole: 'ORDERS',
          breadcrumb: 'header.orders',
        },
        children: [
          {
            path: '',
            component: OrdersComponent,
            canActivate: [PermissionGuard],
          },
          {
            path: ':id',
            component: OrderSingleViewComponent,
            data: {
              breadcrumb: '',
            },
          },
        ],
      },
      {
        path: AppRouteNames.RELATIVE_INVOICES,
        data: {
          expectedRole: 'INVOICES',
          breadcrumb: 'header.invoices',
        },
        children: [
          {
            path: '',
            component: InvoicesComponent,
            canActivate: [PermissionGuard],
          },
          {
            path: ':id',
            component: InvoiceSingleViewComponent,
            data: {
              breadcrumb: '',
            },
          },
        ],
      },
      {
        path: AppRouteNames.RELATIVE_STAND_GLASSES,
        data: {
          expectedRole: 'STAND_GLASSES',
          breadcrumb: 'header.stands',
        },
        children: [
          {
            path: '',
            component: StandsComponent,
            canActivate: [PermissionGuard],
          },
          {
            path: ':id',
            component: StandSingleViewComponent,
            data: {
              breadcrumb: '',
            },
          },
        ],
      },
      {
        path: AppRouteNames.RELATIVE_DOCUMENTS,
        component: DocumentsComponent,
        canActivate: [PermissionGuard],
        data: {
          expectedRole: 'DOCUMENTS',
          breadcrumb: 'header.documents',
        },
      },
      {
        path: AppRouteNames.RELATIVE_CONNECTIONS,
        component: ConnectionsComponent,
        canActivate: [PermissionGuard],
        data: {
          expectedRole: 'CONNECTIONS',
          breadcrumb: 'header.contacts',
        },
      },
      {
        path: AppRouteNames.RELATIVE_PROFILE,
        component: PartnerProfileComponent,
        canActivate: [PermissionGuard],
        data: {
          expectedRole: 'PROFILE',
          breadcrumb: 'header.profile',
        },
      },
      {
        path: AppRouteNames.RELATIVE_COMPLAINTS,
        component: ComplaintsViewComponent,
        canActivate: [PermissionGuard],
        data: {
          expectedRole: 'COMPLAINTS',
          breadcrumb: 'header.complaints',
        },
      },
      {
        path: AppRouteNames.RELATIVE_QUESTIONNAIRE,
        component: QuestionnaireComponent,
        canActivate: [PermissionGuard],
        data: {
          expectedRole: 'QUESTIONNAIRE',
          breadcrumb: 'header.questionnaire',
        },
      },
    ],
  },
  {
    path: AppRouteNames.CHANGE_PASSWORD,
    component: PassChangeComponent,
    canActivate: [],
  },
  {
    path: AppRouteNames.FORGOT_PASSWORD,
    component: ForgottenPassComponent,
    canActivate: [SignedGuard],
  },
  {
    path: AppRouteNames.SALES_MAIN_COMPONENT,
    component: SalesmanViewComponent,
    canActivate: [AuthGuard, PartnerPermissionGuard],
  },
  {
    path: AppRouteNames.FALLBACK,
    component: DefaultRouteComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
