<div class="breadcrumb-actions-row container-width">
  <app-breadcrumb class="breadcrumb-group"></app-breadcrumb>
</div>
<div class="single-order single-col-width m-bottom-2">
  <app-spinner *ngIf="!isLoaded"></app-spinner>
  <app-order-details
    *ngIf="isLoaded"
    [isSingleView]="true"
    [orderToDetails]="singleOrder"
  ></app-order-details>
</div>
