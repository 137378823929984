import { Component } from '@angular/core';

@Component({
  selector: 'app-partner-view',
  templateUrl: './partner-view.component.html',
  styleUrl: './partner-view.component.less',
})
export class PartnerViewComponent {
  constructor() {}
}
