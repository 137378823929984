import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../AuthComponents/auth/auth.service';

@Injectable({ providedIn: 'root' })
class PermissionsService {
  user: any;
  constructor(private router: Router, private authService: AuthService) {
    this.user = this.authService.getUserData();
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.user.partnerParams.includes(next.data['expectedRole'])) {
      return true;
    }
    this.authService.navigateToFirstPage();
    return false;
  }
}

export const PermissionGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean => {
  return inject(PermissionsService).canActivate(next, state);
};
