export enum STATUS_ICON {
  'OK' = 'title-status-ok',
  'WARNING' = 'title-status-info',
  'ERROR' = 'title-status-alert',
}
export class AppConstants {
  public static readonly FILE_COUNT_LIMIT = 3;
  public static readonly FILE_SIZE_MAXIMUM_MB = 20;
  public static readonly ACCEPTED_FILE_TYPE = ['application/pdf', 'image/*'];
  public static readonly CURRENCY_DATA = [
    'EUR',
    'HUF',
    'RON',
    'RSD',
    'USD',
    'CHF',
  ];
  public static readonly ALLOWED_PAGE_SIZES = [10, 25, 50, 100];
  public static readonly DEFAULT_PAGE_SIZE = 25;
  public static readonly DEFAULT_LOCALE = 'hu-HU';
  public static readonly DEFAULT_DATE_FORMAT = 'yyyy.MM.dd.';
  public static readonly DEFAULT_DATETIME_FORMAT = 'yyyy.MM.dd. HH:mm';
  public static readonly USER_LANGUAGES = [
    { name: 'Magyar', id: 'HU' },
    { name: 'English', id: 'EN' },
    { name: 'Română', id: 'RO' },
    { name: 'Srpski', id: 'SR' },
  ];
  public static readonly QUESTIONNAIRE_STATUS = [
    { name: 'Aktív', id: 'RUNNING' },
    { name: 'Inaktív', id: 'STOPPED' },
  ];
  public static readonly ALL_AUTH_PARAMS = [
    { id: 'DASHBOARD', text: 'Dashboard' },
    { id: 'ORDERS', text: 'Megrendelések' },
    { id: 'INVOICES', text: 'Számlák' },
    { id: 'STAND_GLASSES', text: 'Állványok' },
    { id: 'STAND_GLASSES_RETURN', text: 'Állványok visszaküldése' },
    {
      id: 'ADD_REMOVE_PARTNERS_COMMENT',
      text: 'Partnermegjegyzés hozzáadás/törlés',
    },
    {
      id: 'DATA_SHARING_WITH_ROMANIA',
      text: 'Adatközlés a romániai adóhivatallal',
    },
    { id: 'DOCUMENTS', text: 'Dokumentumok' },
    { id: 'CONNECTIONS', text: 'Kapcsolatok' },
    { id: 'PROFILE', text: 'Profil' },
    { id: 'REQUEST_FOR_ORDER', text: 'Ajánlatkérés/megrendelés' },
    { id: 'COMPLAINTS', text: 'Reklamáció beküldése' },
    { id: 'QUESTIONNAIRE', text: 'Kérdőív kitöltése' },
    { id: 'NEWSLETTER_SUBSCRIPTION', text: 'Hírlevel feliratkozás' },
  ];
  public static readonly IMPORT_TYPES = [
    { id: 'salesmen', text: 'UP_Kapcsolattartok' },
    { id: 'partners', text: 'UP_PartnerTorzs' },
    { id: 'company', text: 'UP_CegTorzs' },
    { id: 'orders', text: 'UP_Megrendelesek' },
    { id: 'orders_update', text: 'UP_Visszaigazolasok' },
    { id: 'invoices', text: 'UP_Bizonylatok' },
    { id: 'invoices_update', text: 'UP_BizonylatInfo' },
    { id: 'standglasses', text: 'UP_AllvanyUvegek' },
    { id: 'standmove', text: 'UP_AllvanyKintlev' },
    { id: 'deliveries', text: 'UP_Szallitasok' },
    { id: 'orderinvoice', text: 'UP_BizonylatMegrendelesek' },
    { id: 'etransport', text: 'UP_ETransport' },
  ];
}
