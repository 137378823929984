<div class="login-bg">
  <app-header class="white"></app-header>
  <div class="auth-page-container login-container">
    <div class="auth-page-form card">
      <div class="form-container">
        <form #authForm (ngSubmit)="onSubmit()" class="form-lg">
          <h3 class="title-lg black m-bottom-2">{{ "login.title" | translate }}</h3>
          <dx-form
            [formData]="authFormData"
            validationGroup="formValidations"
            labelLocation="top"
          >
            <dxi-item dataField="username">
              <dxo-label [text]="'common.username' | translate"></dxo-label>
              <dxi-validation-rule
                type="required"
                [message]="'error.requiredMessage' | translate"
              >
              </dxi-validation-rule>
            </dxi-item>
            <dxi-item
              dataField="password"
              [editorOptions]="{ mode: 'password' }"
            >
              <dxo-label [text]="'common.password' | translate"></dxo-label>
              <dxi-validation-rule
                type="required"
                [message]="'error.requiredMessage' | translate"
              >
              </dxi-validation-rule>
            </dxi-item>
          </dx-form>
          <p *ngIf="loginError" class="alert-strip m-top-1-5">
              {{ "login.errorMessage" | translate }}
          </p>
          <div class="button-row right m-top-1-5">
            <dx-button
              class="secondary btn-lg"
              [routerLink]="['/' + AppRouteNames.FORGOT_PASSWORD]"
            >
              <i class="bi bi-lock"></i><span>{{ "login.forgottenPasswordButton" | translate }}</span>
            </dx-button>
            <dx-button
              [useSubmitBehavior]="true"
              validationGroup="formValidations"
              class="primary btn-lg bg-teal-3"
            >
              <i class="bi bi-box-arrow-in-right"></i><span>{{ "login.loginButton" | translate }}</span>
            </dx-button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
