import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { OrdersService } from '../orders.service';
import { AppRouteNames } from '../../../../app-route-names';
import { OrderDetailsDto } from '../../../../../../../shared/models/order-details-dto';
import { DataService } from '../../../../data.service';
import { InvoicesService } from '../../invoices/invoices.service';
import { AlertBoxService } from '../../../UniversalViewComponents/alert-box/alert-box.service';
import { AuthService } from '../../../../AuthComponents/auth/auth.service';
import { SimpleUserDto } from '../../../../../../../shared/models/simple-user-dto';
import { TranslateService } from '@ngx-translate/core';
import {saveAs} from "file-saver";

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrl: './order-details.component.less',
})
export class OrderDetailsComponent {
  @Input() orderToDetails?: OrderDetailsDto;
  @Input() isSingleView?: boolean;
  @Output() reloadList: EventEmitter<any> = new EventEmitter();
  isOwnCommentPopup: boolean = false;
  isInvoicesPopup: boolean = false;
  isMoreDeliveryPopup: boolean = false;
  isComplaintsPopup: boolean = false;
  isMobile: boolean = false;
  user?: SimpleUserDto;
  ownComment: { comment?: string } = {
    comment: '',
  };

  constructor(
    private router: Router,
    private ordersService: OrdersService,
    public dataService: DataService,
    private invoicesService: InvoicesService,
    private alertBoxService: AlertBoxService,
    private translate: TranslateService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.isMobile = this.dataService.isMobile();
    this.user = this.authService.getUserData();
  }

  copyUrl() {
    const linkToInvoice = this.isSingleView
      ? `${window.location.href}`
      : `${window.location.href}/${this.orderToDetails?.order.confirmationName}`;
    navigator.clipboard.writeText(linkToInvoice).then(() => {
      this.alertBoxService.showInfo(
        this.translate.instant('common.successCopy')
      );
    });
  }

  formSent(e: any) {
    this.closePopup();
  }

  goToUrl() {
    this.router.navigate([
      `${AppRouteNames.PARTNER_ORDERS}/${this.orderToDetails?.order.confirmationName}`,
    ]);
  }

  getStatusText(id: string) {
    return this.ordersService.ORDERSTATUS_DATA.find(
      (status) => status.id === id
    )?.text;
  }

  callPhone() {
    window.location.href = `tel:${this.orderToDetails?.delivery?.supplierTel}`;
  }

  createOwnComment() {
    this.ownComment.comment = this.orderToDetails?.order.partnerComment;
    this.isOwnCommentPopup = true;
  }

  showInvoicesPopup() {
    this.isInvoicesPopup = true;
  }

  showComplaintsPopup() {
    this.isComplaintsPopup = true;
  }

  showMoreDeliveryPopup() {
    this.isMoreDeliveryPopup = true;
  }

  openSzallitasiFeltetelek() {
    window.open('../../../assets/doc/szallitasi_feltetelek.pdf', '_blank');
  }

  closePopup() {
    this.isInvoicesPopup = false;
    this.isOwnCommentPopup = false;
    this.isComplaintsPopup = false;
    this.isMoreDeliveryPopup = false;
  }

  downloadInvoice(id: number, invoiceNumber: string) {
    this.invoicesService.downloadInvoice(id).subscribe({
      next: (blob) => {
        this.dataService.downloadFile(blob, invoiceNumber);
      },
      error: (err) => {
        this.alertBoxService.showAlert(
          this.translate.instant('orders.details.invoicesPopup.downloadError')
        );
      },
    });
  }

  downloadAllInvoices() {
    this.ordersService
      .downloadAllInvoices(this.orderToDetails!.order.id)
      .subscribe({
        next: (blob) => {
          this.dataService.downloadFile(
            blob,
            `${this.orderToDetails!.order!.confirmationName}`,
            'zip'
          );
        },
        error: (err) => {
          this.alertBoxService.showAlert(
            this.translate.instant('orders.details.invoicesPopup.downloadError')
          );
        },
      });
  }

  downloadStatements() {
    this.ordersService
      .downloadStatements(this.orderToDetails!.order.id)
      .subscribe({
        next: (blob) => {
          this.dataService.downloadFile(
            blob,
            `statements-${this.orderToDetails?.order.confirmationName}`,
            'zip'
          );
        },
        error: (err) => {
          this.alertBoxService.showAlert(
            this.translate.instant('orders.details.invoicesPopup.downloadError')
          );
        },
      });
  }

  onSubmit() {
    this.ordersService
      .sendPartnerComment(
        this.orderToDetails!.order.id,
        this.ownComment.comment!
      )
      .subscribe({
        next: () => {
          this.alertBoxService.showInfo(
            this.translate.instant('orders.details.commentPopup.successMessage')
          );
          this.reloadList.emit();
          this.orderToDetails!.order.partnerComment = this.ownComment.comment;
          this.closePopup();
        },
      });
  }

  downloadOrder() {
    this.ordersService.downloadOrder(this.orderToDetails!.order!.id).subscribe({
      next: (blob) => {
        this.dataService.downloadFile(
          blob,
          `${this.orderToDetails!.order!.confirmationName}`
        );
      },
      error: (err) => {
        this.alertBoxService.showAlert(
          this.translate.instant('orders.details.invoicesPopup.downloadError')
        );
      },
    });
  }

  formatDateToFileName() {
    const today = new Date();
    return (
        today.getFullYear() +
        '_' +
        String(today.getMonth() + 1).padStart(2, '0') +
        '_' +
        String(today.getDate()).padStart(2, '0')
    );
  }

  downloadStandGlassPdf() {
    this.ordersService.downloadGlassPdf(this.orderToDetails!.order!.confirmationName).subscribe({
      next: (resp) => {
        const currentDate = this.formatDateToFileName();
        saveAs(resp, `${this.orderToDetails!.order!.confirmationName}_${currentDate}`);
      },
    });
  }

  downloadStandGlassCsv() {
    this.ordersService.downloadGlassCsv(this.orderToDetails!.order!.confirmationName).subscribe({
      next: (resp) => {
        const currentDate = this.formatDateToFileName();
        saveAs(resp, `${this.orderToDetails!.order!.confirmationName}_${currentDate}`);
      },
    });
  }
}
