<div class="partner-dashboard container-width">
  <div class="partner-dashboard-header">
    <div class="partner-dashboard-header-text">
      <h1>
        <img
          src="../../../../assets/img/ceglass-logo.svg"
          alt=""
          class="img-header"
        />
        <span>{{ "dashboard.headTile.title" | translate }}</span>
      </h1>
      <h2>{{ "dashboard.headTile.subTitle" | translate }}</h2>
      <p class="header-company m-top-1">
        <i class="bi bi-building"></i><span>{{ companyName }}</span>
      </p>
    </div>
    <div class="partner-dashboard-header-social_icons">
      <a
        href="https://www.facebook.com/ceglassindustriesszeged/"
        target="_blank"
        ><i class="bi bi-facebook"></i
      ></a>
      <a href="https://www.instagram.com/ceglass_industries/" target="_blank"
        ><i class="bi bi-instagram"></i
      ></a>
      <a
        href="https://www.linkedin.com/company/ceglassindustries/"
        target="_blank"
        ><i class="bi bi-linkedin"></i
      ></a>
      <a href="https://www.tiktok.com/@ceglassindustries" target="_blank"
        ><i class="bi bi-tiktok"></i
      ></a>
      <a
        href="https://www.youtube.com/channel/UCdiTDzXzDBSnpL8upzUihwA"
        target="_blank"
        ><i class="bi bi-youtube"></i
      ></a>
      <a href="https://ceglass.eu/" target="_blank"
        ><i class="bi bi-globe"></i
      ></a>
    </div>
  </div>
  <div class="partner-dashboard-grid">
    @if (canReadOrders) {
    <div class="dashboard-data-orders">
      <div class="card-inner">
        <h3 id="order-title" class="dashboard-data-title card-title">
          <span [className]="orderStatus"></span
          >{{ "dashboard.orders.title" | translate }}
        </h3>
        <div class="orders-data">
          <ul class="simple-ul-table">
            <li>
              <span>{{ "dashboard.orders.inProgress" | translate }}</span
              ><span>{{ orders?.inProgress }}</span>
            </li>
            <li>
              <span>{{ "dashboard.orders.confirmed" | translate }}</span
              ><span>{{ orders?.confirmed }}</span>
            </li>
            <li>
              <span>{{ "dashboard.orders.completed" | translate }}</span
              ><span>{{ orders?.completed }}</span>
            </li>
          </ul>
        </div>
      </div>
      <dx-button
        class="secondary btn-sm font-lg m-top-1"
        [routerLink]="AppRouteNames.PARTNER_ORDERS"
      >
        <i class="bi bi-card-list"></i
        ><span>{{ "dashboard.orders.detailsButton" | translate }}</span>
      </dx-button>
    </div>
    } @else {
    <div class="orders-data no-permission-card">
      <i class="bi bi-cart4"></i>
      <p>{{ "dashboard.orders.noAccess" | translate }}</p>
    </div>
    } @if (canReadInvoices) {
    <div class="dashboard-data-invoices">
      <div class="card-inner">
        <h3 id="invoices-title" class="dashboard-data-title card-title">
          <span [className]="invoiceStatus"></span>
          {{ "dashboard.invoices.title" | translate }}
        </h3>
        <div class="invoices-data">
          <ul class="simple-ul-table">
            <li>
              <span>{{ "dashboard.invoices.pendingPayment" | translate }}</span
              ><span>{{ invoices?.pendingPayment }}</span>
            </li>
            <li>
              <span>{{ "dashboard.invoices.overduePayment" | translate }}</span
              ><span>{{ (invoices?.overdueAmount)!.count }}</span>
            </li>
            <li *ngIf="invoices?.overdueAmount?.count != 0">
              <span>{{ "dashboard.invoices.overdueAmount" | translate }}</span>
              <span *ngFor="let amount of invoices?.overdueAmount?.prices">
                {{ amount.price | ceNumberFormat }} {{ amount.currency }}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <dx-button
        class="secondary btn-sm font-lg m-top-1"
        [routerLink]="AppRouteNames.PARTNER_INVOICES"
      >
        <i class="bi bi-card-list"></i
        ><span>{{ "dashboard.invoices.detailsButton" | translate }}</span>
      </dx-button>
    </div>
    } @else {
    <div class="invoices-data no-permission-card">
      <i class="bi bi-file-earmark-medical"></i>
      <p>{{ "dashboard.invoices.noAccess" | translate }}</p>
    </div>
    } @if (canReadStands) {
    <div class="dashboard-data-stands">
      <div class="card-inner">
        <h3 id="stands-title" class="dashboard-data-title card-title">
          <span [className]="standsStatus"></span
          >{{ "dashboard.stands.title" | translate }}
        </h3>
        <dx-scroll-view
          #scrollView
          id="scrollview"
          showScrollbar="always"
          class="stands-data"
          height="9rem"
        >
          <ul class="simple-ul-table">
            <li>
              <span>{{
                "dashboard.stands.outsideStandCount" | translate
              }}</span>
              <span>{{ stands?.outsideStandCount }}</span>
            </li>
            <li>
              <span>{{
                "dashboard.stands.overdueOutsideStandCount" | translate
              }}</span>
              <span>{{ stands?.overdueOutsideStandCount }}</span>
            </li>
            <li>
              <span>{{ "dashboard.stands.firstDeadline" | translate }}</span>
              <span>{{ stands?.firstDeadline | ceDateFormat }}</span>
            </li>
          </ul>
        </dx-scroll-view>
      </div>
      <dx-button
        class="secondary btn-sm font-lg m-top-1"
        [routerLink]="AppRouteNames.PARTNER_STAND_GLASSES"
      >
        <i class="bi bi-card-list"></i
        ><span>{{ "dashboard.stands.detailsButton" | translate }}</span>
      </dx-button>
    </div>
    } @else {
    <div class="stands-data no-permission-card">
      <i class="bi bi-bookshelf"></i>
      <p>{{ "dashboard.stands.noAccess" | translate }}</p>
    </div>
    }

    <div class="partner-dashboard-newsletter">
      <div class="newsletter-form-container">
        <h3 class="title-lg">
          {{ "dashboard.newsletter.beTheFirst" | translate }}:
        </h3>
        <div class="newsletter-checks">
          <div>
            <i class="bi bi-check-circle"></i
            >{{ "dashboard.newsletter.actions" | translate }}
          </div>
          <div>
            <i class="bi bi-check-circle"></i
            >{{ "dashboard.newsletter.professionalContent" | translate }}
          </div>
          <div>
            <i class="bi bi-check-circle"></i
            >{{ "dashboard.newsletter.pr" | translate }}
          </div>
          <div>
            <i class="bi bi-check-circle"></i
            >{{ "dashboard.newsletter.technicalDetails" | translate }}
          </div>
        </div>
        @if (canSubNewsletter) {
        <div class="newsletter-form">
          <div *ngIf="alreadySub" class="newsletter-thank-you">
            <p>
              <i class="bi bi-envelope"></i
              ><span>{{ "dashboard.newsletter.success" | translate }}</span>
            </p>
          </div>
          <form *ngIf="!alreadySub" (ngSubmit)="onSubmit()" class="form-lg">
            <dx-form
              #newsletterForm
              [formData]="newsletterFormData"
              labelLocation="top"
            >
              <dxi-item dataField="companyName"
                ><dxo-label
                  [text]="'dashboard.newsletter.company' | translate"
                ></dxo-label
                ><dxi-validation-rule
                  type="required"
                  [message]="'error.requiredMessage' | translate"
                ></dxi-validation-rule
              ></dxi-item>
              <dxi-item dataField="email" [editorOptions]="{ mode: 'email' }"
                ><dxo-label text="E-mail"></dxo-label
                ><dxi-validation-rule
                  type="email"
                  [message]="'error.emailInvalidMessage' | translate"
                ></dxi-validation-rule>
                <dxi-validation-rule
                  type="required"
                  [message]="'error.requiredMessage' | translate"
                ></dxi-validation-rule
              ></dxi-item>
            </dx-form>

            <p class="alert-strip m-top-1-5" *ngIf="newsletterError">
              {{ "dashboard.newsletter.unsuccess" | translate }}
            </p>
            <dx-button
              class="primary btn-lg bg-teal-3 m-top-2"
              [useSubmitBehavior]="true"
              ><i class="bi bi-check-circle"></i
              ><span>{{ "dashboard.newsletter.subscribe" | translate }}</span>
            </dx-button>
          </form>
        </div>
        } @else {
        <div class="newsletter-thank-you">
          <p>
            <i class="bi bi-envelope-slash"></i>
            <span>{{ "dashboard.newsletter.noAccess" | translate }}</span>
          </p>
        </div>

        }
      </div>
    </div>
    <div class="partner-dashboard-quote">
      <app-quote-slider></app-quote-slider>
    </div>
    <div class="partner-dashboard-bottom-sales">
      <h3 class="title-lg teal-3 m-bottom-1">
        {{ "dashboard.priceLists.title" | translate }}
      </h3>
      <div class="button-grid">
        <div class="sales-card glass-stand" (click)="showInfo()">
          <div class="background"></div>
          <div class="text">
            <i class="bi bi-bookshelf"></i>
            <p>{{ "dashboard.priceLists.standsTile" | translate }}</p>
          </div>
        </div>
        <div class="sales-card tools" (click)="showInfo()">
          <div class="background"></div>
          <div class="text">
            <i class="bi bi-tools"></i>
            <p>{{ "dashboard.priceLists.toolsTile" | translate }}</p>
          </div>
        </div>
        <div class="sales-card guides" (click)="showInfo()">
          <div class="background"></div>
          <div class="text">
            <i class="bi bi-journal-check"></i>
            <p>{{ "dashboard.priceLists.helpersTile" | translate }}</p>
          </div>
        </div>
        <div class="sales-card inquiry" (click)="togglePopup()">
          <div class="background"></div>
          <div class="text">
            <i class="bi bi-question-circle"></i>
            <p>{{ "dashboard.priceLists.interestedTile" | translate }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="partner-dashboard-bottom-images">
      <app-image-slider></app-image-slider>
    </div>
  </div>
</div>
<div class="popup" *ngIf="isPopupOpen">
  <div class="popup-container">
    <dx-button
      (onClick)="togglePopup()"
      class="primary bg-teal-5 icon-only close"
    >
      <i class="bi bi-x-lg"></i>
    </dx-button>
    <div class="generic-block" (click)="$event.stopPropagation()">
      <h3 class="card-title teal-5">
        {{ "dashboard.priceLists.interestedPopup.title" | translate }}
      </h3>
      <form class="no-summary">
        <dx-form
          #sendInterestedForm
          [formData]="orderData"
          [showValidationSummary]="true"
          labelLocation="top"
          [showColonAfterLabel]="false"
          [colCount]="1"
        >
          <dxi-item dataField="subject" editorType="dxTextBox"
            ><dxo-label
              [text]="
                'dashboard.priceLists.interestedPopup.subject' | translate
              "
            ></dxo-label>
            <dxi-validation-rule
              type="required"
              [message]="'error.requiredMessage' | translate"
            ></dxi-validation-rule>
          </dxi-item>
          <dxi-item
            dataField="message"
            editorType="dxTextArea"
            [editorOptions]="{ height: 200 }"
            ><dxo-label
              [text]="
                'dashboard.priceLists.interestedPopup.message' | translate
              "
            ></dxo-label>
            <dxi-validation-rule
              type="required"
              [message]="'error.requiredMessage' | translate"
            ></dxi-validation-rule>
            <dxi-validation-rule
              type="stringLength"
              [min]="25"
              [message]="'error.minCharMessage' | translate : { number: 25 }"
            >
            </dxi-validation-rule>
            <dxi-validation-rule
              type="stringLength"
              [max]="2001"
              [message]="'error.maxCharMessage' | translate : { number: 2001 }"
            >
            </dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="deliveryAddress" editorType="dxTextBox"
            ><dxo-label
              [text]="
                'dashboard.priceLists.interestedPopup.deliveryAddress'
                  | translate
              "
            ></dxo-label>
          </dxi-item>
          <dxi-item
            #fileUploader
            dataField="attachments"
            editorType="dxFileUploader"
            [editorOptions]="{
              maxFileSize: 10000000000,
              accept: AppConstants.ACCEPTED_FILE_TYPE.join(','),
              uploadMode: 'useButton',
              multiple: true,
            }"
          >
            <dxo-label
              [text]="'common.fileUploader.attachments' | translate"
            ></dxo-label>
            <dxi-validation-rule
              #fileValidation
              type="custom"
              [validationCallback]="validateFiles"
              [message]="'error.attachmentsInvalidMessage' | translate"
            ></dxi-validation-rule>
          </dxi-item>
          <dxi-item type="group">
            <p>
              {{
                "common.fileUploader.attachmentsMessage"
                  | translate
                    : {
                        fileCount: AppConstants.FILE_COUNT_LIMIT,
                        fileSize: fileSize,
                        maxFileSize: AppConstants.FILE_SIZE_MAXIMUM_MB
                      }
              }}
            </p>
          </dxi-item>
          <dxi-item type="group">
            <div class="button-row right">
              <dx-button
                #submitRequestOrderButton
                [useSubmitBehavior]="true"
                (onClick)="onInterestedSubmit()"
                class="btn-sm primary bg-teal-5 font-lg"
              >
                <i class="bi bi-envelope-arrow-up"></i>
                <span>{{
                  "dashboard.priceLists.interestedPopup.sendButton" | translate
                }}</span>
              </dx-button>
            </div>
          </dxi-item>
        </dx-form>
      </form>
    </div>
  </div>
</div>
