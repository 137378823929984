<div class="breadcrumb-actions-row container-width">
  <app-breadcrumb class="breadcrumb-group"></app-breadcrumb>
</div>
<div
  class="stands-data container-width main-grid mg-66-33"
  [ngClass]="{ 'full-width': standToDetails == undefined }"
>
  <div class="stands-data-details left-col">
    <app-stands-summary
      (setListFullWidth)="setListFullWidth($event)"
    ></app-stands-summary>
    <app-stands-list
      #standsList
      (standToDetails)="getStandToDetails($event)"
      class="h100p"
    ></app-stands-list>
  </div>
  <div class="standglasses-data-single" *ngIf="standToDetails != undefined">
    <app-stand-details
      [isSingleView]="false"
      [standToDetails]="standToDetails"
    ></app-stand-details>
  </div>
</div>
<app-spinner *ngIf="!isLoaded"></app-spinner>
