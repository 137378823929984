import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { LoginDto } from '../../../../../shared/models/login-dto';
import { AppRouteNames } from '../../app-route-names';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.less',
})
export class AuthComponent {
  authFormData: LoginDto = <LoginDto>{};
  loginError: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  onSubmit() {
    this.authService.fetchLoginToken(this.authFormData).subscribe({
      next: (token) => {
        this.authService.successLogin(
          token,
          this.route.snapshot.queryParamMap.get('return-path')
        );
      },
      error: (e) => {
        this.loginError = true;
      },
    });
  }

  routePassword() {
    this.router.navigate([AppRouteNames.LOGIN]);
  }

  protected readonly AppRouteNames = AppRouteNames;
}
