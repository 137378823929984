import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../AuthComponents/auth/auth.service';
import { AppRouteNames } from '../app-route-names';

@Injectable({ providedIn: 'root' })
class SalesPermissionService {
  user = {
    authLevel: '',
  };

  constructor(private router: Router, private authService: AuthService) {
    this.user = JSON.parse(localStorage.getItem('userData')!);
  }

  canActivate(state: RouterStateSnapshot): boolean {
    if (this.user.authLevel == 'SALES' || this.user.authLevel == 'ADMIN') {
      this.router.navigate([AppRouteNames.SALES_MAIN_COMPONENT]);
      return false;
    }
    return true;
  }
}

export const SalesPermissionGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean => {
  return inject(SalesPermissionService).canActivate(state);
};
