import { Component, ViewChild } from '@angular/core';
import { DashboardService } from '../dashboard/dashboard.service';
import { AppConstants } from '../../../../../../shared/app-constants';
import { DxSelectBoxComponent } from 'devextreme-angular';
import {AuthService} from "../../../AuthComponents/auth/auth.service";

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrl: './documents.component.less',
})
export class DocumentsComponent {
  @ViewChild('languageSelector') languageSelector?: DxSelectBoxComponent;
  documents?: any[];
  selectedLang?: string;
  constructor(
    private dashboardService: DashboardService,
    private authService: AuthService,
  ) {
    this.selectedLang=this.authService.getUserData().language;
    this.languageChanged();
  }

  languageChanged() {
      this.dashboardService.getDocuments().subscribe((documents: any) => {
        this.documents = Object.values(
          documents[this.languageSelector!.value.toLowerCase()]
        );
      });
  }
  protected readonly AppConstants = AppConstants;

}
