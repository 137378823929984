import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../AuthComponents/auth/auth.service';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AppRouteNames } from '../../app-route-names';
import { SimpleUserDto } from '../../../../../shared/models/simple-user-dto';
import { DataService } from '../../data.service';
import {loadMessages, locale} from "devextreme/localization";
import huMessages from "devextreme/localization/messages/hu.json";
import roMessages from "devextreme/localization/messages/ro.json";
import enMessages from "devextreme/localization/messages/en.json";
import srMessages from "../../../assets/i18n/dx-sr.json";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.less',
})
export class HeaderComponent implements OnInit {
  isUserLoggedIn?: Observable<boolean>;
  languages = this.translate.getLangs();
  userType: string = '';
  userNeedsPassChange?: boolean;
  user?: SimpleUserDto;
  menuOpen: boolean = false;
  menus: string[] = [];

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    public dataService: DataService
  ) {}

  ngOnInit(): void {
    this.isUserLoggedIn = this.authService.getUserLoggedIn();
    this.user = this.authService.getUserData();
    if (this.user) {
      this.translate.use(this.user.language.toLowerCase());
      switch (this.user.language.toLowerCase()) {
        case "en":
          loadMessages(enMessages);
          break;
        case "sr":
          loadMessages(srMessages);
          break;
        case "ro":
          loadMessages(roMessages);
          break;
        case "hu":
          loadMessages(huMessages);
          break;
      }
      locale(this.user.language.toLowerCase());

      this.userType = this.user.authLevel;
      this.userNeedsPassChange = this.user.needPasswordChange;
      this.menus = this.user.partnerParams;
    }
  }

  onLogout() {
    this.authService.logout();
  }
  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  protected readonly AppRouteNames = AppRouteNames;
}
