<div class="generic-block h100p justify-vertically">
  <div class="stand-details-card-top">
    <div class="generic-block-header-row">
      <h3 class="card-title teal-5">
        {{ "stands.details.title" | translate }}
      </h3>
    </div>
    <h4 class="card-title secondary">
      {{ "stands.details.highlightsSection.title" | translate }}
    </h4>
    <div class="generic-data-grid m-bottom-1-5">
      <div class="generic-data-group col-2">
        <div class="generic-data-block">
          <p class="generic-data-block-title">
            {{ "stands.details.highlightsSection.id" | translate }}
          </p>
          <p class="generic-data-block-value">
            {{ standToDetails?.id }}
          </p>
        </div>
        <div class="generic-data-block">
          <p class="generic-data-block-title">
            {{ "stands.details.highlightsSection.status" | translate }}
          </p>
          <p class="generic-data-block-value">
            {{ getStatusText(standToDetails?.status!) }}
          </p>
        </div>
        <div class="generic-data-block">
          <p class="generic-data-block-title">
            {{ "stands.details.highlightsSection.movementDate" | translate }}
          </p>
          <p class="generic-data-block-value">
            {{ standToDetails?.movementDate | ceDateFormat }}
          </p>
        </div>
        <div class="generic-data-block">
          <p class="generic-data-block-title">
            {{ "stands.details.highlightsSection.rentalStart" | translate }}
          </p>
          <p class="generic-data-block-value">
            {{ standToDetails?.rentalStart | ceDateFormat }}
          </p>
        </div>
        <div class="generic-data-block">
          <p class="generic-data-block-title">
            {{
              "stands.details.highlightsSection.invoicedRentalDays" | translate
            }}
          </p>
          <p class="generic-data-block-value">
            {{ standToDetails?.invoicedRentalDays }}
          </p>
        </div>
        <div class="generic-data-block">
          <p class="generic-data-block-title">
            {{ "stands.details.highlightsSection.rentalCost" | translate }}
          </p>
          <p class="generic-data-block-value">
            {{ standToDetails?.rentalCost }} {{ standToDetails?.currency }}
          </p>
        </div>
        <div class="generic-data-block">
          <p class="generic-data-block-title">
            {{
              "stands.details.highlightsSection.returnStandIndicatedDate"
                | translate
            }}
          </p>
          <p class="generic-data-block-value">
            {{
              (standToDetails?.returnStandIndicatedDate | ceDateFormat) ||
                "&nbsp;"
            }}
          </p>
        </div>
        <div class="generic-data-block">
          <p class="generic-data-block-title">
            {{ "stands.details.highlightsSection.returnDate" | translate }}
          </p>
          <p class="generic-data-block-value">
            {{ (standToDetails?.returnDate | ceDateFormat) || "&nbsp;" }}
          </p>
        </div>
      </div>
    </div>
    <h4 class="card-title secondary">
      {{ "stands.details.otherDataSection.title" | translate }}
    </h4>
    <div class="generic-data-grid">
      <div class="generic-data-group col-2">
        <div class="generic-data-block">
          <p class="generic-data-block-title">
            {{
              "stands.details.otherDataSection.deliveryNoteNumber" | translate
            }}
          </p>
          <p class="generic-data-block-value">
            {{ standToDetails?.deliveryNoteNumber }}
          </p>
        </div>
        <div class="generic-data-block has-icon-button">
          <p class="generic-data-block-title">
            {{ "stands.details.otherDataSection.barcode" | translate }}
          </p>
          <dx-button class="tertiary font-lg" (click)="showBarcode()">
            <i class="bi bi-upc-scan"></i>
          </dx-button>
          <p class="generic-data-block-value">
            {{ standToDetails?.barcode }}
          </p>
        </div>
        <div class="generic-data-block">
          <p class="generic-data-block-title">
            {{ "stands.details.otherDataSection.city" | translate }}
          </p>
          <p class="generic-data-block-value">
            {{ standToDetails?.city }}
          </p>
        </div>
        <div class="generic-data-block">
          <p class="generic-data-block-title">
            {{ "stands.details.otherDataSection.address" | translate }}
          </p>
          <p class="generic-data-block-value">
            {{ standToDetails?.address }}
          </p>
        </div>
      </div>
    </div>
    <dx-button
      class="primary font-lg btn-sm bg-teal-5 m-top-1 m-bottom-1-5"
      *ngIf="
        standsService.isPartnerCanSendBackStand &&
        standToDetails?.status === 'OUTSIDE'
      "
      (click)="toggleSendBackStand()"
    >
      <i class="bi bi-arrow-90deg-up"></i
      ><span> {{ "stands.details.standReturnButton" | translate }}</span>
    </dx-button>
  </div>
  <div class="stand-details-card-bottom">
    <div class="bottom-button-grid">
      <!--       <dx-button (click)="orderStand()" class="tertiary font-lg">
        <i class="bi bi-arrow-left-right"></i>
        <span>Megrendelés</span>
      </dx-button> -->
      <dx-button
        (click)="isComplaintsPopup = true"
        *ngIf="
          !isSingleView &&
          authService.getUserData().partnerParams?.includes('COMPLAINTS') &&
          authService.getUserData()?.original
        "
        class="tertiary font-lg"
      >
        <i class="bi bi-envelope-arrow-up"></i>
        <span>{{
          "invoices.details.buttons.showComplaintsPopup" | translate
        }}</span>
      </dx-button>
    </div>
  </div>
</div>
<div class="popup" *ngIf="isSendBackPopup">
  <div class="popup-container">
    <dx-button
      (onClick)="closePopup()"
      class="primary bg-teal-5 close icon-only"
    >
      <i class="bi bi-x-lg"></i>
    </dx-button>
    <div class="generic-block" (click)="$event.stopPropagation()">
      <h3 class="card-title teal-5">
        {{ "stands.standsReturnPopup.title" | translate }}
      </h3>
      <p>{{ standToDetails?.id }}</p>
      <p
        [innerHTML]="
          'stands.standsReturnPopup.messageForOneStand'
            | translate
              : {
                  standsReturnButton:
                    '<span>' +
                    ('stands.standsReturnButton' | translate) +
                    '</span>'
                }
        "
      ></p>
      <form>
        <dx-form
          #sendBackStandForm
          [formData]="sendBackStandData"
          labelLocation="top"
          [showColonAfterLabel]="false"
        >
          <dxi-item itemType="group">
            <dxi-item dataField="message" editorType="dxTextArea">
              <dxo-label text="Üzenet" [visible]="false"></dxo-label>
            </dxi-item>
            <dxi-item
              dataField="isUrgent"
              editorType="dxCheckBox"
              [editorOptions]="{
                text: 'stands.standsReturnPopup.urgentOption' | translate
              }"
            >
              <dxo-label text="" [visible]="false"></dxo-label>
            </dxi-item>
            <dxi-item
              dataField="isNeedCrane"
              editorType="dxCheckBox"
              [editorOptions]="{
                text: 'stands.standsReturnPopup.needCraneOption' | translate
              }"
            >
              <dxo-label text="" [visible]="false"></dxo-label>
            </dxi-item>
          </dxi-item>
        </dx-form>
        <div class="button-row right m-top-1-5">
          <dx-button
            class="primary btn-sm font-lg bg-teal-5"
            [useSubmitBehavior]="true"
            (onClick)="sendBackStand()"
          >
            <i class="bi bi-arrow-90deg-up"></i>
            <span>{{
              "stands.standsReturnPopup.sendBackStandsButton" | translate
            }}</span>
          </dx-button>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="popup" *ngIf="isBarcodePopup">
  <div class="popup-container">
    <dx-button
      (onClick)="closePopup()"
      class="primary bg-teal-5 close icon-only"
    >
      <i class="bi bi-x-lg"></i>
    </dx-button>
    <div class="generic-block" (click)="$event.stopPropagation()">
      <img
        style="width: 100%; max-width: 320px; margin: auto; display: block"
        src="{{ standToDetails?.barcodeImage }}"
        alt=""
      />
      <p style="text-align: center; font-size: 1.25rem; font-weight: 700">
        {{ standToDetails?.barcode }}
      </p>
    </div>
  </div>
</div>
<div class="popup" *ngIf="isComplaintsPopup">
  <div class="popup-container">
    <dx-button
      (onClick)="closePopup()"
      class="primary bg-teal-5 close icon-only"
    >
      <i class="bi bi-x-lg"></i>
    </dx-button>
    <div class="generic-block" (click)="$event.stopPropagation()">
      <h3 class="card-title teal-5">
        {{ "stands.complaintsPopup.title" | translate }}
      </h3>
      <app-complaints
        [complaintData]="{
          type: 'STAND',
          id: standToDetails!.id.toString()
        }"
        (formSent)="closePopup()"
      ></app-complaints>
    </div>
  </div>
</div>
