import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../AuthComponents/auth/auth.service';

@Injectable({ providedIn: 'root' })
class LoggedService {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(state: RouterStateSnapshot): any {
    this.authService.isLoggedIn.subscribe((val) => {
      if (val) {
        this.authService.navigateToFirstPage();
        return false;
      }
      return true;
    });
  }
}

export const SignedGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean => {
  return inject(LoggedService).canActivate(state);
};
