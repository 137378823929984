<div class="generic-block generic-block-highlight m-bottom-1">
  <div class="generic-block-header-row no-padding">
    <h3 class="card-title white">{{ "orders.summary.title" |translate }}</h3>
    <div class="button-row gap-2">
      @if (isSummaryVisible) {
      <dx-button (click)="toggleSummary()" class="tertiary font-lg lighter"
        ><i class="bi bi-arrows-angle-contract"></i
        ><span>{{ "common.summary.close" |translate }}</span></dx-button
      >
      } @else {
      <dx-button (click)="toggleSummary()" class="tertiary font-lg lighter"
        ><i class="bi bi-arrows-angle-expand"></i
        ><span>{{ "common.summary.open" |translate }}</span></dx-button
      >
      }
    </div>
  </div>
  <div style="background-color: rgba(255,255,255,0.1); padding: 1rem; border-radius: .5rem" class="m-top-1-5" *ngIf="isSummaryVisible">
      <div
    class="del-orders-summary-data generic-data-group in-highlight-block col-6 "
    [ngClass]="{ 'mobile-view': isMobile }"
    
  >
    <div class="generic-data-block">
      <p class="generic-data-block-title white">{{ "orders.summary.nextDeliveryDate" |translate }}</p>
      <p class="generic-data-block-value white">
        {{ ordersSummary?.nextDeliveryDate || "-" }}
      </p>
    </div>
    <div class="generic-data-block has-icon-button">
      <p class="generic-data-block-title white">{{ "orders.summary.currentOrders" |translate }}</p>
      <dx-button
        (click)="filterBy('ACTUAL')"
        class="tertiary lighter font-lg icon-only"
      >
        <i class="bi bi-filter"></i>
      </dx-button>
      <p class="generic-data-block-value white">
        {{ ordersSummary?.currentOrders?.count }}
      </p>
    </div>
    <div class="generic-data-block has-icon-button">
      <p class="generic-data-block-title white">{{ "orders.summary.closedOrders" |translate }}</p>
      <dx-button
        (click)="filterBy('CLOSED')"
        class="tertiary lighter font-lg icon-only"
      >
        <i class="bi bi-filter"></i>
      </dx-button>
      <p class="generic-data-block-value white">
        {{ ordersSummary?.closedOrders?.count }}
      </p>
    </div>
    <div class="generic-data-block">
      <p class="generic-data-block-title white">{{ "orders.summary.nextDeliveryPrice" |translate }}</p>
      <p class="generic-data-block-value white">
        {{ ordersSummary?.nextDeliveryValue?.price | ceNumberFormat }}
        {{ ordersSummary?.nextDeliveryValue?.currency }}
      </p>
    </div>
    <div class="generic-data-block">
      <p class="generic-data-block-title white">{{ "orders.summary.currentOrdersPrice" |translate }}</p>
      @if (ordersSummary?.currentOrders?.prices!.length > 0) {
      <p
        *ngFor="let order of ordersSummary!.currentOrders.prices"
        class="generic-data-block-value white"
      >
        {{ order.price | ceNumberFormat }}
        {{ order.currency }}
      </p>
      } @else {
      <p class="generic-data-block-value white">0</p>
      }
    </div>
    <div class="generic-data-block">
      <p class="generic-data-block-title white">{{ "orders.summary.closedOrdersPrice" |translate }}</p>
      @if (ordersSummary?.closedOrders?.prices!.length > 0) {
      <p
        *ngFor="let order of ordersSummary!.closedOrders.prices"
        class="generic-data-block-value white"
      >
        {{ order.price | ceNumberFormat }}
        {{ order.currency }}
      </p>
      } @else {
      <p class="generic-data-block-value white">0</p>
      }
    </div>
  </div>
  </div>

</div>
