import { Component } from '@angular/core';
import { DashboardService } from '../dashboard.service';
import { AuthService } from '../../../../AuthComponents/auth/auth.service';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrl: './image-slider.component.less',
})
export class ImageSliderComponent {
  images: any[];
  lang: string = '';

  constructor(
    private dashboardService: DashboardService,
    private authService: AuthService
  ) {
    this.images = [];
  }

  ngOnInit(): void {
    this.dashboardService.getImages().subscribe((images) => {
      const imagesArray = Object.values(images);
      this.images = this.dashboardService.getRandomItems(imagesArray, 5);
    });
    this.lang = this.authService.getUserData().language.toLocaleLowerCase();
  }
}
