import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../shared/environments/environment';
import { ComplainDto } from '../../../../../../shared/models/complain-dto';

@Injectable({ providedIn: 'root' })
export class ComplaintsService {
  constructor(private http: HttpClient) {}

  sendComplaint(dto: ComplainDto, attachments?: File[]) {
    let fileData = new FormData();
    if (attachments && attachments.length > 0) {
      attachments?.forEach((attachment) => {
        fileData.append('files', attachment, attachment.name);
      });
    }
    let url = `${
      environment.apiUrl
    }api/complaint/request?complaintType=${encodeURI(
      dto.complaintType
    )}&message=${encodeURI(dto.message!)}`;
    if (dto.extraId) url += `&extraId=${encodeURI(dto.extraId!)}`;
    return this.http.post<ComplainDto>(url, fileData);
  }
}
