export class AppRouteNames {
  public static readonly SALES_MAIN_COMPONENT = 'salesman-main';
  public static readonly FORGOT_PASSWORD = 'forgot-password';
  public static readonly CHANGE_PASSWORD = 'change-password';
  public static readonly LOGIN = 'login';
  public static readonly RELATIVE_DASHBOARD = 'dashboard';
  public static readonly RELATIVE_ORDERS = 'orders';
  public static readonly RELATIVE_INVOICES = 'invoices';
  public static readonly RELATIVE_STAND_GLASSES = 'stands';
  public static readonly RELATIVE_DOCUMENTS = 'documents';
  public static readonly RELATIVE_CONNECTIONS = 'connections';
  public static readonly RELATIVE_PROFILE = 'profile';
  public static readonly RELATIVE_COMPLAINTS = 'complaints';
  public static readonly RELATIVE_QUESTIONNAIRE = 'questionnaire';
  public static readonly PARTNER_MAIN_COMPONENT = 'partner-main';
  public static readonly PARTNER_DASHBOARD = this.buildURL(
    this.PARTNER_MAIN_COMPONENT,
    this.RELATIVE_DASHBOARD
  );
  public static readonly PARTNER_ORDERS = this.buildURL(
    this.PARTNER_MAIN_COMPONENT,
    this.RELATIVE_ORDERS
  );
  public static readonly PARTNER_INVOICES = this.buildURL(
    this.PARTNER_MAIN_COMPONENT,
    this.RELATIVE_INVOICES
  );
  public static readonly PARTNER_STAND_GLASSES = this.buildURL(
    this.PARTNER_MAIN_COMPONENT,
    this.RELATIVE_STAND_GLASSES
  );
  public static readonly PARTNER_DOCUMENTS = this.buildURL(
    this.PARTNER_MAIN_COMPONENT,
    this.RELATIVE_DOCUMENTS
  );
  public static readonly PARTNER_CONNECTIONS = this.buildURL(
    this.PARTNER_MAIN_COMPONENT,
    this.RELATIVE_CONNECTIONS
  );
  public static readonly PARTNER_COMPLAINTS = this.buildURL(
    this.PARTNER_MAIN_COMPONENT,
    this.RELATIVE_COMPLAINTS
  );
  public static readonly PARTNER_QUESTIONNAIRE = this.buildURL(
    this.PARTNER_MAIN_COMPONENT,
    this.RELATIVE_QUESTIONNAIRE
  );
  public static readonly PARTNER_PROFILE = this.buildURL(
    this.PARTNER_MAIN_COMPONENT,
    this.RELATIVE_PROFILE
  );

  public static readonly FALLBACK = '**';

  private static buildURL(...parts: string[]): string {
    return '/' + parts.filter(Boolean).join('/');
  }
}
