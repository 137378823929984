import { Component, Input, Output } from '@angular/core';
import { InvoiceSummaryDto } from '../../../../../../../../shared/models/invoice-summary-dto';
import { InvoicesService } from '../../invoices.service';
import { STATUS_ICON } from '../../../../../../../../shared/app-constants';

@Component({
  selector: 'app-invoices-summary-card',
  templateUrl: './invoices-summary-card.component.html',
  styleUrl: './invoices-summary-card.component.less',
})
export class InvoicesSummaryCardComponent {
  @Input() invoicesSummary?: InvoiceSummaryDto;
  invoiceStatus?: STATUS_ICON;

  constructor(private invoicesService: InvoicesService) {}

  ngOnInit(): void {
    if (
      this.invoicesSummary?.overdueInvoices.count! < 1 &&
      this.invoicesSummary?.payableInvoices.count! < 1
    ) {
      this.invoiceStatus = STATUS_ICON.OK;
    } else if (this.invoicesSummary?.overdueInvoices.count! > 0) {
      this.invoiceStatus = STATUS_ICON.ERROR;
    } else {
      this.invoiceStatus = STATUS_ICON.WARNING;
    }
  }

  filterBy(status: string) {
    this.invoicesService.filteredCompany.next({
      status: status,
      company: this.invoicesSummary?.ceCompany?.name!,
    });
  }
}
