<div class="generic-block h100p justify-vertically">
  <div class="invoice-details-card-top">
    <div class="generic-block-header-row">
      <h3 class="card-title teal-5">{{ "invoices.details.title" |translate }}</h3>
      <div class="button-row gap-2">
        <dx-button class="tertiary font-lg" (click)="copyUrl()"
          ><i class="bi bi-copy"></i><span>{{ "invoices.details.copyDetailsUrl" |translate }}</span></dx-button
        >
        <dx-button
          class="tertiary font-lg"
          (click)="goToUrl()"
          *ngIf="!isSingleView"
        >
          <i class="bi bi-box-arrow-up-right"></i>
        </dx-button>
      </div>
    </div>
    <div class="detailer-featured">
      <div class="order-main-details-card-body-data">
        <p class="detailer-featured-title">{{ "invoices.details.invoiceNumber" |translate }}</p>
        <p class="detailer-featured-value">
          {{ invoiceToDetails?.invoiceNumber }}
        </p>
      </div>
      <div class="order-main-details-card-body-data">
        <p class="detailer-featured-title">{{ "invoices.details.firstDownloadDate" |translate }}</p>
        <p class="detailer-featured-value">
          {{ invoiceToDetails?.firstDownloadDate | ceDateTimeFormat }}
        </p>
      </div>
      <div class="order-main-details-card-body-data">
        <p class="detailer-featured-title">{{ "invoices.details.issueDate" |translate }}</p>
        <p class="detailer-featured-value">
          {{ invoiceToDetails?.issueDate | ceDateFormat }}
        </p>
      </div>
      <div class="order-main-details-card-body-data">
        <p class="detailer-featured-title">{{ "invoices.details.dueDate" |translate }}</p>
        <p class="detailer-featured-value">
          {{ invoiceToDetails?.dueDate | ceDateFormat }}
        </p>
      </div>
      <div class="order-main-details-card-body-data">
        <p class="detailer-featured-title">{{ "invoices.details.completionDate" |translate }}</p>
        <p class="detailer-featured-value">
          {{ invoiceToDetails?.completionDate | ceDateFormat }}
        </p>
      </div>
      <div class="order-main-details-card-body-data">
        <p class="detailer-featured-title">{{ "invoices.details.balance" |translate }}</p>
        <p class="detailer-featured-value">
          {{ invoiceToDetails?.balance! | ceNumberFormat }}
          {{ invoiceToDetails?.currency }}
        </p>
      </div>
    </div>
    <h4 class="card-title secondary m-top-2">{{ "invoices.details.balance" |translate }}</h4>
    <div class="generic-data-group col-2">
      <div class="generic-data-block">
        <p class="generic-data-block-title">{{ "invoices.details.netPrice" |translate }}</p>
        <p class="generic-data-block-value">
          {{ invoiceToDetails?.netPrice! | ceNumberFormat }}
        </p>
      </div>
      <div class="generic-data-block">
        <p class="generic-data-block-title">{{ "invoices.details.grossPrice" |translate }}</p>
        <p class="generic-data-block-value">
          {{ invoiceToDetails?.grossPrice! | ceNumberFormat }}
        </p>
      </div>
      <div class="generic-data-block">
        <p class="generic-data-block-title">{{ "invoices.details.invoiceType" |translate }}</p>
        <p class="generic-data-block-value">
          {{ invoicesService.getInvoiceTypeCellText(invoiceToDetails) }}
        </p>
      </div>
      <div class="generic-data-block">
        <p class="generic-data-block-title">{{ "invoices.details.currency" |translate }}</p>
        <p class="generic-data-block-value">{{ invoiceToDetails?.currency }}</p>
      </div>
      <div class="generic-data-block">
        <p class="generic-data-block-title">{{ "invoices.details.vatAmount" |translate }}</p>
        <p class="generic-data-block-value">
          {{ invoiceToDetails?.vatAmount! | ceNumberFormat }}
        </p>
      </div>
      <div class="generic-data-block">
        <p class="generic-data-block-title">{{ "invoices.details.invoiceReferenceHistory" |translate }}</p>
        <p class="generic-data-block-value">
          {{ invoiceToDetails?.invoiceReferenceHistory }}
        </p>
      </div>
    </div>
  </div>
  <div class="invoice-details-card-bottom">
    <h3 class="card-title teal-5 m-top-2">{{ "invoices.details.buttons.title" |translate }}</h3>
    <div class="bottom-button-grid">
      <dx-button
        (click)="downloadInvoice()"
        [disabled]="invoiceToDetails?.pdf == undefined"
        class="tertiary font-lg"
      >
        <i class="bi bi-file-earmark-medical"></i>
        <span>{{ "invoices.details.buttons.downloadInvoice" |translate }}</span>
      </dx-button>
      <dx-button
        (click)="goToOrders()"
        *ngIf="!isSingleView && user?.partnerParams?.includes('ORDERS')"
        [disabled]="!invoiceToDetails?.hasOrder"
        class="tertiary font-lg"
      >
        <i class="bi bi-cart4"></i>
        <span>{{ "invoices.details.buttons.goToOrders" |translate }}</span>
      </dx-button>
      <dx-button
        (click)="downloadStatement()"
        [disabled]="(invoiceToDetails?.performanceStatementList)!.length < 1"
        *ngIf="!isSingleView"
        class="tertiary font-lg"
      >
        <i class="bi bi-file-earmark-check"></i>
        <span>{{ "invoices.details.buttons.downloadPerformanceStatement" |translate }}</span>
      </dx-button>
      <dx-button
        *ngIf="!isSingleView && user?.partnerParams?.includes('STAND_GLASSES')"
        [disabled]="true"
        class="tertiary font-lg"
      >
        <i class="bi bi-bookshelf"></i>
        <span>{{ "invoices.details.buttons.stands" |translate }}</span>
      </dx-button>
      <dx-button
        (click)="showComplaintsPopup()"
        *ngIf="
          !isSingleView &&
          user?.partnerParams?.includes('COMPLAINTS') &&
          user?.original
        "
        class="tertiary font-lg"
      >
        <i class="bi bi-envelope-arrow-up"></i>
        <span>{{ "invoices.details.buttons.showComplaintsPopup" |translate }}</span>
      </dx-button>
        <dx-button
                (click)="downloadETransport()"
                *ngIf="
          !isSingleView &&
          user?.partnerParams?.includes('DATA_SHARING_WITH_ROMANIA') &&
          invoiceToDetails?.hasETransporTxt
        "
                class="tertiary font-lg"
        >
            <i class="bi bi-truck"></i>
            <span>{{ "invoices.details.buttons.etransport" |translate }}</span>
        </dx-button>
    </div>
  </div>
</div>
<div class="popup" *ngIf="isComplaintsPopup">
  <div class="popup-container">
    <dx-button
      (onClick)="closePopup()"
      class="primary bg-teal-5 close icon-only"
    >
      <i class="bi bi-x-lg"></i>
    </dx-button>
    <div class="generic-block" (click)="$event.stopPropagation()">
      <h3 class="card-title teal-5">{{ "invoices.details.complaintsPopup.title" |translate }}</h3>
      <app-complaints
        [complaintData]="{
          type: 'INVOICE',
          id: invoiceToDetails!.invoiceNumber.toString()
        }"
        (formSent)="formSent($event)"
      ></app-complaints>
    </div>
  </div>
</div>
