import { Component } from '@angular/core';
import { AlertBoxService } from './alert-box.service';

@Component({
  selector: 'app-alert-box',
  templateUrl: './alert-box.component.html',
  styleUrl: './alert-box.component.less',
})
export class AlertBoxComponent {
  alertMessage: string = '';
  infoMessage: string = '';
  showAlert: boolean = false;
  showInfo: boolean = false;

  constructor(private alertBoxService: AlertBoxService) {}

  ngOnInit(): void {
    this.alertBoxService.alertSubject.subscribe((message) => {
      this.alertMessage = message;
      this.showAlert = true;
    });
    this.alertBoxService.infoSubject.subscribe((message) => {
      this.infoMessage = message;
      this.showInfo = true;
      setTimeout(() => {
        this.closeInfoBox();
      }, 3000);
    });
  }

  closeAlert() {
    this.showAlert = false;
  }
  closeInfoBox() {
    this.showInfo = false;
  }
}
