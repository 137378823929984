<div class="login-bg">
  <app-header class="white"></app-header>
  <div class="forgotten-pass-container login-container">
    <div class="card">
      <h3 class="title-lg m-bottom-2">{{ "forgottenPassword.title" | translate }}</h3>
      <p class="black m-bottom-1-5">
          {{ "forgottenPassword.description" | translate }}
      </p>
      <div class="forgotten-pass-form">
        <form  (ngSubmit)="onSubmit()" class="form-lg">
          <dx-form
            #passChangeForm
            [formData]="forgottenPassData"
            validationGroup="formValidations"
            labelLocation="top"
          >
            <dxi-item
              dataField="email"
              [editorOptions]="{
                mode: 'email',
                disabled: successfulRequest
              }"
            >
              <dxo-label [text]="'Email'"></dxo-label>
              <dxi-validation-rule
                type="email"
                [message]="'error.emailInvalidMessage' | translate"
              ></dxi-validation-rule>
              <dxi-validation-rule
                type="required"
                [message]="'error.requiredMessage' | translate"
              >
              </dxi-validation-rule>
            </dxi-item>
          </dx-form>

          <p *ngIf="successfulRequest" class="success-strip m-top-1">
              {{ "forgottenPassword.successMessage" | translate }}
          </p>
          <div class="button-row right m-top-1-5">
            <dx-button
              class="secondary btn-lg"
              [routerLink]="['/' + AppRouteNames.LOGIN]"
            >
              <i class="bi bi-arrow-left"></i><span>{{ "forgottenPassword.backButton" | translate }}</span>
            </dx-button>
            <dx-button
              [useSubmitBehavior]="true"
              [disabled]="pendingRequest"
              validationGroup="formValidations"
              class="primary bg-teal-3 btn-lg"
            >
              <i class="bi bi-box-arrow-in-right"></i><span>{{ "forgottenPassword.sendButton" | translate }}</span>
            </dx-button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
