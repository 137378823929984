<div class="generic-block h100p">
  <div class="generic-block-header-row">
    <h3 class="card-title teal-5">
      {{ "orders.title" | translate
      }}<span class="active-filter-title" *ngIf="isFiltered">{{
        "orders.summaryFilterIsActive" | translate
      }}</span>
    </h3>
    <div class="button-row gap-2">
      <dx-button (click)="resetFilters()" class="tertiary font-lg">
        <i class="bi bi-x-circle"></i>
        <span>{{ "common.table.resetFiltersButton" | translate }}</span>
      </dx-button>
      <dx-button
        (click)="excelExport()"
        *ngIf="isDesktop"
        class="tertiary font-lg"
      >
        <i class="bi bi-table"></i>
        <span>{{ "common.table.excelExportButton" | translate }}</span>
      </dx-button>
      <dx-button
        (click)="setFullWidth()"
        [disabled]="isFullWidth"
        *ngIf="isDesktop"
        class="tertiary font-lg tab-l-hidden"
      >
        <i class="bi bi-arrows-angle-expand"></i>
        <span>{{ "common.table.setFullWidthButton" | translate }}</span>
      </dx-button>
      <dx-button
        (click)="columnChooser.instance.showColumnChooser()"
        *ngIf="isDesktop"
        class="tertiary font-lg mob-icon-only"
      >
        <i class="bi bi-layout-sidebar-inset"></i>
        <span>{{ "common.table.columnChooserButton" | translate }}</span>
      </dx-button>
    </div>
  </div>
  <dx-data-grid
    #ordersListGrid
    id="ordersListGrid"
    [dataSource]="ordersList"
    (onFocusedRowChanged)="checkOrder($event)"
    [customizeColumns]="dataService.defaultColumns"
    [filterSyncEnabled]="true"
  >
    <dxo-column-chooser #columnChooser [enabled]="true" mode="select">
      <dxo-selection [allowSelectAll]="false" [selectByClick]="true" />
      <dxo-position />
    </dxo-column-chooser>
    <dxo-paging [pageSize]="dataService.PAGE_SIZE" />
    <dxo-state-storing
      [enabled]="true"
      type="custom"
      [customSave]="stateSave"
    />
    <dxi-column
      #orderStatus
      dataField="status"
      dataType="string"
      [caption]="'orders.column.status' | translate"
      [visible]="true"
      cellTemplate="statusCellTemplate"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="!isDesktop ? '80' : '150'"
    >
      <dxo-lookup
        [allowClearing]="true"
        [dataSource]="ordersService.ORDERSTATUS_DATA"
        displayExpr="text"
        valueExpr="id"
      ></dxo-lookup
    ></dxi-column>
    <dxi-column
      dataField="confirmationName"
      dataType="string"
      [caption]="'orders.column.confirmationName' | translate"
      [visible]="true"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="150"
    ></dxi-column>
    <dxi-column
      dataField="id"
      dataType="number"
      [caption]="'orders.column.id' | translate"
      [visible]="false"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="80"
    ></dxi-column>
    <dxi-column
      #simpleStatus
      dataField="archiveStatus"
      dataType="string"
      [caption]="'orders.column.archiveStatus' | translate"
      [visible]="isDesktop"
      cellTemplate="simpleStatusCellTemplate"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="100"
      ><dxo-lookup
        [allowClearing]="true"
        [dataSource]="ordersService.SIMPLE_ORDERSTATUS_DATA"
        displayExpr="text"
        valueExpr="id"
      ></dxo-lookup
    ></dxi-column>
    <dxi-column
      dataField="dateOfOrder"
      dataType="date"
      [caption]="'orders.column.dateOfOrder' | translate"
      [visible]="isDesktop"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="125"
    ></dxi-column>
    <dxi-column
      dataField="expectedDeliveryDate"
      dataType="date"
      [caption]="'orders.column.expectedDeliveryDate' | translate"
      [visible]="true"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="125"
    ></dxi-column>
    <dxi-column
      dataField="estimatedDeliveryDate"
      dataType="date"
      [caption]="'orders.column.estimatedDeliveryDate' | translate"
      [visible]="isDesktop"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="125"
    ></dxi-column>
    <dxi-column
      dataField="comment"
      dataType="string"
      [caption]="'orders.column.comment' | translate"
      [visible]="isDesktop"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="150"
    ></dxi-column>
    <dxi-column
      dataField="confirmationDate"
      dataType="date"
      [caption]="'orders.column.confirmationDate' | translate"
      [visible]="isDesktop"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="125"
    ></dxi-column>
    <dxi-column
      dataField="netPrice"
      dataType="number"
      [caption]="'orders.column.netPrice' | translate"
      [visible]="isDesktop"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="100"
    ></dxi-column>
    <dxi-column
      dataField="vatValue"
      dataType="number"
      [caption]="'orders.column.vatValue' | translate"
      [visible]="isDesktop"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="100"
    ></dxi-column>
    <dxi-column
      dataField="amount"
      dataType="number"
      [caption]="'orders.column.amount' | translate"
      [visible]="true"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="100"
    ></dxi-column>
    <dxi-column
      dataField="currency"
      dataType="string"
      [caption]="'orders.column.currency' | translate"
      [visible]="true"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="60"
      ><dxo-lookup
        [allowClearing]="true"
        [dataSource]="AppConstants.CURRENCY_DATA"
      ></dxo-lookup>
    </dxi-column>
    <dxi-column
      dataField="squareMeter"
      dataType="number"
      [caption]="'orders.column.squareMeter' | translate"
      [visible]="isDesktop"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="80"
    ></dxi-column>
    <dxi-column
      dataField="partnerComment"
      dataType="string"
      [caption]="'orders.column.partnerComment' | translate"
      [visible]="isDesktop"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="100"
    ></dxi-column>
    <dxi-column
      #invoices
      dataField="invoiceIdList"
      dataType="string"
      [caption]="'orders.column.invoiceIdList' | translate"
      [visible]="isDesktop"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="100"
    ></dxi-column>
    <dxi-column
      dataField="standGlassesIdList"
      dataType="string"
      [caption]="'orders.column.standGlassesIdList' | translate"
      [visible]="isDesktop"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="100"
    ></dxi-column>
    <dxi-column
      dataField="deliveryNoteList"
      dataType="string"
      [caption]="'orders.column.deliveryNoteList' | translate"
      [visible]="isDesktop"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="150"
    ></dxi-column>
    <div
      class="invoice-status-cell"
      *dxTemplate="let d of 'statusCellTemplate'"
    >
      <i class="bi bi-arrow-clockwise" *ngIf="d.value == 'IN_PROGRESS'"></i>
      <i class="bi bi-clock-history" *ngIf="d.value == 'CONFIRMED'"></i>
      <i class="bi bi-truck" *ngIf="d.value == 'UNDER_DELIVERY'"></i>
      <i class="bi bi-check-circle" *ngIf="d.value == 'COMPLETED'"></i>
      <i class="bi bi-x-circle" *ngIf="d.value == 'DELETED'"></i>
      <span *ngIf="isDesktop">{{ getStatusText(d.value) }}</span>
    </div>
    <div
      class="invoice-status-cell"
      *dxTemplate="let d of 'simpleStatusCellTemplate'"
    >
      <span>{{ getSimpleStatusText(d.value) }}</span>
    </div>
  </dx-data-grid>
</div>
