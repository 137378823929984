<div class="user-questionnare-container">
  <div class="user-questionnare generic-block m-bottom-2">
    <h2 class="card-title teal-5">{{ customForm.name }}</h2>
    <p class="desc">
      {{ customForm.description }}
    </p>
    <form>
      <dx-form
        #userQuestionnareForm
        [formData]="questionnaireFormData"
        [showValidationSummary]="true"
        labelLocation="top"
        [showColonAfterLabel]="false"
        [colCount]="1"
      >
        <dxi-item *ngFor="let item of customForm.form" itemType="group">
          <ng-container [ngSwitch]="item.type">
            <dxi-item
              itemType="group"
              *ngSwitchCase="'textblock'"
              name="textblock"
            >
              <p>{{ item.title }}</p>
            </dxi-item>
            <dxi-item
              [dataField]="item.title"
              *ngSwitchCase="'textinput'"
              [isRequired]="item.isRequired"
              name="textInput"
            >
              <dxo-label [text]="item.title"></dxo-label>
            </dxi-item>
            <dxi-item
              [dataField]="item.title"
              *ngSwitchCase="'checkboxes'"
              itemType="group"
              [isRequired]="item.isRequired"
              name="checkboxes"
            >
              <dx-list
                [dataSource]="item.values!"
                [selectionMode]="'multiple'"
                [showSelectionControls]="true"
                [selectByClick]="true"
                (onOptionChanged)="onCheckboxOptionChanged($event, item.title)"
              >
              </dx-list>
              <dxo-label [text]="item.title"></dxo-label>
            </dxi-item>
            <dxi-item
              [dataField]="item.title"
              *ngSwitchCase="'radio-buttons'"
              editorType="dxRadioGroup"
              [editorOptions]="{ items: item.values }"
              [isRequired]="item.isRequired"
              name="radio-buttons"
            >
              <dxo-label [text]="item.title"></dxo-label>
            </dxi-item>
            <dxi-item
              [dataField]="item.title"
              *ngSwitchCase="'scale'"
              editorType="dxRadioGroup"
              [editorOptions]="{ items: item.values }"
              [isRequired]="item.isRequired"
              name="scale"
            >
              <dxo-label [text]="item.title"></dxo-label>
            </dxi-item>
          </ng-container>
        </dxi-item>
        <dxi-item itemType="group">
          <dx-button
            class="primary btn-sm font-lg bg-teal-3"
            [useSubmitBehavior]="false"
            (onClick)="onSubmit()"
          >
          <i class="bi bi-envelope-arrow-up"></i>
          <span>Küldés</span></dx-button>
        </dxi-item>
      </dx-form>
    </form>
  </div>
</div>
