<ng-container *ngIf="quotes && quotes.length" >
  <ngx-glide
    #ngxGlide
    [perView]="1"
    [autoplay]="true"
    [startAt]="0"
    [focusAt]="0"
    [autoplay]="5000"
    [animationDuration]="400"
    [showArrows]="false"
    animationTimingFunc="ease-in-out"
  >
    <div *ngFor="let quote of quotes">
      <h3 class="title-lg teal-title">{{ (quote | langSelect : lang).title }}</h3>
      <p>
        {{ (quote | langSelect : lang).desc }}
       </p>
    </div>
  </ngx-glide>
</ng-container>
