import { Component, Input, ViewChild } from '@angular/core';
import { DxFormComponent } from 'devextreme-angular';
import { QuestionnaireResultDto } from '../../../../../../../shared/models/questionnaire-result-dto';
import { QuestionnaireService } from '../questionnaire.service';
import {
  CustomFormType,
} from '../../../../../../../shared/app-shared-utilities';
interface FormData {
  [key: string]: any;
}

@Component({
  selector: 'app-questionnaire-form',
  templateUrl: './questionnaire-form.component.html',
  styleUrl: './questionnaire-form.component.less',
})
export class QuestionnaireFormComponent {
  @ViewChild('userQuestionnareForm') userQuestionnareForm?: DxFormComponent;
  @Input() customForm: CustomFormType = <CustomFormType>{};
  questionnaireFormData: FormData = {};
  questionnaireResult: QuestionnaireResultDto = <QuestionnaireResultDto>{};

  constructor(
    private questionnaireService: QuestionnaireService,
  ) {}

  ngOnInit(): void {}

  onCheckboxOptionChanged(e: any, dataField: string) {
    if (e.name === 'selectedItemKeys') {
      this.questionnaireFormData[dataField] = e.value;
    }
  }

  onSubmit() {
    if (
      this.userQuestionnareForm?.instance.validate().isValid &&
      this.validateListElements()
    ) {
      if (confirm('Biztosan szeretné elküldeni a kérdőívet?')) {
        this.questionnaireResult = {
          questionnaireId: this.customForm.id!,
          result: JSON.stringify(this.questionnaireFormData),
        };
        this.questionnaireService
          .sendQuestionnaireResult(this.questionnaireResult)
          .subscribe({
            next: () => {
              alert('Sikeres kitöltés!');
              let userData = JSON.parse(localStorage.getItem('userData')!);
              userData.questionnaireFormStatus = 'FILLED';
              localStorage.setItem('userData', JSON.stringify(userData));
              location.reload();
            },
            error: (error) => {
              alert(error.error.message);
            },
          });
      }
      return;
    }
    alert('Minden csillagos mező kitöltése kötelező!');
  }

  validateListElements() {
    return this.userQuestionnareForm!.instance.option('items')?.every(
      (item: any) => {
        if (
          item.items &&
          item.items[0].name === 'checkboxes' &&
          item.items[0].isRequired
        ) {
          return this.questionnaireFormData[item.items[0].dataField].length > 0;
        }
        return true;
      }
    );
  }
}
