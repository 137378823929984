import { Component, EventEmitter, Output } from '@angular/core';
import { DataService } from '../../../../data.service';
import { StandsService } from '../stands.service';
import {StandSummaryDto} from "../../../../../../../shared/models/stand-summary-dto";

@Component({
  selector: 'app-stands-summary',
  templateUrl: './stands-summary.component.html',
  styleUrl: './stands-summary.component.less',
})
export class StandsSummaryComponent {
  isSummaryVisible?: boolean = true;
  summaryDto?: StandSummaryDto;
  isMobile: boolean = false;
  @Output() setListFullWidth: EventEmitter<any> = new EventEmitter();

  constructor(
    private dataService: DataService,
    private standsService: StandsService
  ) {}

  ngOnInit(): void {
    this.standsService.fetchStandSummary().subscribe({
      next: (summaryDto) => {
        this.summaryDto = summaryDto;
      },
    });

    this.standsService.isSummaryVisible.subscribe((isVisible) => {
      this.isSummaryVisible = isVisible;
    });
    this.isMobile = this.dataService.isMobile();
  }

  toggleSummary() {
    this.isSummaryVisible = !this.isSummaryVisible;
    if (this.isSummaryVisible) this.setListFullWidth.emit();
  }
}
