import { Pipe, PipeTransform } from '@angular/core';
import {AppConstants} from "../../../../shared/app-constants";
import {formatDate} from "@angular/common";

@Pipe({
  name: 'ceDateFormat'
})
export class CeDateFormatPipe implements PipeTransform {

  transform(value: string|undefined, ...args: unknown[]): string {
    if (value == undefined) return "";
    return formatDate(value, AppConstants.DEFAULT_DATE_FORMAT, AppConstants.DEFAULT_LOCALE);
  }

}
