import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrderDetailsDto } from '../../../../../../shared/models/order-details-dto';
import { environment } from '../../../../../../shared/environments/environment';
import { OrderSummaryDto } from '../../../../../../shared/models/order-summary-dto';
import { OrderRequestDto } from '../../../../../../shared/models/order-request-dto';
import { BehaviorSubject } from 'rxjs';
import {TranslateService} from "@ngx-translate/core";

@Injectable({ providedIn: 'root' })
export class OrdersService {
  ORDERSTATUS_DATA:{id:string,text:string}[]=[];
  SIMPLE_ORDERSTATUS_DATA:{id:string,text:string}[]=[];

  isSummaryVisible = new BehaviorSubject<boolean>(true);
  filteredOrders = new BehaviorSubject<{ status: string }>({ status: '' });

  constructor(
      private http: HttpClient,
      private translate: TranslateService,
  ) {
    this.loadTranslates();
  }

  async loadTranslates(){
    await this.translate.get("header.title").toPromise();
    this.ORDERSTATUS_DATA = [
      { id: 'IN_PROGRESS', text: this.translate.instant("orders.status.IN_PROGRESS") },
      { id: 'CONFIRMED', text: this.translate.instant("orders.status.CONFIRMED") },
      { id: 'UNDER_DELIVERY', text: this.translate.instant("orders.status.UNDER_DELIVERY") },
      { id: 'COMPLETED', text: this.translate.instant("orders.status.COMPLETED") },
      { id: 'DELETED', text: this.translate.instant("orders.status.DELETED") },
    ];
    this.SIMPLE_ORDERSTATUS_DATA = [
      { id: 'ARCHIVE', text: this.translate.instant("orders.archive.ARCHIVE") },
      { id: 'ACTUAL', text: this.translate.instant("orders.archive.ACTUAL") }
    ]
  }

  fetchOrderDetails(id: string) {
    return this.http.get<OrderDetailsDto>(
      `${environment.apiUrl}api/order/details/${id}`
    );
  }

  downloadGlassPdf(id: string) {
    return this.http.get(
        `${environment.apiUrl}api/order/generate-pdf/${id}`,
        { responseType: 'blob' }
    );
  }

  downloadGlassCsv(id: string) {
    return this.http.get(
        `${environment.apiUrl}api/order/generate-csv/${id}`,
        { responseType: 'blob' }
    );
  }

  fetchOrderSummary() {
    return this.http.get<OrderSummaryDto>(
      `${environment.apiUrl}api/order/summary`
    );
  }

  downloadOrder(id: number) {
    return this.http.get(`${environment.apiUrl}api/order/download/${id}`, {
      responseType: 'blob',
    });
  }

  sendPartnerComment(id: number, addedComment: string) {
    return this.http.post(`${environment.apiUrl}api/order/comment/${id}`, {
      comment: addedComment,
    });
  }

  downloadAllInvoices(id: number) {
    return this.http.get(
      `${environment.apiUrl}api/order/all-invoice/download/${id}`,
      { responseType: 'blob' }
    );
  }

  downloadStatements(id: number) {
    return this.http.get(
      `${environment.apiUrl}api/order/all-performance-statement/download/${id}`,
      { responseType: 'blob' }
    );
  }

  sendOrderRequest(dto: OrderRequestDto, attachments?: File[]) {
    let fileData = new FormData();
    if (attachments && attachments.length > 0) {
      attachments?.forEach((attachment) => {
        fileData.append('files', attachment, attachment.name);
      });
    }
    let url = `${environment.apiUrl}api/order/order-request?orderRequestType=${dto.orderRequestType}&subject=${dto.subject}&message=${dto.message}&shippingAddress=${dto.shippingAddress}'`;
    return this.http.post<OrderRequestDto>(url, fileData);
  }
}
