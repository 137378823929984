<div class="breadcrumb-group">
  <div class="breadcrumb">
    <div
      class="breadcrumb-item"
      *ngFor="let breadcrumb of breadcrumbs; let i = index; let last = last"
    >
      <a
        *ngIf="isDashboard && i === 0"
        [routerLink]="AppRouteNames.PARTNER_DASHBOARD"
      >
        <i class="bi bi-house-fill"></i>
      </a>
      <a [ngClass]="{ current: last }" [routerLink]="breadcrumb.url">{{
        breadcrumb.label | translate
      }}</a>
    </div>
  </div>
</div>
