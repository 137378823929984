{
    "sr": {
        "Yes": "Da",
        "No": "Ne",
        "Cancel": "Ipak",
        "Close": "Zatvaranje",
        "Clear": "Izbriši",
        "Done": "Gotovo",
        "Loading": "Učitavanje...",
        "Select": "Izbor…",
        "Search": "Pretraga",
        "Back": "Nazad",
        "OK": "OK",

        "dxCollectionWidget-noDataText": "Nema podataka za prikaz",

        "dxDropDownEditor-selectLabel": "Izaberite",

        "validation-required": "Obavezno",
        "validation-required-formatted": "{0} obavezno",
        "validation-numeric": "Vrednost mora biti broj",
        "validation-numeric-formatted": "{0} mora biti broj",
        "validation-range": "Vrednost je izvan raspona",
        "validation-range-formatted": "{0} je izvan raspona",
        "validation-stringLength": "Dužina vrednosti je neispravna",
        "validation-stringLength-formatted": "{0} Dužina je netočna",
        "validation-custom": "Neispravna vrednost",
        "validation-custom-formatted": "{0} nevažeći",
        "validation-async": "Neispravna vrednost",
        "validation-async-formatted": "{0} nevažeći",
        "validation-compare": "Vrednosti se ne poklapaju",
        "validation-compare-formatted": "{0} ne odgovara",
        "validation-pattern": "Vrednost se ne može uklopiti u uzorak",
        "validation-pattern-formatted": "{0} se ne može uklopiti u uzorak",
        "validation-email": "Neispravan  e-mail",
        "validation-email-formatted": "{0} Neispravan  e-mail",
        "validation-mask": "Neispravna vrednost",

        "dxLookup-searchPlaceholder": "Minimalni broj karaktera: {0}",

        "dxList-pullingDownText": "Povucite za ažuriranje...",
        "dxList-pulledDownText": "Otpustite ga za ažuriranje...",
        "dxList-refreshingText": "Ažuriranje...",
        "dxList-pageLoadingText": "Učitavanje...",
        "dxList-nextButtonText": "Sledeće",
        "dxList-selectAll": "Izaberite sve",
        "dxListEditDecorator-delete": "Izbriši",
        "dxListEditDecorator-more": "Više",

        "dxScrollView-pullingDownText": "Povucite za ažuriranje...",
        "dxScrollView-pulledDownText": "Otpustite ga za ažuriranje...",
        "dxScrollView-refreshingText": "Ažuriranje...",
        "dxScrollView-reachBottomText": "Učitavanje...",

        "dxDateBox-simulatedDataPickerTitleTime": "Izaberite vreme",
        "dxDateBox-simulatedDataPickerTitleDate": "Izaberite datum",
        "dxDateBox-simulatedDataPickerTitleDateTime": "Izaberite datum i vreme",
        "dxDateBox-validation-datetime": "Vrednost mora biti datum ili vreme",

        "dxDateRangeBox-invalidStartDateMessage": "Start value must be a date",
        "dxDateRangeBox-invalidEndDateMessage": "End value must be a date",
        "dxDateRangeBox-startDateOutOfRangeMessage": "Start date is out of range",
        "dxDateRangeBox-endDateOutOfRangeMessage": "End date is out of range",
        "dxDateRangeBox-startDateLabel": "Datum početka",
        "dxDateRangeBox-endDateLabel": "Datum završetka",

        "dxFileUploader-selectFile": "Izaberite datoteku",
        "dxFileUploader-dropFile": "ili prevucite datoteku ovde",
        "dxFileUploader-bytes": "bajt",
        "dxFileUploader-kb": "KB",
        "dxFileUploader-Mb": "MB",
        "dxFileUploader-Gb": "GB",
        "dxFileUploader-upload": "Učitati podatke",
        "dxFileUploader-uploaded": "Učitano",
        "dxFileUploader-readyToUpload": "Učitavanje gotovo",
        "dxFileUploader-uploadAbortedMessage": "Učitatavanje prekinuto",
        "dxFileUploader-uploadFailedMessage": "Učitatavanje neuspešno",
        "dxFileUploader-invalidFileExtension": "Neodgovarajući tip datoteke",
        "dxFileUploader-invalidMaxFileSize": "Prevelika datoteka",
        "dxFileUploader-invalidMinFileSize": "Premala datoteka",

        "dxRangeSlider-ariaFrom": "#IME?",
        "dxRangeSlider-ariaTill": "#IME?",
        "dxSwitch-switchedOnText": "Uključeno",
        "dxSwitch-switchedOffText": "Isključeno",

        "dxForm-optionalMark": "Opcionalno",
        "dxForm-requiredMessage": "{0} obavezno",

        "dxNumberBox-invalidValueMessage": "Vrednost mora biti broj",
        "dxNumberBox-noDataText": "Nea podataka",

        "dxDataGrid-emptyHeaderWithColumnChooserText": "Use {0} to display columns",
        "dxDataGrid-emptyHeaderWithGroupPanelText": "Drag a column from the group panel here",
        "dxDataGrid-emptyHeaderWithColumnChooserAndGroupPanelText": "Use {0} or drag a column from the group panel",
        "dxDataGrid-emptyHeaderColumnChooserText": "birač kolona",
        "dxDataGrid-columnChooserTitle": "Izbor kolone",
        "dxDataGrid-columnChooserEmptyText": "Povucite kolonu ovde da je sakrijete",
        "dxDataGrid-groupContinuesMessage": "Nastavlja se na sledećoj stranici",
        "dxDataGrid-groupContinuedMessage": "Nastavak sa prethodne stranice",
        "dxDataGrid-groupHeaderText": "Grupisanje po ovoj koloni",
        "dxDataGrid-ungroupHeaderText": "Raskid grupe",
        "dxDataGrid-ungroupAllText": "Raskid svih grupa",
        "dxDataGrid-editingEditRow": "Uređivanje",
        "dxDataGrid-editingSaveRowChanges": "Sačuvaj",
        "dxDataGrid-editingCancelRowChanges": "Ipak",
        "dxDataGrid-editingDeleteRow": "Izbriši",
        "dxDataGrid-editingUndeleteRow": "Resetuj",
        "dxDataGrid-editingConfirmDeleteMessage": "Da li ste sigurni da želite da obrišete ovu evidenciju?",
        "dxDataGrid-validationCancelChanges": "Odbaci promene",
        "dxDataGrid-groupPanelEmptyText": "Povucite ovde zaglavlje kolone za grupisanje",
        "dxDataGrid-noDataText": "Nea podataka",
        "dxDataGrid-searchPanelPlaceholder": "Pretraga...",
        "dxDataGrid-filterRowShowAllText": "(Sve)",
        "dxDataGrid-filterRowResetOperationText": "Resetuj",
        "dxDataGrid-filterRowOperationEquals": "Jednako",
        "dxDataGrid-filterRowOperationNotEquals": "Nije jednako",
        "dxDataGrid-filterRowOperationLess": "Manje",
        "dxDataGrid-filterRowOperationLessOrEquals": "Manje ili jednako",
        "dxDataGrid-filterRowOperationGreater": "Veće",
        "dxDataGrid-filterRowOperationGreaterOrEquals": "Veće ili jednako",
        "dxDataGrid-filterRowOperationStartsWith": "Počinje",
        "dxDataGrid-filterRowOperationContains": "Sadrži",
        "dxDataGrid-filterRowOperationNotContains": "Ne sadrži",
        "dxDataGrid-filterRowOperationEndsWith": "Završava se",
        "dxDataGrid-filterRowOperationBetween": "Između",
        "dxDataGrid-filterRowOperationBetweenStartText": "Početak",
        "dxDataGrid-filterRowOperationBetweenEndText": "Kraj",
        "dxDataGrid-ariaSearchBox": "Polje za pretragu",
        "dxDataGrid-applyFilterText": "Primena filtera",
        "dxDataGrid-trueText": "tačno",
        "dxDataGrid-falseText": "netačno",
        "dxDataGrid-sortingAscendingText": "Rastuće",
        "dxDataGrid-sortingDescendingText": "Smanjujuće",
        "dxDataGrid-sortingClearText": "izbrisi izgled",
        "dxDataGrid-ariaNotSortedColumn": "Nije sortirana kolona",
        "dxDataGrid-ariaSortedAscendingColumn": "Kolona je sortirana u rastućem redosledu",
        "dxDataGrid-ariaSortedDescendingColumn": "Kolona sortirana u opadajućem redosledu",
        "dxDataGrid-ariaSortIndex": "Indeks sortiranja {0}",
        "dxDataGrid-editingSaveAllChanges": "Sačuvaj promene",
        "dxDataGrid-editingCancelAllChanges": "Odbaci promene",
        "dxDataGrid-editingAddRow": "Dodaj red",
        "dxDataGrid-summaryMin": "Minimum: {0}",
        "dxDataGrid-summaryMinOtherColumn": "{1} minimuma: {0}",
        "dxDataGrid-summaryMax": "Maximum: {0}",
        "dxDataGrid-summaryMaxOtherColumn": "{1} maximuma: {0}",
        "dxDataGrid-summaryAvg": "Prosek: {0}",
        "dxDataGrid-summaryAvgOtherColumn": "{1} prosek: {0}",
        "dxDataGrid-summarySum": "Iznos: {0}",
        "dxDataGrid-summarySumOtherColumn": "{1}iznos: {0}",
        "dxDataGrid-summaryCount": "Količina: {0}",
        "dxDataGrid-columnFixingFix": "Unos podataka",
        "dxDataGrid-columnFixingUnfix": "Otključavanje",
        "dxDataGrid-columnFixingLeftPosition": "Levo",
        "dxDataGrid-columnFixingRightPosition": "Desno",
        "dxDataGrid-exportTo": "Konvertuj u Excel datoteku",
        "dxDataGrid-exportToExcel": "Konvertuj u Excel datoteku",
        "dxDataGrid-exporting": "Konvertovanje…",
        "dxDataGrid-excelFormat": "Excel fajl",
        "dxDataGrid-selectedRows": "Izabrani redovi",
        "dxDataGrid-exportSelectedRows": "Izvez izabranih redova",
        "dxDataGrid-exportAll": "Izvezite sve podatke",
        "dxDataGrid-headerFilterLabel": "Opcije filtera",
        "dxDataGrid-headerFilterIndicatorLabel": "Prikaži opcije filtera za kolonu '{0}'",
        "dxDataGrid-headerFilterEmptyValue": "(Prazno)",
        "dxDataGrid-headerFilterOK": "OK",
        "dxDataGrid-headerFilterCancel": "Ipak",
        "dxDataGrid-ariaAdaptiveCollapse": "Sakrij dodatne podatke",
        "dxDataGrid-ariaAdaptiveExpand": "Prikaži dodatne podatke",
        "dxDataGrid-ariaColumn": "Kolona",
        "dxDataGrid-ariaColumnHeader": "Zaglavlje kolone",
        "dxDataGrid-ariaValue": "Iznos",
        "dxDataGrid-ariaError": "Greška",
        "dxDataGrid-ariaRevertButton": "Press Escape to discard the changes",
        "dxDataGrid-ariaFilterCell": "Filter ćelija",
        "dxDataGrid-ariaCollapse": "Zatvaranje",
        "dxDataGrid-ariaModifiedCell": "Modified",
        "dxDataGrid-ariaDeletedCell": "Deleted",
        "dxDataGrid-ariaEditableCell": "Editable",
        "dxDataGrid-ariaExpand": "Rasklapanje",
        "dxDataGrid-ariaCollapsedRow": "Collapsed row",
        "dxDataGrid-ariaExpandedRow": "Expanded row",
        "dxDataGrid-ariaDataGrid": "Mreža podataka",
        "dxDataGrid-ariaSearchInGrid": "Pretražite mrežu podataka",
        "dxDataGrid-ariaSelectAll": "Izaberite sve",
        "dxDataGrid-ariaSelectRow": "Izaberite red",
        "dxDataGrid-ariaToolbar": "Data grid toolbar",
        "dxDataGrid-ariaEditForm": "Edit form",
        "dxDataGrid-filterBuilderPopupTitle": "Napravi filter",
        "dxDataGrid-filterPanelCreateFilter": "Napravi filter",
        "dxDataGrid-filterPanelClearFilter": "Izbriši",
        "dxDataGrid-filterPanelFilterEnabledHint": "Omogući filter",

        "dxTreeList-ariaTreeList": "Tree list with {0} rows and {1} columns",
        "dxTreeList-ariaSearchInGrid": "Search in the tree list",
        "dxTreeList-ariaToolbar": "Tree list toolbar",
        "dxTreeList-editingAddRowToNode": "Hozzáadás",

        "dxPager-infoText": "Stranica: {0}/{1} ({2} stavki)",
        "dxPager-pagesCountText": "/",
        "dxPager-pageSize": "Items per page: {0}",
        "dxPager-pageSizesAllText": "Sve",
        "dxPager-page": "Page {0}",
        "dxPager-prevPage": "Previous Page",
        "dxPager-nextPage": "Next Page",
        "dxPager-ariaLabel": "Navigacija po stranici",
        "dxPager-ariaPageSize": "Page size",
        "dxPager-ariaPageNumber": "Page number",

        "dxPivotGrid-grandTotal": "Teljes összeg",
        "dxPivotGrid-total": "{0} Összeg",
        "dxPivotGrid-fieldChooserTitle": "Mezőválasztó",
        "dxPivotGrid-showFieldChooser": "Mezőválasztó megjelenítése",
        "dxPivotGrid-expandAll": "Összes kibontása",
        "dxPivotGrid-collapseAll": "Összes összecsukása",
        "dxPivotGrid-sortColumnBySummary": "Rendezés \"{0}\" ezen oszlop alapján",
        "dxPivotGrid-sortRowBySummary": "Rendezés \"{0}\" ezen sor alapján",
        "dxPivotGrid-removeAllSorting": "Összes rendezés törlése",
        "dxPivotGrid-dataNotAvailable": "N/A",
        "dxPivotGrid-rowFields": "Sormezők",
        "dxPivotGrid-columnFields": "Kolonamezők",
        "dxPivotGrid-dataFields": "Adatmezők",
        "dxPivotGrid-filterFields": "Szűrőmezők",
        "dxPivotGrid-allFields": "Összes mező",
        "dxPivotGrid-columnFieldArea": "Húzza az oszlopmezőket ide",
        "dxPivotGrid-dataFieldArea": "Húzza az adatmezőket ide",
        "dxPivotGrid-rowFieldArea": "Húzza a sormezőket ide",
        "dxPivotGrid-filterFieldArea": "Húzza a szűrőmezőket ide",

        "dxScheduler-editorLabelTitle": "Tárgy",
        "dxScheduler-editorLabelStartDate": "Datum početka",
        "dxScheduler-editorLabelEndDate": "Datum završetka",
        "dxScheduler-editorLabelDescription": "Leírás",
        "dxScheduler-editorLabelRecurrence": "Ismétlődés",

        "dxScheduler-openAppointment": "Találkozó megnyitása",

        "dxScheduler-recurrenceNever": "Soha",
        "dxScheduler-recurrenceMinutely": "Percenként",
        "dxScheduler-recurrenceHourly": "Óránként",
        "dxScheduler-recurrenceDaily": "Naponta",
        "dxScheduler-recurrenceWeekly": "Hetente",
        "dxScheduler-recurrenceMonthly": "Havonta",
        "dxScheduler-recurrenceYearly": "Évente",

        "dxScheduler-recurrenceRepeatEvery": "Ismétlődés minden",
        "dxScheduler-recurrenceRepeatOn": "Ismétlődés",
        "dxScheduler-recurrenceEnd": "Ismétlődés vége",
        "dxScheduler-recurrenceAfter": "Után",
        "dxScheduler-recurrenceOn": "Ekkor",

        "dxScheduler-recurrenceRepeatMinutely": "perc(ek)",
        "dxScheduler-recurrenceRepeatHourly": "óra(k)",
        "dxScheduler-recurrenceRepeatDaily": "nap(ok)",
        "dxScheduler-recurrenceRepeatWeekly": "hét(ek)",
        "dxScheduler-recurrenceRepeatMonthly": "hónap(ok)",
        "dxScheduler-recurrenceRepeatYearly": "év(ek)",

        "dxScheduler-switcherDay": "Nap",
        "dxScheduler-switcherWeek": "Hét",
        "dxScheduler-switcherWorkWeek": "Munkahét",
        "dxScheduler-switcherMonth": "Hónap",

        "dxScheduler-switcherAgenda": "Naptár",

        "dxScheduler-switcherTimelineDay": "Idővonal nap",
        "dxScheduler-switcherTimelineWeek": "Idővonal hét",
        "dxScheduler-switcherTimelineWorkWeek": "Idővonal munkahét",
        "dxScheduler-switcherTimelineMonth": "Idővonal hónap",

        "dxScheduler-recurrenceRepeatOnDate": "dátumon",
        "dxScheduler-recurrenceRepeatCount": "előfordulás(ok)",
        "dxScheduler-allDay": "Egész nap",

        "dxScheduler-confirmRecurrenceEditTitle": "Edit Recurring Appointment",
        "dxScheduler-confirmRecurrenceDeleteTitle": "Delete Recurring Appointment",

        "dxScheduler-confirmRecurrenceEditMessage": "Csak ezt az alkalmat szeretné szerkeszteni, vagy az összes ismétlődést?",
        "dxScheduler-confirmRecurrenceDeleteMessage": "Csak ezt az alkalmat szeretné törölni, vagy az összes ismétlődést?",

        "dxScheduler-confirmRecurrenceEditSeries": "Ismétlődés szerkesztése",
        "dxScheduler-confirmRecurrenceDeleteSeries": "Ismétlődés törlése",
        "dxScheduler-confirmRecurrenceEditOccurrence": "Alkalom szerkesztése",
        "dxScheduler-confirmRecurrenceDeleteOccurrence": "Alkalom törlése",

        "dxScheduler-noTimezoneTitle": "Nincs időzóna",
        "dxScheduler-moreAppointments": "{0} több",

        "dxCalendar-todayButtonText": "Ma",
        "dxCalendar-ariaWidgetName": "Naptár",
        "dxCalendar-previousMonthButtonLabel": "Previous month",
        "dxCalendar-previousYearButtonLabel": "Previous year",
        "dxCalendar-previousDecadeButtonLabel": "Previous decade",
        "dxCalendar-previousCenturyButtonLabel": "Previous century",
        "dxCalendar-nextMonthButtonLabel": "Next month",
        "dxCalendar-nextYearButtonLabel": "Next year",
        "dxCalendar-nextDecadeButtonLabel": "Next decade",
        "dxCalendar-nextCenturyButtonLabel": "Next century",
        "dxCalendar-captionMonthLabel": "Month selection",
        "dxCalendar-captionYearLabel": "Year selection",
        "dxCalendar-captionDecadeLabel": "Decade selection",
        "dxCalendar-captionCenturyLabel": "Century selection",

        "dxColorView-ariaRed": "Piros",
        "dxColorView-ariaGreen": "Zöld",
        "dxColorView-ariaBlue": "Kék",
        "dxColorView-ariaAlpha": "Átlátszóság",
        "dxColorView-ariaHex": "Színkód",

        "dxTagBox-selected": "{0} kiválasztva",
        "dxTagBox-allSelected": "Összes kiválasztva ({0})",
        "dxTagBox-moreSelected": "{0} több",

        "vizExport-printingButtonText": "Nyomtatás",
        "vizExport-titleMenuText": "Konvertuj u Excel datoteku/Nyomtatás",
        "vizExport-exportButtonText": "{0} fájl",

        "dxFilterBuilder-and": "És",
        "dxFilterBuilder-or": "Vagy",
        "dxFilterBuilder-notAnd": "Ne És",
        "dxFilterBuilder-notOr": "Ne Vagy",
        "dxFilterBuilder-addCondition": "Feltétel hozzáadása",
        "dxFilterBuilder-addGroup": "Csoport hozzáadása",
        "dxFilterBuilder-enterValueText": "<írjon be egy értéket>",
        "dxFilterBuilder-filterOperationEquals": "Jednako",
        "dxFilterBuilder-filterOperationNotEquals": "Nije jednako",
        "dxFilterBuilder-filterOperationLess": "Manje",
        "dxFilterBuilder-filterOperationLessOrEquals": "Manje ili jednako",
        "dxFilterBuilder-filterOperationGreater": "Veće",
        "dxFilterBuilder-filterOperationGreaterOrEquals": "Veće ili jednako",
        "dxFilterBuilder-filterOperationStartsWith": "Počinje",
        "dxFilterBuilder-filterOperationContains": "Sadrži",
        "dxFilterBuilder-filterOperationNotContains": "Ne sadrži",
        "dxFilterBuilder-filterOperationEndsWith": "Završava se",
        "dxFilterBuilder-filterOperationIsBlank": "Üres",
        "dxFilterBuilder-filterOperationIsNotBlank": "Ne üres",
        "dxFilterBuilder-filterOperationBetween": "Između",
        "dxFilterBuilder-filterOperationAnyOf": "Valamelyik",
        "dxFilterBuilder-filterOperationNoneOf": "Egyik sem",

        "dxHtmlEditor-dialogColorCaption": "Betűszín választás",
        "dxHtmlEditor-dialogBackgroundCaption": "Háttérszín választás",
        "dxHtmlEditor-dialogLinkCaption": "Link hozzáadása",
        "dxHtmlEditor-dialogLinkUrlField": "URL",
        "dxHtmlEditor-dialogLinkTextField": "Szöveg",
        "dxHtmlEditor-dialogLinkTargetField": "Link megnyitása új ablakban",
        "dxHtmlEditor-dialogImageCaption": "Kép hozzáadása",
        "dxHtmlEditor-dialogImageUrlField": "URL",
        "dxHtmlEditor-dialogImageAltField": "Alternatív szöveg",
        "dxHtmlEditor-dialogImageWidthField": "Szélesség (px)",
        "dxHtmlEditor-dialogImageHeightField": "Magasság (px)",
        "dxHtmlEditor-dialogInsertTableRowsField": "Sorok",
        "dxHtmlEditor-dialogInsertTableColumnsField": "Kolonaok",
        "dxHtmlEditor-dialogInsertTableCaption": "Tábla beszúrása",
        "dxHtmlEditor-dialogUpdateImageCaption": "Update Image",
        "dxHtmlEditor-dialogImageUpdateButton": "Update",
        "dxHtmlEditor-dialogImageAddButton": "Add",
        "dxHtmlEditor-dialogImageSpecifyUrl": "From the Web",
        "dxHtmlEditor-dialogImageSelectFile": "From This Device",
        "dxHtmlEditor-dialogImageKeepAspectRatio": "Keep Aspect Ratio",
        "dxHtmlEditor-dialogImageEncodeToBase64": "Encode to Base64",
        "dxHtmlEditor-heading": "Fejléc",
        "dxHtmlEditor-normalText": "Normál szöveg",
        "dxHtmlEditor-background": "Background Color",
        "dxHtmlEditor-bold": "Bold",
        "dxHtmlEditor-color": "Font Color",
        "dxHtmlEditor-font": "Font",
        "dxHtmlEditor-italic": "Italic",
        "dxHtmlEditor-link": "Add Link",
        "dxHtmlEditor-image": "Add Image",
        "dxHtmlEditor-size": "Size",
        "dxHtmlEditor-strike": "Strikethrough",
        "dxHtmlEditor-subscript": "Subscript",
        "dxHtmlEditor-superscript": "Superscript",
        "dxHtmlEditor-underline": "Underline",
        "dxHtmlEditor-blockquote": "Blockquote",
        "dxHtmlEditor-header": "Header",
        "dxHtmlEditor-increaseIndent": "Increase Indent",
        "dxHtmlEditor-decreaseIndent": "Decrease Indent",
        "dxHtmlEditor-orderedList": "Ordered List",
        "dxHtmlEditor-bulletList": "Bullet List",
        "dxHtmlEditor-alignLeft": "Align Left",
        "dxHtmlEditor-alignCenter": "Align Center",
        "dxHtmlEditor-alignRight": "Align Right",
        "dxHtmlEditor-alignJustify": "Align Justify",
        "dxHtmlEditor-codeBlock": "Code Block",
        "dxHtmlEditor-variable": "Add Variable",
        "dxHtmlEditor-undo": "Undo",
        "dxHtmlEditor-redo": "Redo",
        "dxHtmlEditor-clear": "Clear Formatting",
        "dxHtmlEditor-insertTable": "Insert Table",
        "dxHtmlEditor-insertHeaderRow": "Insert Header Row",
        "dxHtmlEditor-insertRowAbove": "Insert Row Above",
        "dxHtmlEditor-insertRowBelow": "Insert Row Below",
        "dxHtmlEditor-insertColumnLeft": "Insert Column Left",
        "dxHtmlEditor-insertColumnRight": "Insert Column Right",
        "dxHtmlEditor-deleteColumn": "Delete Column",
        "dxHtmlEditor-deleteRow": "Delete Row",
        "dxHtmlEditor-deleteTable": "Delete Table",
        "dxHtmlEditor-cellProperties": "Cell Properties",
        "dxHtmlEditor-tableProperties": "Table Properties",
        "dxHtmlEditor-insert": "Insert",
        "dxHtmlEditor-delete": "Delete",
        "dxHtmlEditor-border": "Border",
        "dxHtmlEditor-style": "Style",
        "dxHtmlEditor-width": "Width",
        "dxHtmlEditor-height": "Height",
        "dxHtmlEditor-borderColor": "Color",
        "dxHtmlEditor-tableBackground": "Background",
        "dxHtmlEditor-dimensions": "Dimensions",
        "dxHtmlEditor-alignment": "Alignment",
        "dxHtmlEditor-horizontal": "Horizontal",
        "dxHtmlEditor-vertical": "Vertical",
        "dxHtmlEditor-paddingVertical": "Vertical Padding",
        "dxHtmlEditor-paddingHorizontal": "Horizontal Padding",
        "dxHtmlEditor-pixels": "Pixels",
        "dxHtmlEditor-list": "List",
        "dxHtmlEditor-ordered": "Ordered",
        "dxHtmlEditor-bullet": "Bullet",
        "dxHtmlEditor-align": "Align",
        "dxHtmlEditor-center": "Center",
        "dxHtmlEditor-left": "Left",
        "dxHtmlEditor-right": "Right",
        "dxHtmlEditor-indent": "Indent",
        "dxHtmlEditor-justify": "Justify",
        "dxHtmlEditor-borderStyleNone": "none",
        "dxHtmlEditor-borderStyleHidden": "hidden",
        "dxHtmlEditor-borderStyleDotted": "dotted",
        "dxHtmlEditor-borderStyleDashed": "dashed",
        "dxHtmlEditor-borderStyleSolid": "solid",
        "dxHtmlEditor-borderStyleDouble": "double",
        "dxHtmlEditor-borderStyleGroove": "groove",
        "dxHtmlEditor-borderStyleRidge": "ridge",
        "dxHtmlEditor-borderStyleInset": "inset",
        "dxHtmlEditor-borderStyleOutset": "outset",

        "dxFileManager-newDirectoryName": "Névtelen könyvtár",
        "dxFileManager-rootDirectoryName": "Fájlok",
        "dxFileManager-errorNoAccess": "Hozzáférés megtagadva. A műveletet nem lehet befejezni.",
        "dxFileManager-errorDirectoryExistsFormat": "'{0}' nevű könyvtár már létezik.",
        "dxFileManager-errorFileExistsFormat": "'{0}' nevű fájl már létezik.",
        "dxFileManager-errorFileNotFoundFormat": "'{0}' nevű fájl nem található",
        "dxFileManager-errorDirectoryNotFoundFormat": "'{0}' nevű könyvtár nem található",
        "dxFileManager-errorWrongFileExtension": "Neodgovarajući tip datoteke",
        "dxFileManager-errorMaxFileSizeExceeded": "A fájlméret meghaladja a megengedett legnagyobb méretet",
        "dxFileManager-errorInvalidSymbols": "A név érvénytelen karaktereket tartalmaz.",
        "dxFileManager-errorDefault": "Ismeretlen hiba.",
        "dxFileManager-errorDirectoryOpenFailed": "A könyvtár nem nyitható meg",

        "dxFileManager-commandCreate": "Új könyvtár",
        "dxFileManager-commandRename": "Átnevezés",
        "dxFileManager-commandMove": "Áthelyezés",
        "dxFileManager-commandCopy": "Másolás",
        "dxFileManager-commandDelete": "Izbriši",
        "dxFileManager-commandDownload": "Letöltés",
        "dxFileManager-commandUpload": "Fájlok feltöltése",
        "dxFileManager-commandRefresh": "Frissítés",
        "dxFileManager-commandThumbnails": "Miniatűr nézet",
        "dxFileManager-commandDetails": "Részletek nézet",
        "dxFileManager-commandClearSelection": "Kijelölés törlése",
        "dxFileManager-commandShowNavPane": "Navigációs ablaktábla megjelenítése/elrejtése",

        "dxFileManager-dialogDirectoryChooserMoveTitle": "Áthelyezés",
        "dxFileManager-dialogDirectoryChooserMoveButtonText": "Áthelyezés",
        "dxFileManager-dialogDirectoryChooserCopyTitle": "Másolás",
        "dxFileManager-dialogDirectoryChooserCopyButtonText": "Másolás",
        "dxFileManager-dialogRenameItemTitle": "Átnevezés",
        "dxFileManager-dialogRenameItemButtonText": "Sačuvaj",
        "dxFileManager-dialogCreateDirectoryTitle": "Új könyvtár",
        "dxFileManager-dialogCreateDirectoryButtonText": "Létrehozás",
        "dxFileManager-dialogDeleteItemTitle": "Izbriši",
        "dxFileManager-dialogDeleteItemButtonText": "Izbriši",
        "dxFileManager-dialogDeleteItemSingleItemConfirmation": "Biztosan törli a következőt: {0}?",
        "dxFileManager-dialogDeleteItemMultipleItemsConfirmation": "Biztosan törölni szeretne {0} elemet?",
        "dxFileManager-dialogButtonCancel": "Ipak",

        "dxFileManager-editingCreateSingleItemProcessingMessage": "Könyvtár létrehozása a következőben: {0}",
        "dxFileManager-editingCreateSingleItemSuccessMessage": "Könyvtár létrehozva a következőben: {0}",
        "dxFileManager-editingCreateSingleItemErrorMessage": "A könyvtár nem lett létrehozva",
        "dxFileManager-editingCreateCommonErrorMessage": "A könyvtár nem lett létrehozva",

        "dxFileManager-editingRenameSingleItemProcessingMessage": "Elem átnevezése a következőben: {0}",
        "dxFileManager-editingRenameSingleItemSuccessMessage": "Elem átnevezve a következőben: {0}",
        "dxFileManager-editingRenameSingleItemErrorMessage": "Az elem nem lett átnevezve",
        "dxFileManager-editingRenameCommonErrorMessage": "Az elem nem lett átnevezve",

        "dxFileManager-editingDeleteSingleItemProcessingMessage": "Elem törlése innen: {0}",
        "dxFileManager-editingDeleteMultipleItemsProcessingMessage": "{0} elem törlése innen: {1}",
        "dxFileManager-editingDeleteSingleItemSuccessMessage": "Elem törölve innen: {0}",
        "dxFileManager-editingDeleteMultipleItemsSuccessMessage": "{0} elem törölve innen: {1}",
        "dxFileManager-editingDeleteSingleItemErrorMessage": "Az elem nem lett törölve",
        "dxFileManager-editingDeleteMultipleItemsErrorMessage": "{0} elem nem lett törölve",
        "dxFileManager-editingDeleteCommonErrorMessage": "Néhány elem nem lett törölve",

        "dxFileManager-editingMoveSingleItemProcessingMessage": "Elem áthelyezése ide: {0}",
        "dxFileManager-editingMoveMultipleItemsProcessingMessage": "{0} elem áthelyezése ide: {1}",
        "dxFileManager-editingMoveSingleItemSuccessMessage": "Elem áthelyezve ide: {0}",
        "dxFileManager-editingMoveMultipleItemsSuccessMessage": "{0} elem áthelyezve ide: {1}",
        "dxFileManager-editingMoveSingleItemErrorMessage": "Az elem nem lett áthelyezve",
        "dxFileManager-editingMoveMultipleItemsErrorMessage": "{0} elem nem lett áthelyezve",
        "dxFileManager-editingMoveCommonErrorMessage": "Néhány elem nem lett áthelyezve",

        "dxFileManager-editingCopySingleItemProcessingMessage": "Elem másolása ide: {0}",
        "dxFileManager-editingCopyMultipleItemsProcessingMessage": "{0} elem másolása ide: {1}",
        "dxFileManager-editingCopySingleItemSuccessMessage": "Elem átmásolva ide: {0}",
        "dxFileManager-editingCopyMultipleItemsSuccessMessage": "{0} elem átmásolva ide: {1}",
        "dxFileManager-editingCopySingleItemErrorMessage": "Az elem nem lett átmásolva",
        "dxFileManager-editingCopyMultipleItemsErrorMessage": "{0} elem nem lett átmásolva",
        "dxFileManager-editingCopyCommonErrorMessage": "Néhány elem nem lett átmásolva",

        "dxFileManager-editingUploadSingleItemProcessingMessage": "Elem feltöltése ide: {0}",
        "dxFileManager-editingUploadMultipleItemsProcessingMessage": "{0} elem feltöltése ide: {1}",
        "dxFileManager-editingUploadSingleItemSuccessMessage": "Elem feltöltve ide: {0}",
        "dxFileManager-editingUploadMultipleItemsSuccessMessage": "{0} elem feltöltve ide: {1}",
        "dxFileManager-editingUploadSingleItemErrorMessage": "Az elem nem lett feltöltve",
        "dxFileManager-editingUploadMultipleItemsErrorMessage": "{0} elem nem lett feltöltve",
        "dxFileManager-editingUploadCanceledMessage": "Izbrisano",

        "dxFileManager-editingDownloadSingleItemErrorMessage": "Item was not downloaded",
        "dxFileManager-editingDownloadMultipleItemsErrorMessage": "{0} items were not downloaded",

        "dxFileManager-listDetailsColumnCaptionName": "Név",
        "dxFileManager-listDetailsColumnCaptionDateModified": "Módosítás dátuma",
        "dxFileManager-listDetailsColumnCaptionFileSize": "Fájlméret",

        "dxFileManager-listThumbnailsTooltipTextSize": "Méret",
        "dxFileManager-listThumbnailsTooltipTextDateModified": "Módosítás dátuma",

        "dxFileManager-notificationProgressPanelTitle": "Folyamat",
        "dxFileManager-notificationProgressPanelEmptyListText": "Nincsenek műveletek",
        "dxFileManager-notificationProgressPanelOperationCanceled": "Izbrisano",

        "dxDiagram-categoryGeneral": "Általános",
        "dxDiagram-categoryFlowchart": "Folyamatábra",
        "dxDiagram-categoryOrgChart": "Szervezeti ábra",
        "dxDiagram-categoryContainers": "Tárolók",
        "dxDiagram-categoryCustom": "Egyedi",

        "dxDiagram-commandExportToSvg": "Konvertuj u Excel datoteku SVG-be",
        "dxDiagram-commandExportToPng": "Konvertuj u Excel datoteku PNG-be",
        "dxDiagram-commandExportToJpg": "Konvertuj u Excel datoteku JPEG-be",
        "dxDiagram-commandUndo": "Nazadvonás",
        "dxDiagram-commandRedo": "Újra",
        "dxDiagram-commandFontName": "Betűtípus",
        "dxDiagram-commandFontSize": "Betűméret",
        "dxDiagram-commandBold": "Félkövér",
        "dxDiagram-commandItalic": "Dőlt",
        "dxDiagram-commandUnderline": "Aláhúzott",
        "dxDiagram-commandTextColor": "Betűszín",
        "dxDiagram-commandLineColor": "Vonal színe",
        "dxDiagram-commandLineWidth": "Vonal szélessége",
        "dxDiagram-commandLineStyle": "Vonal stílusa",
        "dxDiagram-commandLineStyleSolid": "Tömör",
        "dxDiagram-commandLineStyleDotted": "Pontozott",
        "dxDiagram-commandLineStyleDashed": "Szaggatott",
        "dxDiagram-commandFillColor": "Kitöltő szín",
        "dxDiagram-commandAlignLeft": "Levo igazítás",
        "dxDiagram-commandAlignCenter": "Középre igazítás",
        "dxDiagram-commandAlignRight": "Desno igazítás",
        "dxDiagram-commandConnectorLineType": "Összekötő vonal típusa",
        "dxDiagram-commandConnectorLineStraight": "Egyenes",
        "dxDiagram-commandConnectorLineOrthogonal": "Merőleges",
        "dxDiagram-commandConnectorLineStart": "Összekötő vonal kezdete",
        "dxDiagram-commandConnectorLineEnd": "Összekötő vonal vége",
        "dxDiagram-commandConnectorLineNone": "Egyik sem",
        "dxDiagram-commandConnectorLineArrow": "Nyíl",
        "dxDiagram-commandFullscreen": "Teljes képernyő",
        "dxDiagram-commandUnits": "Egységek",
        "dxDiagram-commandPageSize": "Lapméret",
        "dxDiagram-commandPageOrientation": "Tájolás",
        "dxDiagram-commandPageOrientationLandscape": "Fekvő",
        "dxDiagram-commandPageOrientationPortrait": "Álló",
        "dxDiagram-commandPageColor": "Oldal színe",
        "dxDiagram-commandShowGrid": "Rács megjelenítése",
        "dxDiagram-commandSnapToGrid": "Rácshoz igazítás",
        "dxDiagram-commandGridSize": "Rács mérete",
        "dxDiagram-commandZoomLevel": "Nagyítás",
        "dxDiagram-commandAutoZoom": "Automatikus nagyítás",
        "dxDiagram-commandFitToContent": "Tartalomhoz igazítás",
        "dxDiagram-commandFitToWidth": "Szélességhez igazítás",
        "dxDiagram-commandAutoZoomByContent": "Automatikus nagyítás tartalom szerint",
        "dxDiagram-commandAutoZoomByWidth": "Automatikus nagyítás szélesség szerint",
        "dxDiagram-commandSimpleView": "Egyszerűsített nézet",
        "dxDiagram-commandCut": "Kivágás",
        "dxDiagram-commandCopy": "Másolás",
        "dxDiagram-commandPaste": "Beillesztés",
        "dxDiagram-commandSelectAll": "Izaberite sve",
        "dxDiagram-commandDelete": "Izbriši",
        "dxDiagram-commandBringToFront": "Előrehoz",
        "dxDiagram-commandSendToBack": "Hátraküld",
        "dxDiagram-commandLock": "Rögzít",
        "dxDiagram-commandUnlock": "Felold",
        "dxDiagram-commandInsertShapeImage": "Kép beszúrása...",
        "dxDiagram-commandEditShapeImage": "Kép cseréje...",
        "dxDiagram-commandDeleteShapeImage": "Kép törlése",
        "dxDiagram-commandLayoutLeftToRight": "Balról jobbra",
        "dxDiagram-commandLayoutRightToLeft": "Jobbról balra",
        "dxDiagram-commandLayoutTopToBottom": "Fentről lefelé",
        "dxDiagram-commandLayoutBottomToTop": "Lentről felfelé",

        "dxDiagram-unitIn": "in",
        "dxDiagram-unitCm": "cm",
        "dxDiagram-unitPx": "px",

        "dxDiagram-dialogButtonOK": "OK",
        "dxDiagram-dialogButtonCancel": "Ipak",
        "dxDiagram-dialogInsertShapeImageTitle": "Kép beszúrása",
        "dxDiagram-dialogEditShapeImageTitle": "Kép cseréje",
        "dxDiagram-dialogEditShapeImageSelectButton": "Kép kiválasztása",
        "dxDiagram-dialogEditShapeImageLabelText": "ili prevucite datoteku ovde",

        "dxDiagram-uiExport": "Konvertuj u Excel datoteku",
        "dxDiagram-uiProperties": "Tulajdonságok",
        "dxDiagram-uiSettings": "Beállítások",
        "dxDiagram-uiShowToolbox": "Eszköztár megjelenítése",
        "dxDiagram-uiSearch": "Pretraga",
        "dxDiagram-uiStyle": "Stílus",
        "dxDiagram-uiLayout": "Elrendezés",
        "dxDiagram-uiLayoutTree": "Fa",
        "dxDiagram-uiLayoutLayered": "Réteges",
        "dxDiagram-uiDiagram": "Diagram",
        "dxDiagram-uiText": "Szöveg",
        "dxDiagram-uiObject": "Objektum",
        "dxDiagram-uiConnector": "Csatlakozó",
        "dxDiagram-uiPage": "Oldal",

        "dxDiagram-shapeText": "Szöveg",
        "dxDiagram-shapeRectangle": "Téglalap",
        "dxDiagram-shapeEllipse": "Ellipszis",
        "dxDiagram-shapeCross": "Kereszt",
        "dxDiagram-shapeTriangle": "Háromszög",
        "dxDiagram-shapeDiamond": "Rombusz",
        "dxDiagram-shapeHeart": "Szív",
        "dxDiagram-shapePentagon": "Ötszög",
        "dxDiagram-shapeHexagon": "Hatszög",
        "dxDiagram-shapeOctagon": "Nyolcszög",
        "dxDiagram-shapeStar": "Csillag",
        "dxDiagram-shapeArrowLeft": "Levo nyíl",
        "dxDiagram-shapeArrowUp": "Felfelé nyíl",
        "dxDiagram-shapeArrowRight": "Desno nyíl",
        "dxDiagram-shapeArrowDown": "Lefelé nyíl",
        "dxDiagram-shapeArrowUpDown": "Fel-le nyíl",
        "dxDiagram-shapeArrowLeftRight": "Levo-jobbra nyíl",
        "dxDiagram-shapeProcess": "Folyamat",
        "dxDiagram-shapeDecision": "Döntés",
        "dxDiagram-shapeTerminator": "Lezárás",
        "dxDiagram-shapePredefinedProcess": "Előre meghatározott folyamat",
        "dxDiagram-shapeDocument": "Dokumentum",
        "dxDiagram-shapeMultipleDocuments": "Több dokumentum",
        "dxDiagram-shapeManualInput": "Kézi bevitel",
        "dxDiagram-shapePreparation": "Előkészítés",
        "dxDiagram-shapeData": "Adat",
        "dxDiagram-shapeDatabase": "Adatbázis",
        "dxDiagram-shapeHardDisk": "Merevlemez",
        "dxDiagram-shapeInternalStorage": "Belső tároló",
        "dxDiagram-shapePaperTape": "Papírszalag",
        "dxDiagram-shapeManualOperation": "Kézi művelet",
        "dxDiagram-shapeDelay": "Késés",
        "dxDiagram-shapeStoredData": "Tárolt adatok",
        "dxDiagram-shapeDisplay": "Kijelző",
        "dxDiagram-shapeMerge": "Összevonás",
        "dxDiagram-shapeConnector": "Csatlakozó",
        "dxDiagram-shapeOr": "Vagy",
        "dxDiagram-shapeSummingJunction": "Összegző csomópont",
        "dxDiagram-shapeContainerDefaultText": "Tároló",
        "dxDiagram-shapeVerticalContainer": "Függőleges tároló",
        "dxDiagram-shapeHorizontalContainer": "Vízszintes tároló",
        "dxDiagram-shapeCardDefaultText": "Személy neve",
        "dxDiagram-shapeCardWithImageOnLeft": "Kártya, bal oldalon képpel",
        "dxDiagram-shapeCardWithImageOnTop": "Kártya, felül képpel",
        "dxDiagram-shapeCardWithImageOnRight": "Kártya, jobb oldalon képpel",

        "dxGantt-dialogTitle": "Cím",
        "dxGantt-dialogStartTitle": "Kezdés",
        "dxGantt-dialogEndTitle": "Befejezés",
        "dxGantt-dialogProgressTitle": "Folyamat",
        "dxGantt-dialogResourcesTitle": "Erőforrás",
        "dxGantt-dialogResourceManagerTitle": "Erőforrás menedzser",
        "dxGantt-dialogTaskDetailsTitle": "Feladat részletei",
        "dxGantt-dialogEditResourceListHint": "Erőforrás lista szerkesztése",
        "dxGantt-dialogEditNoResources": "Nincsenek erőforrások",
        "dxGantt-dialogButtonAdd": "Hozzáadás",
        "dxGantt-contextMenuNewTask": "Novi zadatak",
        "dxGantt-contextMenuNewSubtask": "Novi zadatak",
        "dxGantt-contextMenuDeleteTask": "Feladat törlése",
        "dxGantt-contextMenuDeleteDependency": "Függőség törlése",
        "dxGantt-dialogTaskDeleteConfirmation": "A feladat törlésével az összes függősége és részfeladata is törlődik. Biztosan törli ezt a feladatot?",
        "dxGantt-dialogDependencyDeleteConfirmation": "Biztosan törli a függőséget a feladatból?",
        "dxGantt-dialogResourcesDeleteConfirmation": "Az erőforrás törlése egyúttal törli azokból a feladatokból is, amelyekhez ez az erőforrás tartozik. Biztosan törli ezt az erőforrást? Erőforrás: {0}",
        "dxGantt-dialogConstraintCriticalViolationMessage": "Az áthelyezni kívánt feladatot függőségi viszony kapcsolja össze egy másik feladattal. Ez a változás ütközne a függőségi szabályokkal. Hogyan szeretné folytatni?",
        "dxGantt-dialogConstraintViolationMessage": "Az áthelyezni kívánt feladatot függőségi viszony kapcsolja össze egy másik feladattal. Hogyan szeretné folytatni?",
        "dxGantt-dialogCancelOperationMessage": "Művelet megszakítása",
        "dxGantt-dialogDeleteDependencyMessage": "Függőség törlése",
        "dxGantt-dialogMoveTaskAndKeepDependencyMessage": "Helyezze át a feladatot, és tartsa meg a függőséget",
        "dxGantt-dialogConstraintCriticalViolationSeveralTasksMessage": "The task you are attempting to move is linked to another tasks by dependency relations. This change would conflict with dependency rules. How would you like to proceed?",
        "dxGantt-dialogConstraintViolationSeveralTasksMessage": "The task you are attempting to move is linked to another tasks by dependency relations. How would you like to proceed?",
        "dxGantt-dialogDeleteDependenciesMessage": "Delete the dependency relations",
        "dxGantt-dialogMoveTaskAndKeepDependenciesMessage": "Move the task and keep the dependencies",
        "dxGantt-undo": "Nazadvonás",
        "dxGantt-redo": "Još uvek",
        "dxGantt-expandAll": "Összes kibontása",
        "dxGantt-collapseAll": "Összes összecsukása",
        "dxGantt-addNewTask": "Új feladat hozzáadása",
        "dxGantt-deleteSelectedTask": "Kiválasztott feladat törlése",
        "dxGantt-zoomIn": "Nagyítás",
        "dxGantt-zoomOut": "Kicsinyítés",
        "dxGantt-fullScreen": "Teljes képernyő",
        "dxGantt-quarter": "Q{0}",
        "dxGantt-sortingAscendingText": "Rastuće",
        "dxGantt-sortingDescendingText": "Smanjujuće",
        "dxGantt-sortingClearText": "izbrisi izgled",
        "dxGantt-showResources": "Show Resources",
        "dxGantt-showDependencies": "Show Dependencies",
        "dxGantt-dialogStartDateValidation": "Start date must be after {0}",
        "dxGantt-dialogEndDateValidation": "End date must be after {0}",

        "dxGallery-itemName": "Gallery item",

        "dxMultiView-elementAriaRoleDescription": "MultiView",
        "dxMultiView-elementAriaLabel": "Use the arrow keys or swipe to navigate between views",
        "dxMultiView-itemAriaRoleDescription": "View",
        "dxMultiView-itemAriaLabel": "{0} of {1}"
    }
}
