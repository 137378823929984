{
    "ro": {
        "Yes": "Da",
        "No": "Nu",
        "Cancel": "Anulare",
        "Close": "Închide",
        "Clear": "Curăță",
        "Done": "Gata",
        "Loading": "Incărcare...",
        "Select": "Selectează...",
        "Search": "Caută",
        "Back": "Înapoi",
        "OK": "OK",

        "dxCollectionWidget-noDataText": "Nu există date",

        "dxDropDownEditor-selectLabel": "Selectează",

        "validation-required": "Obligatoriu",
        "validation-required-formatted": "{0} este obligatoriu",
        "validation-numeric": "Valoarea trebuie sa fie un număr",
        "validation-numeric-formatted": "{0} trebuie sa fie un număr",
        "validation-range": "Valoarea depășește limita",
        "validation-range-formatted": "{0} depășește limita",
        "validation-stringLength": "Lungimea valorii nu este corectă",
        "validation-stringLength-formatted": "Lungimea pentru {0} nu este corectă",
        "validation-custom": "Valoarea nu este validă",
        "validation-custom-formatted": "{0} nu este validă",
        "validation-async": "Valoarea nu este validă",
        "validation-async-formatted": "{0} valoarea nu este validă",
        "validation-compare": "Valorile nu corespund",
        "validation-compare-formatted": "{0} nu corespunde",
        "validation-pattern": "Valoarea nu corespunde modelului",
        "validation-pattern-formatted": "{0} nu corespunde modelului",
        "validation-email": "Adresa de email nu este valid",
        "validation-email-formatted": "{0} nu este validă",
        "validation-mask": "Valoarea nu este validă",

        "dxLookup-searchPlaceholder": "Numărul minim de caractere: {0}",

        "dxList-pullingDownText": "Trageți în jos pentru a actualiza...",
        "dxList-pulledDownText": "Eliberați pentru a actualiza...",
        "dxList-refreshingText": "Actualizare...",
        "dxList-pageLoadingText": "Încărcare...",
        "dxList-nextButtonText": "Mai mult",
        "dxList-selectAll": "Selectează Tot",
        "dxListEditDecorator-delete": "Șterge",
        "dxListEditDecorator-more": "Mai mult",

        "dxScrollView-pullingDownText": "Trageți în jos pentru a actualiza...",
        "dxScrollView-pulledDownText": "Eliberați pentru a actualiza...",
        "dxScrollView-refreshingText": "Actualizare...",
        "dxScrollView-reachBottomText": "Încărcare...",

        "dxDateBox-simulatedDataPickerTitleTime": "Selectează timpul",
        "dxDateBox-simulatedDataPickerTitleDate": "Selectează data",
        "dxDateBox-simulatedDataPickerTitleDateTime": "Selectează data și timpul",
        "dxDateBox-validation-datetime": "Valoarea trebuie să fie o dată sau timp",

        "dxDateRangeBox-invalidStartDateMessage": "Start value must be a date",
        "dxDateRangeBox-invalidEndDateMessage": "End value must be a date",
        "dxDateRangeBox-startDateOutOfRangeMessage": "Start date is out of range",
        "dxDateRangeBox-endDateOutOfRangeMessage": "End date is out of range",
        "dxDateRangeBox-startDateLabel": "Data de început",
        "dxDateRangeBox-endDateLabel": "Data de încheiere",

        "dxFileUploader-selectFile": "Selectează fișierul",
        "dxFileUploader-dropFile": "sau plasați fișierul aici",
        "dxFileUploader-bytes": "bytes",
        "dxFileUploader-kb": "KB",
        "dxFileUploader-Mb": "MB",
        "dxFileUploader-Gb": "GB",
        "dxFileUploader-upload": "Încarcă",
        "dxFileUploader-uploaded": "Încărcat",
        "dxFileUploader-readyToUpload": "Gata pentru încărcare",
        "dxFileUploader-uploadAbortedMessage": "Încărcare anulată",
        "dxFileUploader-uploadFailedMessage": "Încărcare eșuată",
        "dxFileUploader-invalidFileExtension": "Tipul fișierului nu este permis",
        "dxFileUploader-invalidMaxFileSize": "Fișierul este prea mare",
        "dxFileUploader-invalidMinFileSize": "Fișierul este prea mic",

        "dxRangeSlider-ariaFrom": "Din",
        "dxRangeSlider-ariaTill": "Până",
        "dxSwitch-switchedOnText": "Pornit",
        "dxSwitch-switchedOffText": "Oprit",

        "dxForm-optionalMark": "optional",
        "dxForm-requiredMessage": "{0} este obligatoriu",

        "dxNumberBox-invalidValueMessage": "Valoarea trebuie sa fie un numră",
        "dxNumberBox-noDataText": "Nu există date",

        "dxDataGrid-emptyHeaderWithColumnChooserText": "Use {0} to display columns",
        "dxDataGrid-emptyHeaderWithGroupPanelText": "Drag a column from the group panel here",
        "dxDataGrid-emptyHeaderWithColumnChooserAndGroupPanelText": "Use {0} or drag a column from the group panel",
        "dxDataGrid-emptyHeaderColumnChooserText": "column chooser",
        "dxDataGrid-columnChooserTitle": "Alege coloana",
        "dxDataGrid-columnChooserEmptyText": "Trage coloana aici pentru a o ascunde",
        "dxDataGrid-groupContinuesMessage": "Continuă pe pagina urmatoare",
        "dxDataGrid-groupContinuedMessage": "Continuă de la pagina anterioară",
        "dxDataGrid-groupHeaderText": "Grupează după această coloană",
        "dxDataGrid-ungroupHeaderText": "Degrupează",
        "dxDataGrid-ungroupAllText": "Degrupează Tot",
        "dxDataGrid-editingEditRow": "Editare",
        "dxDataGrid-editingSaveRowChanges": "Salvare",
        "dxDataGrid-editingCancelRowChanges": "Anulare",
        "dxDataGrid-editingDeleteRow": "Șterge",
        "dxDataGrid-editingUndeleteRow": "Anulați ștergerea",
        "dxDataGrid-editingConfirmDeleteMessage": "Sigur doriți să ștergeți această înregistrare?",
        "dxDataGrid-validationCancelChanges": "Anulează modificările",
        "dxDataGrid-groupPanelEmptyText": "Trageți aici un antet de coloană pentru a grupa după acea coloană",
        "dxDataGrid-noDataText": "Nu există date",
        "dxDataGrid-searchPanelPlaceholder": "Caută...",
        "dxDataGrid-filterRowShowAllText": "(Tot)",
        "dxDataGrid-filterRowResetOperationText": "Resetare",
        "dxDataGrid-filterRowOperationEquals": "Egal",
        "dxDataGrid-filterRowOperationNotEquals": "Nu este egal",
        "dxDataGrid-filterRowOperationLess": "Mai puțin decât",
        "dxDataGrid-filterRowOperationLessOrEquals": "Mai puțin decât sau egal",
        "dxDataGrid-filterRowOperationGreater": "Mai mare decât",
        "dxDataGrid-filterRowOperationGreaterOrEquals": "Mai mare decât sau egal",
        "dxDataGrid-filterRowOperationStartsWith": "Începe cu",
        "dxDataGrid-filterRowOperationContains": "Conține",
        "dxDataGrid-filterRowOperationNotContains": "Nu conține",
        "dxDataGrid-filterRowOperationEndsWith": "Se termină cu",
        "dxDataGrid-filterRowOperationBetween": "Între",
        "dxDataGrid-filterRowOperationBetweenStartText": "Începe",
        "dxDataGrid-filterRowOperationBetweenEndText": "Sfârșit",
        "dxDataGrid-ariaSearchBox": "Search box",
        "dxDataGrid-applyFilterText": "Aplica filtre",
        "dxDataGrid-trueText": "adevărat",
        "dxDataGrid-falseText": "fals",
        "dxDataGrid-sortingAscendingText": "Sortare crescătoare",
        "dxDataGrid-sortingDescendingText": "Sortare descrescătoare",
        "dxDataGrid-sortingClearText": "Curăță Sortarea",
        "dxDataGrid-ariaNotSortedColumn": "Not sorted column",
        "dxDataGrid-ariaSortedAscendingColumn": "Column sorted in ascending order",
        "dxDataGrid-ariaSortedDescendingColumn": "Column sorted in descending order",
        "dxDataGrid-ariaSortIndex": "Sort index {0}",
        "dxDataGrid-editingSaveAllChanges": "Salvează modificările",
        "dxDataGrid-editingCancelAllChanges": "Renunţă la modificări",
        "dxDataGrid-editingAddRow": "Adaugă un rând",
        "dxDataGrid-summaryMin": "Min: {0}",
        "dxDataGrid-summaryMinOtherColumn": "Min of {1} este {0}",
        "dxDataGrid-summaryMax": "Max: {0}",
        "dxDataGrid-summaryMaxOtherColumn": "Max of {1} este {0}",
        "dxDataGrid-summaryAvg": "Avg: {0}",
        "dxDataGrid-summaryAvgOtherColumn": "Med of {1} este {0}",
        "dxDataGrid-summarySum": "Sum: {0}",
        "dxDataGrid-summarySumOtherColumn": "Sum of {1} este {0}",
        "dxDataGrid-summaryCount": "Numără: {0}",
        "dxDataGrid-columnFixingFix": "Fixare",
        "dxDataGrid-columnFixingUnfix": "Defixare",
        "dxDataGrid-columnFixingLeftPosition": "La stânga",
        "dxDataGrid-columnFixingRightPosition": "La dreapta",
        "dxDataGrid-exportTo": "Export",
        "dxDataGrid-exportToExcel": "Exportare fișier Excel ",
        "dxDataGrid-exporting": "Exportare...",
        "dxDataGrid-excelFormat": "Fișier Excel",
        "dxDataGrid-selectedRows": "Selectează rând",
        "dxDataGrid-exportSelectedRows": "Exportă rândul selectat",
        "dxDataGrid-exportAll": "Exportă toate datele ",
        "dxDataGrid-headerFilterLabel": "Filter options",
        "dxDataGrid-headerFilterIndicatorLabel": "Show filter options for column '{0}'",
        "dxDataGrid-headerFilterEmptyValue": "(Gol)",
        "dxDataGrid-headerFilterOK": "OK",
        "dxDataGrid-headerFilterCancel": "Anulare",
        "dxDataGrid-ariaAdaptiveCollapse": "Hide additional data",
        "dxDataGrid-ariaAdaptiveExpand": "Display additional data",
        "dxDataGrid-ariaColumn": "Coloană",
        "dxDataGrid-ariaColumnHeader": "Column header",
        "dxDataGrid-ariaValue": "Valoare",
        "dxDataGrid-ariaError": "Error",
        "dxDataGrid-ariaRevertButton": "Press Escape to discard the changes",
        "dxDataGrid-ariaFilterCell": "Celulă de filtrare",
        "dxDataGrid-ariaCollapse": "Restrângere",
        "dxDataGrid-ariaModifiedCell": "Modified",
        "dxDataGrid-ariaDeletedCell": "Deleted",
        "dxDataGrid-ariaEditableCell": "Editable",
        "dxDataGrid-ariaExpand": "Extindere",
        "dxDataGrid-ariaCollapsedRow": "Collapsed row",
        "dxDataGrid-ariaExpandedRow": "Expanded row",
        "dxDataGrid-ariaDataGrid": "Grilă de date",
        "dxDataGrid-ariaSearchInGrid": "Caută in grila de date",
        "dxDataGrid-ariaSelectAll": "Selectează tot",
        "dxDataGrid-ariaSelectRow": "Selectează rând",
        "dxDataGrid-ariaToolbar": "Data grid toolbar",
        "dxDataGrid-ariaEditForm": "Edit form",
        "dxDataGrid-filterBuilderPopupTitle": "Filtrează Constructor",
        "dxDataGrid-filterPanelCreateFilter": "Crează Fișier",
        "dxDataGrid-filterPanelClearFilter": "Curăță",
        "dxDataGrid-filterPanelFilterEnabledHint": "Activați filtrul",

        "dxTreeList-ariaTreeList": "Tree list with {0} rows and {1} columns",
        "dxTreeList-ariaSearchInGrid": "Search in the tree list",
        "dxTreeList-ariaToolbar": "Tree list toolbar",
        "dxTreeList-editingAddRowToNode": "Adaugă",

        "dxPager-infoText": "Pagina {0} de {1} ({2} obiecte)",
        "dxPager-pagesCountText": "de",
        "dxPager-pageSize": "Items per page: {0}",
        "dxPager-pageSizesAllText": "Tot",
        "dxPager-page": "Page {0}",
        "dxPager-prevPage": "Previous Page",
        "dxPager-nextPage": "Next Page",
        "dxPager-ariaLabel": "Page Navigation",
        "dxPager-ariaPageSize": "Page size",
        "dxPager-ariaPageNumber": "Page number",

        "dxPivotGrid-grandTotal": "Total general",
        "dxPivotGrid-total": "{0} Total",
        "dxPivotGrid-fieldChooserTitle": "Selector de Câmp",
        "dxPivotGrid-showFieldChooser": "Afișează Selectorul de Câmp",
        "dxPivotGrid-expandAll": "Extindere totală",
        "dxPivotGrid-collapseAll": "Restrângere totală",
        "dxPivotGrid-sortColumnBySummary": "Sortează \"{0}\" după Aceasta Coloană",
        "dxPivotGrid-sortRowBySummary": "Sortează \"{0}\" după Aceast Rând",
        "dxPivotGrid-removeAllSorting": "Elimină Toată Sortarea",
        "dxPivotGrid-dataNotAvailable": "N/A",
        "dxPivotGrid-rowFields": "Câmpuri de rând",
        "dxPivotGrid-columnFields": "Câmpuri de coloană",
        "dxPivotGrid-dataFields": "Câmpuri de date",
        "dxPivotGrid-filterFields": "Filter Fields",
        "dxPivotGrid-allFields": "Câmpuri de filtrare",
        "dxPivotGrid-columnFieldArea": "Plasați câmpurile de coloană aici",
        "dxPivotGrid-dataFieldArea": "Plasați câmpurile de date aici",
        "dxPivotGrid-rowFieldArea": "Plasați câmpurile rândului aici",
        "dxPivotGrid-filterFieldArea": "Plasați câmpurile de filtrare aici",

        "dxScheduler-editorLabelTitle": "Subiect",
        "dxScheduler-editorLabelStartDate": "Data de început",
        "dxScheduler-editorLabelEndDate": "Data de încheiere",
        "dxScheduler-editorLabelDescription": "Descriere",
        "dxScheduler-editorLabelRecurrence": "Repetă",

        "dxScheduler-openAppointment": "Deschideți rezervarea",

        "dxScheduler-recurrenceNever": "Niciodată",
        "dxScheduler-recurrenceMinutely": "In fiecare minut",
        "dxScheduler-recurrenceHourly": "Orar",
        "dxScheduler-recurrenceDaily": "Zilnic",
        "dxScheduler-recurrenceWeekly": "Săptămânal",
        "dxScheduler-recurrenceMonthly": "Lunar",
        "dxScheduler-recurrenceYearly": "Anual",

        "dxScheduler-recurrenceRepeatEvery": "Repetă fiecare",
        "dxScheduler-recurrenceRepeatOn": "Repetă pe",
        "dxScheduler-recurrenceEnd": "Terminați repetarea",
        "dxScheduler-recurrenceAfter": "După",
        "dxScheduler-recurrenceOn": "Pe",

        "dxScheduler-recurrenceRepeatMinutely": "minute(s)",
        "dxScheduler-recurrenceRepeatHourly": "ore(s)",
        "dxScheduler-recurrenceRepeatDaily": "zile(s)",
        "dxScheduler-recurrenceRepeatWeekly": "săptămâni(s)",
        "dxScheduler-recurrenceRepeatMonthly": "luni(s)",
        "dxScheduler-recurrenceRepeatYearly": "ani(s)",

        "dxScheduler-switcherDay": "Zi",
        "dxScheduler-switcherWeek": "Săptămână",
        "dxScheduler-switcherWorkWeek": "Săptămână de lucru",
        "dxScheduler-switcherMonth": "Lună",

        "dxScheduler-switcherAgenda": "Agendă",

        "dxScheduler-switcherTimelineDay": "Orar zilnic",
        "dxScheduler-switcherTimelineWeek": "Orar săptămânal",
        "dxScheduler-switcherTimelineWorkWeek": "Orar de lucru săptămânal ",
        "dxScheduler-switcherTimelineMonth": "Orar lunar",

        "dxScheduler-recurrenceRepeatOnDate": "pe data",
        "dxScheduler-recurrenceRepeatCount": "apariție(s)",
        "dxScheduler-allDay": "Totă ziua",

        "dxScheduler-confirmRecurrenceEditTitle": "Edit Recurring Appointment",
        "dxScheduler-confirmRecurrenceDeleteTitle": "Delete Recurring Appointment",

        "dxScheduler-confirmRecurrenceEditMessage": "Doriți să editați doar această programare sau întreaga serie?",
        "dxScheduler-confirmRecurrenceDeleteMessage": "Doriți să ștergeți doar această programare sau întreaga serie?",

        "dxScheduler-confirmRecurrenceEditSeries": "Editați serie",
        "dxScheduler-confirmRecurrenceDeleteSeries": "Ștergeți serie",
        "dxScheduler-confirmRecurrenceEditOccurrence": "Editare rezervare",
        "dxScheduler-confirmRecurrenceDeleteOccurrence": "Ștergere rezervare",

        "dxScheduler-noTimezoneTitle": "Fără fus orar",
        "dxScheduler-moreAppointments": "{0} mai mult",

        "dxCalendar-todayButtonText": "Astăzi",
        "dxCalendar-ariaWidgetName": "Calendar",
        "dxCalendar-previousMonthButtonLabel": "Previous month",
        "dxCalendar-previousYearButtonLabel": "Previous year",
        "dxCalendar-previousDecadeButtonLabel": "Previous decade",
        "dxCalendar-previousCenturyButtonLabel": "Previous century",
        "dxCalendar-nextMonthButtonLabel": "Next month",
        "dxCalendar-nextYearButtonLabel": "Next year",
        "dxCalendar-nextDecadeButtonLabel": "Next decade",
        "dxCalendar-nextCenturyButtonLabel": "Next century",
        "dxCalendar-captionMonthLabel": "Month selection",
        "dxCalendar-captionYearLabel": "Year selection",
        "dxCalendar-captionDecadeLabel": "Decade selection",
        "dxCalendar-captionCenturyLabel": "Century selection",

        "dxColorView-ariaRed": "Roșu",
        "dxColorView-ariaGreen": "Verde",
        "dxColorView-ariaBlue": "Albastru",
        "dxColorView-ariaAlpha": "Tranparent",
        "dxColorView-ariaHex": "Cod de culoare",

        "dxTagBox-selected": "{0} selectat",
        "dxTagBox-allSelected": "Toate selctate ({0})",
        "dxTagBox-moreSelected": "{0} mai mult",

        "vizExport-printingButtonText": "Printare",
        "vizExport-titleMenuText": "Exportare/Printare",
        "vizExport-exportButtonText": "{0} fișier",

        "dxFilterBuilder-and": "și",
        "dxFilterBuilder-or": "Sau",
        "dxFilterBuilder-notAnd": "Nu și",
        "dxFilterBuilder-notOr": "Nu sau",
        "dxFilterBuilder-addCondition": "Adaugă condiție",
        "dxFilterBuilder-addGroup": "Adaugă grup",
        "dxFilterBuilder-enterValueText": "<introduceți o valoare>",
        "dxFilterBuilder-filterOperationEquals": "Egal",
        "dxFilterBuilder-filterOperationNotEquals": "Nu este egal",
        "dxFilterBuilder-filterOperationLess": "Este mai puțin decât",
        "dxFilterBuilder-filterOperationLessOrEquals": "Este mai puțin decât sau egal",
        "dxFilterBuilder-filterOperationGreater": "Este mai mare decât",
        "dxFilterBuilder-filterOperationGreaterOrEquals": "Este mai mare decât sau egal",
        "dxFilterBuilder-filterOperationStartsWith": "Incepe cu",
        "dxFilterBuilder-filterOperationContains": "Conține",
        "dxFilterBuilder-filterOperationNotContains": "Nu conține",
        "dxFilterBuilder-filterOperationEndsWith": "Se termina cu",
        "dxFilterBuilder-filterOperationIsBlank": "Este gol",
        "dxFilterBuilder-filterOperationIsNotBlank": "Nu este gol",
        "dxFilterBuilder-filterOperationBetween": "Este între",
        "dxFilterBuilder-filterOperationAnyOf": "Este oricare dintre",
        "dxFilterBuilder-filterOperationNoneOf": "Nu este nici una dintre",

        "dxHtmlEditor-dialogColorCaption": "Schimbă culoarea fontului",
        "dxHtmlEditor-dialogBackgroundCaption": "Schimbă culoarea de fundal",
        "dxHtmlEditor-dialogLinkCaption": "Adaugă Link",
        "dxHtmlEditor-dialogLinkUrlField": "URL",
        "dxHtmlEditor-dialogLinkTextField": "Text",
        "dxHtmlEditor-dialogLinkTargetField": "Dechide link-ul intr-o nouă fereastră",
        "dxHtmlEditor-dialogImageCaption": "Adaugă imagine",
        "dxHtmlEditor-dialogImageUrlField": "URL",
        "dxHtmlEditor-dialogImageAltField": "Text alternativ",
        "dxHtmlEditor-dialogImageWidthField": "Lăţime (px)",
        "dxHtmlEditor-dialogImageHeightField": "Înălţime (px)",
        "dxHtmlEditor-dialogInsertTableRowsField": "Rând",
        "dxHtmlEditor-dialogInsertTableColumnsField": "Coloană",
        "dxHtmlEditor-dialogInsertTableCaption": "Introduceți tabelul",
        "dxHtmlEditor-dialogUpdateImageCaption": "Update Image",
        "dxHtmlEditor-dialogImageUpdateButton": "Update",
        "dxHtmlEditor-dialogImageAddButton": "Add",
        "dxHtmlEditor-dialogImageSpecifyUrl": "From the Web",
        "dxHtmlEditor-dialogImageSelectFile": "From This Device",
        "dxHtmlEditor-dialogImageKeepAspectRatio": "Keep Aspect Ratio",
        "dxHtmlEditor-dialogImageEncodeToBase64": "Encode to Base64",
        "dxHtmlEditor-heading": "Titlu",
        "dxHtmlEditor-normalText": "Text normal",
        "dxHtmlEditor-background": "Background Color",
        "dxHtmlEditor-bold": "Bold",
        "dxHtmlEditor-color": "Font Color",
        "dxHtmlEditor-font": "Font",
        "dxHtmlEditor-italic": "Italic",
        "dxHtmlEditor-link": "Add Link",
        "dxHtmlEditor-image": "Add Image",
        "dxHtmlEditor-size": "Size",
        "dxHtmlEditor-strike": "Strikethrough",
        "dxHtmlEditor-subscript": "Subscript",
        "dxHtmlEditor-superscript": "Superscript",
        "dxHtmlEditor-underline": "Underline",
        "dxHtmlEditor-blockquote": "Blockquote",
        "dxHtmlEditor-header": "Header",
        "dxHtmlEditor-increaseIndent": "Increase Indent",
        "dxHtmlEditor-decreaseIndent": "Decrease Indent",
        "dxHtmlEditor-orderedList": "Ordered List",
        "dxHtmlEditor-bulletList": "Bullet List",
        "dxHtmlEditor-alignLeft": "Align Left",
        "dxHtmlEditor-alignCenter": "Align Center",
        "dxHtmlEditor-alignRight": "Align Right",
        "dxHtmlEditor-alignJustify": "Align Justify",
        "dxHtmlEditor-codeBlock": "Code Block",
        "dxHtmlEditor-variable": "Add Variable",
        "dxHtmlEditor-undo": "Undo",
        "dxHtmlEditor-redo": "Redo",
        "dxHtmlEditor-clear": "Clear Formatting",
        "dxHtmlEditor-insertTable": "Insert Table",
        "dxHtmlEditor-insertHeaderRow": "Insert Header Row",
        "dxHtmlEditor-insertRowAbove": "Insert Row Above",
        "dxHtmlEditor-insertRowBelow": "Insert Row Below",
        "dxHtmlEditor-insertColumnLeft": "Insert Column Left",
        "dxHtmlEditor-insertColumnRight": "Insert Column Right",
        "dxHtmlEditor-deleteColumn": "Delete Column",
        "dxHtmlEditor-deleteRow": "Delete Row",
        "dxHtmlEditor-deleteTable": "Delete Table",
        "dxHtmlEditor-cellProperties": "Cell Properties",
        "dxHtmlEditor-tableProperties": "Table Properties",
        "dxHtmlEditor-insert": "Insert",
        "dxHtmlEditor-delete": "Delete",
        "dxHtmlEditor-border": "Border",
        "dxHtmlEditor-style": "Style",
        "dxHtmlEditor-width": "Width",
        "dxHtmlEditor-height": "Height",
        "dxHtmlEditor-borderColor": "Color",
        "dxHtmlEditor-tableBackground": "Background",
        "dxHtmlEditor-dimensions": "Dimensions",
        "dxHtmlEditor-alignment": "Alignment",
        "dxHtmlEditor-horizontal": "Horizontal",
        "dxHtmlEditor-vertical": "Vertical",
        "dxHtmlEditor-paddingVertical": "Vertical Padding",
        "dxHtmlEditor-paddingHorizontal": "Horizontal Padding",
        "dxHtmlEditor-pixels": "Pixels",
        "dxHtmlEditor-list": "List",
        "dxHtmlEditor-ordered": "Ordered",
        "dxHtmlEditor-bullet": "Bullet",
        "dxHtmlEditor-align": "Align",
        "dxHtmlEditor-center": "Center",
        "dxHtmlEditor-left": "Left",
        "dxHtmlEditor-right": "Right",
        "dxHtmlEditor-indent": "Indent",
        "dxHtmlEditor-justify": "Justify",
        "dxHtmlEditor-borderStyleNone": "none",
        "dxHtmlEditor-borderStyleHidden": "hidden",
        "dxHtmlEditor-borderStyleDotted": "dotted",
        "dxHtmlEditor-borderStyleDashed": "dashed",
        "dxHtmlEditor-borderStyleSolid": "solid",
        "dxHtmlEditor-borderStyleDouble": "double",
        "dxHtmlEditor-borderStyleGroove": "groove",
        "dxHtmlEditor-borderStyleRidge": "ridge",
        "dxHtmlEditor-borderStyleInset": "inset",
        "dxHtmlEditor-borderStyleOutset": "outset",

        "dxFileManager-newDirectoryName": "Director fără titlu",
        "dxFileManager-rootDirectoryName": "Fişiere",
        "dxFileManager-errorNoAccess": "Acces interzis. Operațiunea nu a putut fi finalizată.",
        "dxFileManager-errorDirectoryExistsFormat": "Directorul '{0}' există deja.",
        "dxFileManager-errorFileExistsFormat": "Fişierul '{0}' există deja.",
        "dxFileManager-errorFileNotFoundFormat": "Fişierul '{0}' nu a fost găsit.",
        "dxFileManager-errorDirectoryNotFoundFormat": "Directorul '{0}' nu a fost găsit.",
        "dxFileManager-errorWrongFileExtension": "Extensia de fișier nu este permisă.",
        "dxFileManager-errorMaxFileSizeExceeded": "Dimensiunea fișierului depășește dimensiunea maximă permisă.",
        "dxFileManager-errorInvalidSymbols": "Acest nume conține caractere nevalide.",
        "dxFileManager-errorDefault": "Eroare nespecificată.",
        "dxFileManager-errorDirectoryOpenFailed": "Directorul nu poate fi deschis",

        "dxFileManager-commandCreate": "Director nou",
        "dxFileManager-commandRename": "Redenumire",
        "dxFileManager-commandMove": "Mută la",
        "dxFileManager-commandCopy": "Copiază in",
        "dxFileManager-commandDelete": "Șterge",
        "dxFileManager-commandDownload": "Descarcă",
        "dxFileManager-commandUpload": "Incarcă fişier",
        "dxFileManager-commandRefresh": "Reîncarca",
        "dxFileManager-commandThumbnails": "Vizualizare miniaturi",
        "dxFileManager-commandDetails": "Vizualizare detali",
        "dxFileManager-commandClearSelection": "Selecție clară",
        "dxFileManager-commandShowNavPane": "Comutați panoul de navigare",

        "dxFileManager-dialogDirectoryChooserMoveTitle": "Mută la",
        "dxFileManager-dialogDirectoryChooserMoveButtonText": "Mută",
        "dxFileManager-dialogDirectoryChooserCopyTitle": "Copiază in",
        "dxFileManager-dialogDirectoryChooserCopyButtonText": "Copiază",
        "dxFileManager-dialogRenameItemTitle": "Redenumire",
        "dxFileManager-dialogRenameItemButtonText": "Salavează",
        "dxFileManager-dialogCreateDirectoryTitle": "Director nou",
        "dxFileManager-dialogCreateDirectoryButtonText": "Crează",
        "dxFileManager-dialogDeleteItemTitle": "Șterge",
        "dxFileManager-dialogDeleteItemButtonText": "Șterge",
        "dxFileManager-dialogDeleteItemSingleItemConfirmation": "Sigur doriți să ștergeți{0}?",
        "dxFileManager-dialogDeleteItemMultipleItemsConfirmation": "Sigur doriți să ștergeți  {0} elementul?",
        "dxFileManager-dialogButtonCancel": "Anulare",

        "dxFileManager-editingCreateSingleItemProcessingMessage": "Crearea unui director în interior {0}",
        "dxFileManager-editingCreateSingleItemSuccessMessage": "Crearea unui director în interior {0}",
        "dxFileManager-editingCreateSingleItemErrorMessage": "Directorul nu a fost creat",
        "dxFileManager-editingCreateCommonErrorMessage": "Directorul nu a fost creat",

        "dxFileManager-editingRenameSingleItemProcessingMessage": "Redenumirea unui element din interior {0}",
        "dxFileManager-editingRenameSingleItemSuccessMessage": "Redenumirea unui element din interior {0}",
        "dxFileManager-editingRenameSingleItemErrorMessage": "Elementul nu a fost redenumit",
        "dxFileManager-editingRenameCommonErrorMessage": "Elementul nu a fost redenumit",

        "dxFileManager-editingDeleteSingleItemProcessingMessage": "Ștergerea unui element din {0}",
        "dxFileManager-editingDeleteMultipleItemsProcessingMessage": "Ștergerea {0} elementelor din {1}",
        "dxFileManager-editingDeleteSingleItemSuccessMessage": "Ștergerea unui element din  {0}",
        "dxFileManager-editingDeleteMultipleItemsSuccessMessage": "Ștergerea {0} elementelor din {1}",
        "dxFileManager-editingDeleteSingleItemErrorMessage": "Elementul nu a fost șters",
        "dxFileManager-editingDeleteMultipleItemsErrorMessage": "{0} elementele nu a fost șterse",
        "dxFileManager-editingDeleteCommonErrorMessage": "Unele elemente nu au fost șterse",

        "dxFileManager-editingMoveSingleItemProcessingMessage": "Mutarea unui element în {0}",
        "dxFileManager-editingMoveMultipleItemsProcessingMessage": "Mutarea {0} elementului în {1}",
        "dxFileManager-editingMoveSingleItemSuccessMessage": "Mutarea unui element în {0}",
        "dxFileManager-editingMoveMultipleItemsSuccessMessage": "Mutarea  {0} elementului în  {1}",
        "dxFileManager-editingMoveSingleItemErrorMessage": "Elementul nu a fost mutat",
        "dxFileManager-editingMoveMultipleItemsErrorMessage": "{0} elementele nu a fost mutate",
        "dxFileManager-editingMoveCommonErrorMessage": "Unele elemente nu a fost mutate",

        "dxFileManager-editingCopySingleItemProcessingMessage": "Copierea unui element în {0}",
        "dxFileManager-editingCopyMultipleItemsProcessingMessage": "Copiere {0} element în {1}",
        "dxFileManager-editingCopySingleItemSuccessMessage": "Copierea unui element în {0}",
        "dxFileManager-editingCopyMultipleItemsSuccessMessage": "Copiere {0} element în {1}",
        "dxFileManager-editingCopySingleItemErrorMessage": "Elementul nu a fost copiat",
        "dxFileManager-editingCopyMultipleItemsErrorMessage": "{0} elementul nu a fost copiat",
        "dxFileManager-editingCopyCommonErrorMessage": "Unele elemente nu au fost copiate",

        "dxFileManager-editingUploadSingleItemProcessingMessage": "Încărcarea unui element în {0}",
        "dxFileManager-editingUploadMultipleItemsProcessingMessage": "Încărcarea {0} elementului în {1}",
        "dxFileManager-editingUploadSingleItemSuccessMessage": "Încărcarea unui element în {0}",
        "dxFileManager-editingUploadMultipleItemsSuccessMessage": "Încărcarea {0} elementului în {1}",
        "dxFileManager-editingUploadSingleItemErrorMessage": "Elementul nu a fost încărcat",
        "dxFileManager-editingUploadMultipleItemsErrorMessage": "{0} elementele nu au fost încărcate",
        "dxFileManager-editingUploadCanceledMessage": "Anulare",

        "dxFileManager-editingDownloadSingleItemErrorMessage": "Item was not downloaded",
        "dxFileManager-editingDownloadMultipleItemsErrorMessage": "{0} items were not downloaded",

        "dxFileManager-listDetailsColumnCaptionName": "Nume",
        "dxFileManager-listDetailsColumnCaptionDateModified": "Data modificata",
        "dxFileManager-listDetailsColumnCaptionFileSize": "Mărime fișier",

        "dxFileManager-listThumbnailsTooltipTextSize": "Mărime",
        "dxFileManager-listThumbnailsTooltipTextDateModified": "Data modificata",

        "dxFileManager-notificationProgressPanelTitle": "Progres",
        "dxFileManager-notificationProgressPanelEmptyListText": "Fără operațiuni",
        "dxFileManager-notificationProgressPanelOperationCanceled": "Anulare",

        "dxDiagram-categoryGeneral": "General",
        "dxDiagram-categoryFlowchart": "Diagrama de flux",
        "dxDiagram-categoryOrgChart": "Organigrama",
        "dxDiagram-categoryContainers": "Containere",
        "dxDiagram-categoryCustom": "Personalizat",

        "dxDiagram-commandExportToSvg": "Exportați în SVG",
        "dxDiagram-commandExportToPng": "Exportați în PNG",
        "dxDiagram-commandExportToJpg": "Exportați în JPEG",
        "dxDiagram-commandUndo": "Anula",
        "dxDiagram-commandRedo": "Reface",
        "dxDiagram-commandFontName": "Fontulul numelui",
        "dxDiagram-commandFontSize": "Marimea fontului",
        "dxDiagram-commandBold": "Bold",
        "dxDiagram-commandItalic": "Italic",
        "dxDiagram-commandUnderline": "Subliniat",
        "dxDiagram-commandTextColor": "Culoarea fontului",
        "dxDiagram-commandLineColor": "culoarea liniei",
        "dxDiagram-commandLineWidth": "Lățimea liniei",
        "dxDiagram-commandLineStyle": "stilul liniei",
        "dxDiagram-commandLineStyleSolid": "Solid",
        "dxDiagram-commandLineStyleDotted": "Punctat",
        "dxDiagram-commandLineStyleDashed": "Dashed",
        "dxDiagram-commandFillColor": "Culoare umplere",
        "dxDiagram-commandAlignLeft": "Aliniat la stânga",
        "dxDiagram-commandAlignCenter": "Aliniat la centru",
        "dxDiagram-commandAlignRight": "Aliniere la dreapta",
        "dxDiagram-commandConnectorLineType": "Tipul liniei conectorului",
        "dxDiagram-commandConnectorLineStraight": "Drept",
        "dxDiagram-commandConnectorLineOrthogonal": "Ortogonal",
        "dxDiagram-commandConnectorLineStart": "Pornire linie conector",
        "dxDiagram-commandConnectorLineEnd": "Sfârșitul liniei conectorului",
        "dxDiagram-commandConnectorLineNone": "Niciunul",
        "dxDiagram-commandConnectorLineArrow": "Săgeată",
        "dxDiagram-commandFullscreen": "Ecran complet",
        "dxDiagram-commandUnits": "Unități",
        "dxDiagram-commandPageSize": "Mărime pagină",
        "dxDiagram-commandPageOrientation": "Orientare pagină",
        "dxDiagram-commandPageOrientationLandscape": "Vedere",
        "dxDiagram-commandPageOrientationPortrait": "Portret",
        "dxDiagram-commandPageColor": "Culoare pagina",
        "dxDiagram-commandShowGrid": "Afisare grila",
        "dxDiagram-commandSnapToGrid": "Fixare la grilă",
        "dxDiagram-commandGridSize": "Dimensiune grilă",
        "dxDiagram-commandZoomLevel": "Nivel zoom",
        "dxDiagram-commandAutoZoom": "Zoom automat",
        "dxDiagram-commandFitToContent": "Se potrivește conținutului",
        "dxDiagram-commandFitToWidth": "Se potrivește lățimii",
        "dxDiagram-commandAutoZoomByContent": "Mărire automată după conținut",
        "dxDiagram-commandAutoZoomByWidth": "Mărire automată după lățime",
        "dxDiagram-commandSimpleView": "Vedere simpla",
        "dxDiagram-commandCut": "Tăiat",
        "dxDiagram-commandCopy": "Copiat",
        "dxDiagram-commandPaste": "Lipire",
        "dxDiagram-commandSelectAll": "Selectează tot",
        "dxDiagram-commandDelete": "Șterge",
        "dxDiagram-commandBringToFront": "Aduceți în față",
        "dxDiagram-commandSendToBack": "Timite înapoi",
        "dxDiagram-commandLock": "Blocare",
        "dxDiagram-commandUnlock": "Deblocare",
        "dxDiagram-commandInsertShapeImage": "Introdu imaginea...",
        "dxDiagram-commandEditShapeImage": "Mofica imaginea...",
        "dxDiagram-commandDeleteShapeImage": "Șterge imaginea",
        "dxDiagram-commandLayoutLeftToRight": "De la stânga la dreapta",
        "dxDiagram-commandLayoutRightToLeft": "De la dreapta la stânga",
        "dxDiagram-commandLayoutTopToBottom": "De sus până jos",
        "dxDiagram-commandLayoutBottomToTop": "De jos până sus",

        "dxDiagram-unitIn": "in",
        "dxDiagram-unitCm": "cm",
        "dxDiagram-unitPx": "px",

        "dxDiagram-dialogButtonOK": "OK",
        "dxDiagram-dialogButtonCancel": "Anulare",
        "dxDiagram-dialogInsertShapeImageTitle": "Introdu imaginea",
        "dxDiagram-dialogEditShapeImageTitle": "Mofica imaginea",
        "dxDiagram-dialogEditShapeImageSelectButton": "Selectează imaginea",
        "dxDiagram-dialogEditShapeImageLabelText": "sau plasați fișierul aici",

        "dxDiagram-uiExport": "Exportă",
        "dxDiagram-uiProperties": "Propertăți",
        "dxDiagram-uiSettings": "Setări",
        "dxDiagram-uiShowToolbox": "Afișați caseta de instrumente",
        "dxDiagram-uiSearch": "Caută",
        "dxDiagram-uiStyle": "Stil",
        "dxDiagram-uiLayout": "Aspect",
        "dxDiagram-uiLayoutTree": "Aspect",
        "dxDiagram-uiLayoutLayered": "Stratificat",
        "dxDiagram-uiDiagram": "Diagramă",
        "dxDiagram-uiText": "Text",
        "dxDiagram-uiObject": "Objectiect",
        "dxDiagram-uiConnector": "Conector",
        "dxDiagram-uiPage": "Pagină",

        "dxDiagram-shapeText": "Text",
        "dxDiagram-shapeRectangle": "Dreptunghi",
        "dxDiagram-shapeEllipse": "Elipsă",
        "dxDiagram-shapeCross": "Traversă",
        "dxDiagram-shapeTriangle": "Triunghi",
        "dxDiagram-shapeDiamond": "Diamant",
        "dxDiagram-shapeHeart": "Inimă",
        "dxDiagram-shapePentagon": "Pentagon",
        "dxDiagram-shapeHexagon": "Hexagon",
        "dxDiagram-shapeOctagon": "Octagon",
        "dxDiagram-shapeStar": "Start",
        "dxDiagram-shapeArrowLeft": "Săgeata stângă",
        "dxDiagram-shapeArrowUp": "Săgeata în sus",
        "dxDiagram-shapeArrowRight": "Săgeata dreaptă",
        "dxDiagram-shapeArrowDown": "Săgeata în jos",
        "dxDiagram-shapeArrowUpDown": "Săgeata sus jos",
        "dxDiagram-shapeArrowLeftRight": "Săgeata stângă dreapta",
        "dxDiagram-shapeProcess": "Proces",
        "dxDiagram-shapeDecision": "Decizie",
        "dxDiagram-shapeTerminator": "Terminator",
        "dxDiagram-shapePredefinedProcess": "Proces predefinit",
        "dxDiagram-shapeDocument": "Document",
        "dxDiagram-shapeMultipleDocuments": "Documente multiple",
        "dxDiagram-shapeManualInput": "Introducere manuală",
        "dxDiagram-shapePreparation": "Preparare",
        "dxDiagram-shapeData": "Data",
        "dxDiagram-shapeDatabase": "Baza de date",
        "dxDiagram-shapeHardDisk": "Hard Disk",
        "dxDiagram-shapeInternalStorage": "Stocare internă",
        "dxDiagram-shapePaperTape": "Banda de hartie",
        "dxDiagram-shapeManualOperation": "Operare manuale",
        "dxDiagram-shapeDelay": "Întârziere",
        "dxDiagram-shapeStoredData": "Date stocate",
        "dxDiagram-shapeDisplay": "Display",
        "dxDiagram-shapeMerge": "Îmbinați",
        "dxDiagram-shapeConnector": "Conector",
        "dxDiagram-shapeOr": "Or",
        "dxDiagram-shapeSummingJunction": "Summing Junction",
        "dxDiagram-shapeContainerDefaultText": "Recipient",
        "dxDiagram-shapeVerticalContainer": "Recipient Vertical",
        "dxDiagram-shapeHorizontalContainer": "Recipient orizontal",
        "dxDiagram-shapeCardDefaultText": "Numele persoanei",
        "dxDiagram-shapeCardWithImageOnLeft": "Card cu imagine în stânga",
        "dxDiagram-shapeCardWithImageOnTop": "Card cu imagine deasupra",
        "dxDiagram-shapeCardWithImageOnRight": "Card cu imagine în dreapta",

        "dxGantt-dialogTitle": "Titlu",
        "dxGantt-dialogStartTitle": "Start",
        "dxGantt-dialogEndTitle": "Sfârșit",
        "dxGantt-dialogProgressTitle": "Progres",
        "dxGantt-dialogResourcesTitle": "Resurse",
        "dxGantt-dialogResourceManagerTitle": "Manager de resurse",
        "dxGantt-dialogTaskDetailsTitle": "Detalii activitate",
        "dxGantt-dialogEditResourceListHint": "Editați lista de resurse",
        "dxGantt-dialogEditNoResources": "Fără resursă",
        "dxGantt-dialogButtonAdd": "Adaugă",
        "dxGantt-contextMenuNewTask": "Activitate nouă",
        "dxGantt-contextMenuNewSubtask": "Subactivitate nouă",
        "dxGantt-contextMenuDeleteTask": "Ștergeți activitatea",
        "dxGantt-contextMenuDeleteDependency": "Șterge depndență",
        "dxGantt-dialogTaskDeleteConfirmation": "Ștergerea unui activitate, de asemenea șterge toate dependențele și subtichetele sale. Sigur doriți să ștergeți aceast tichet?",
        "dxGantt-dialogDependencyDeleteConfirmation": "Sigur doriți să ștergeți dependența din tichet?",
        "dxGantt-dialogResourcesDeleteConfirmation": "Ștergerea unei resurse, de asemenea ștergea acesteia din tichetul căruia îi este atribuită această resursă. Sigur doriți să ștergeți aceste resurse? Resursă: {0}",
        "dxGantt-dialogConstraintCriticalViolationMessage": "Tichetul pe care încercați să îl mutați este legat de un al doilea tichet printr-o relație de dependență. Această modificare ar intra în conflict cu regulile de dependență. Cum doriți să continuați?",
        "dxGantt-dialogConstraintViolationMessage": "Tichetul pe care încercați să îl mutați este legat de un al doilea tichet printr-o relație de dependență. Cum doriți să continuați?",
        "dxGantt-dialogCancelOperationMessage": "Anulați operațiunea",
        "dxGantt-dialogDeleteDependencyMessage": "Ștergeți dependența",
        "dxGantt-dialogMoveTaskAndKeepDependencyMessage": "Mutați tichetul și păstrați dependența",
        "dxGantt-dialogConstraintCriticalViolationSeveralTasksMessage": "The task you are attempting to move is linked to another tasks by dependency relations. This change would conflict with dependency rules. How would you like to proceed?",
        "dxGantt-dialogConstraintViolationSeveralTasksMessage": "The task you are attempting to move is linked to another tasks by dependency relations. How would you like to proceed?",
        "dxGantt-dialogDeleteDependenciesMessage": "Delete the dependency relations",
        "dxGantt-dialogMoveTaskAndKeepDependenciesMessage": "Move the task and keep the dependencies",
        "dxGantt-undo": "Anulați",
        "dxGantt-redo": "Refați",
        "dxGantt-expandAll": "Expandați tot",
        "dxGantt-collapseAll": "Restrangeți tot",
        "dxGantt-addNewTask": "Adăugaţi o activitate nouă",
        "dxGantt-deleteSelectedTask": "Ștergeți activitatea selectată",
        "dxGantt-zoomIn": "Mărire",
        "dxGantt-zoomOut": "Micșorare",
        "dxGantt-fullScreen": "Ecran complet",
        "dxGantt-quarter": "Q{0}",
        "dxGantt-sortingAscendingText": "Sort Ascending",
        "dxGantt-sortingDescendingText": "Sort Descending",
        "dxGantt-sortingClearText": "Clear Sorting",
        "dxGantt-showResources": "Show Resources",
        "dxGantt-showDependencies": "Show Dependencies",
        "dxGantt-dialogStartDateValidation": "Start date must be after {0}",
        "dxGantt-dialogEndDateValidation": "End date must be after {0}",

        "dxGallery-itemName": "Gallery item",

        "dxMultiView-elementAriaRoleDescription": "MultiView",
        "dxMultiView-elementAriaLabel": "Use the arrow keys or swipe to navigate between views",
        "dxMultiView-itemAriaRoleDescription": "View",
        "dxMultiView-itemAriaLabel": "{0} of {1}"
    }
}
