import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AppRouteNames } from '../../../../app-route-names';
import { InvoicesService } from '../invoices.service';
import { InvoiceDto } from '../../../../../../../shared/models/invoice-dto';
import saveAs from 'file-saver';
import { AlertBoxService } from '../../../UniversalViewComponents/alert-box/alert-box.service';
import { AuthService } from '../../../../AuthComponents/auth/auth.service';
import { SimpleUserDto } from '../../../../../../../shared/models/simple-user-dto';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrl: './invoice-details.component.less',
})
export class InvoiceDetailsComponent {
  @Input() invoiceToDetails?: InvoiceDto;
  @Input() isSingleView?: boolean;
  user?: SimpleUserDto;
  isComplaintsPopup: boolean = false;

  constructor(
    private router: Router,
    public invoicesService: InvoicesService,
    private alertBoxService: AlertBoxService,
    private translate: TranslateService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.user = this.authService.getUserData();
  }

  copyUrl() {
    const linkToInvoice = this.isSingleView
      ? `${window.location.href}`
      : `${window.location.href}/${this.invoiceToDetails?.invoiceNumber}`;
    navigator.clipboard.writeText(linkToInvoice).then(() => {
      this.alertBoxService.showInfo(
        this.translate.instant('common.successCopy')
      );
    });
  }

  goToUrl() {
    this.router.navigate([
      `${AppRouteNames.PARTNER_INVOICES}/${this.invoiceToDetails?.invoiceNumber}`,
    ]);
  }

  formSent(e: any) {
    this.closePopup();
  }

  showComplaintsPopup() {
    this.isComplaintsPopup = true;
  }

  closePopup() {
    this.isComplaintsPopup = false;
  }

  goToOrders() {
    this.router.navigate([AppRouteNames.PARTNER_ORDERS], {
      queryParams: { id: this.invoiceToDetails?.invoiceNumber },
    });
  }
  downloadETransport() {
    this.invoicesService
      .downloadETransport(this.invoiceToDetails!.id)
      .subscribe({
        next: (val) => {
          saveAs(val, `ETransport_${this.invoiceToDetails!.id}`);
        },
      });
  }

  downloadInvoice() {
    this.invoicesService.downloadInvoice(this.invoiceToDetails!.id).subscribe({
      next: (val) => {
        saveAs(val, this.invoiceToDetails?.pdf);
      },
      error: (err) => {
        this.alertBoxService.showAlert(
          this.translate.instant('orders.details.invoicesPopup.downloadError')
        );
      },
    });
  }

  downloadStatement() {
    this.invoicesService
      .downloadStatement(this.invoiceToDetails!.id)
      .subscribe({
        next: (val) => {
          saveAs(val, `teljesitmeny_nyilatkozat_${this.invoiceToDetails!.id}`);
        },
        error: (err) => {
          this.alertBoxService.showAlert(
            this.translate.instant('orders.details.invoicesPopup.downloadError')
          );
        },
      });
  }
}
