import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../shared/environments/environment';
import { InvoiceSummaryDto } from '../../../../../../shared/models/invoice-summary-dto';
import { BehaviorSubject } from 'rxjs';
import { InvoiceDto } from '../../../../../../shared/models/invoice-dto';
import {TranslateService} from "@ngx-translate/core";

export interface FilterInvoiceObject {
  status: string;
  company: string;
}

@Injectable({ providedIn: 'root' })
export class InvoicesService {
  companiesList = new BehaviorSubject<string[]>([]);
  invoicesSummary = new BehaviorSubject<InvoiceSummaryDto[]>([]);
  currencies = new BehaviorSubject<string[]>([]);
  filteredCompany = new BehaviorSubject<FilterInvoiceObject>({
    status: '',
    company: '',
  });
  isSummaryVisible = new BehaviorSubject<boolean>(true);
  INVOICESTATUS_DATA:{id:string,text:string}[]=[];
  INVOICETYPE_DATA:{id:string,text:string}[]=[];
  ARCHIVESTATUS_DATA:{id:string,text:string}[]=[];

  constructor(
      private http: HttpClient,
      private translate: TranslateService,
  ) {
    this.loadTranslates();
  }

  async loadTranslates() {
    await this.translate.get("header.title").toPromise();
    this.INVOICESTATUS_DATA = [
      {id: 'UNPAID', text: this.translate.instant("invoices.status.UNPAID")},
      {id: 'PAID', text: this.translate.instant("invoices.status.PAID")},
      {id: 'EXPIRED', text: this.translate.instant("invoices.status.EXPIRED")},
    ];
    this.INVOICETYPE_DATA = [
      {id: 'PROFORMA', text: this.translate.instant("invoices.type.PROFORMA")},
      {id: 'STORNO_PROFORMA', text: this.translate.instant("invoices.type.STORNO_PROFORMA")},
      {id: 'ELOLEG_BEKERO', text: this.translate.instant("invoices.type.ELOLEG_BEKERO")},
      {id: 'ELOLEG_SZAMLA', text: this.translate.instant("invoices.type.ELOLEG_SZAMLA")},
      {id: 'SZAMLA', text: this.translate.instant("invoices.type.SZAMLA")},
      {id: 'STORNO_SZAMLA', text: this.translate.instant("invoices.type.STORNO_SZAMLA")},
      {id: 'HELYESBITO_SZAMLA', text: this.translate.instant("invoices.type.HELYESBITO_SZAMLA")},
      {id: 'VISSZARU_SZAMLA', text: this.translate.instant("invoices.type.VISSZARU_SZAMLA")}
    ];
    this.ARCHIVESTATUS_DATA = [
      {id: 'ARCHIVE', text: this.translate.instant("invoices.archive.ARCHIVE")},
      {id: 'ACTUAL', text: this.translate.instant("invoices.archive.ACTUAL")},
    ];
  }

  getStatusCellText(text: string | undefined, data: any[]): string {
    if (text == undefined) return '';
    return data.filter((i) => i.id == text)[0].text;
  }

  getInvoiceTypeCellText = (rowData: InvoiceDto | undefined) => {
    if (rowData == undefined) return '';
    return this.INVOICETYPE_DATA.filter((i) => i.id == rowData.invoiceType)[0]
      .text;
  };

  fetchInvoicesSummary() {
    this.http
      .get<InvoiceSummaryDto[]>(`${environment.apiUrl}api/invoice/summary`)
      .subscribe((invoices) => {
        this.invoicesSummary.next(invoices);
        invoices.forEach((invoice) => {
          this.companiesList.next([
            ...this.companiesList.getValue(),
            invoice.ceCompany!.name,
          ]);
        });
        invoices.forEach((invoice) => {
          invoice.currencies.forEach((price) => {
            this.currencies.next([...this.currencies.getValue(), price!]);
          });
        });
      });
  }

  getInvoiceDetails(id: string) {
    return this.http.get<InvoiceDto>(
      `${environment.apiUrl}api/invoice/details/${id}`
    );
  }

  downloadETransport(id: number) {
    return this.http.get(`${environment.apiUrl}api/invoice/download-etranspor/${id}`, {
      responseType: 'blob',
    });
  }

  downloadInvoice(id: number) {
    return this.http.get(`${environment.apiUrl}api/invoice/download/${id}`, {
      responseType: 'blob',
    });
  }

  downloadStatement(id: number) {
    return this.http.get(
      `${environment.apiUrl}api/invoice/performance-statement-pdf/${id}`,
      { responseType: 'blob' }
    );
  }
}
