import {Pipe, PipeTransform} from '@angular/core';
import {AppConstants} from "../../../../shared/app-constants";

@Pipe({
    name: 'ceNumberFormat'
})
export class CeNumberFormatPipe implements PipeTransform {

    transform(value: number | undefined, ...args: unknown[]): string {
        if (value == undefined) return "";
        return new Intl.NumberFormat(AppConstants.DEFAULT_LOCALE, {maximumFractionDigits: 2,useGrouping:true, }).format(value);
    }

}
