import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../AuthComponents/auth/auth.service';
import { AppRouteNames } from '../app-route-names';

@Injectable({ providedIn: 'root' })
class PermissionsService {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(state: RouterStateSnapshot): boolean {
    let url: string = state.url;

    return this.checkLogin(url);
  }

  checkLogin(url: string): any {
    this.authService.isLoggedIn.subscribe((val) => {
      if (val) return true;

      this.router.navigate([AppRouteNames.LOGIN], {
        queryParams: { 'return-path': url },
      });
      return false;
    });
  }
}

export const AuthGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean => {
  return inject(PermissionsService).canActivate(state);
};
