<div class="generic-block h100p">
  <div class="generic-block-header-row">
    <h3 class="card-title teal-5">
      {{ "invoices.title" | translate
      }}<span class="active-filter-title" *ngIf="isFiltered">{{
        "orders.summaryFilterIsActive" | translate
      }}</span>
    </h3>
    <div class="button-row gap-2">
      <dx-button (click)="resetFilters()" class="tertiary font-lg">
        <i class="bi bi-x-circle"></i>
        <span>{{ "common.table.resetFiltersButton" | translate }}</span>
      </dx-button>
      <dx-button
        (click)="excelExport()"
        *ngIf="isDesktop"
        class="tertiary font-lg"
      >
        <i class="bi bi-table"></i>
        <span>{{ "common.table.excelExportButton" | translate }}</span>
      </dx-button>
      <dx-button
        (click)="setFullWidth()"
        [disabled]="isFullWidth"
        *ngIf="isDesktop"
        class="tertiary font-lg tab-l-hidden"
      >
        <i class="bi bi-arrows-angle-expand"></i>
        <span>{{ "common.table.setFullWidthButton" | translate }}</span>
      </dx-button>
      <dx-button
        (click)="columnChooser.instance.showColumnChooser()"
        *ngIf="isDesktop"
        class="tertiary font-lg mob-icon-only"
      >
        <i class="bi bi-layout-sidebar-inset"></i>
        <span>{{ "common.table.columnChooserButton" | translate }}</span>
      </dx-button>
    </div>
  </div>
  <dx-data-grid
    #invoicesListGrid
    id="invoicesListGrid"
    [dataSource]="invoicesList"
    (onFocusedRowChanged)="checkInvoice($event)"
    [customizeColumns]="dataService.defaultColumns"
  >
    <dxo-column-chooser #columnChooser [enabled]="true" mode="select">
      <dxo-selection [allowSelectAll]="false" [selectByClick]="true" />
      <dxo-position />
    </dxo-column-chooser>
    <dxo-paging [pageSize]="dataService.PAGE_SIZE" />
    <dxo-state-storing
      [enabled]="true"
      type="custom"
      [customSave]="stateSave"
    />
    <dxi-column
      #invoiceStatus
      dataField="invoiceStatusSimple"
      dataType="string"
      [caption]="'invoices.column.status' | translate"
      [visible]="true"
      cellTemplate="invoiceStatusTemplate"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="!isDesktop ? '80' : '105'"
    >
      <dxo-lookup
        [allowClearing]="true"
        [dataSource]="invoiceService.INVOICESTATUS_DATA"
        displayExpr="text"
        valueExpr="id"
      ></dxo-lookup>
    </dxi-column>
    <dxi-column
      dataField="invoiceNumber"
      dataType="string"
      [caption]="'invoices.column.invoiceNumber' | translate"
      [visible]="true"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="125"
    ></dxi-column>
    <dxi-column
      #companyName
      dataField="ceCompanyName"
      dataType="string"
      [caption]="'invoices.column.ceCompanyName' | translate"
      [visible]="isDesktop"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
    >
      <dxo-lookup
        [allowClearing]="true"
        [dataSource]="companiesList"
      ></dxo-lookup
    ></dxi-column>
    <dxi-column
      dataField="issueDate"
      dataType="date"
      [caption]="'invoices.column.issueDate' | translate"
      [visible]="isDesktop"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="125"
    ></dxi-column>
    <dxi-column
      dataField="completionDate"
      dataType="date"
      [caption]="'invoices.column.completionDate' | translate"
      [visible]="isDesktop"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="125"
    ></dxi-column>
    <dxi-column
      dataField="dueDate"
      dataType="date"
      [caption]="'invoices.column.dueDate' | translate"
      [visible]="true"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="125"
    ></dxi-column>
    <dxi-column
      dataField="netPrice"
      dataType="number"
      [caption]="'invoices.column.netPrice' | translate"
      alignment="left"
      [visible]="isDesktop"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="100"
    ></dxi-column>
    <dxi-column
      dataField="vatAmount"
      dataType="number"
      [caption]="'invoices.column.vatValue' | translate"
      alignment="left"
      [visible]="isDesktop"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="100"
    ></dxi-column>
    <dxi-column
      dataField="grossPrice"
      dataType="number"
      [caption]="'invoices.column.grossPrice' | translate"
      alignment="left"
      [visible]="isDesktop"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="100"
      ><dxo-format type="fixedPoint"></dxo-format>
    </dxi-column>
    <dxi-column
      #payableAmount
      dataField="balance"
      dataType="number"
      [caption]="'invoices.column.balance' | translate"
      alignment="left"
      [visible]="true"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="100"
    ></dxi-column>
    <dxi-column
      dataField="currency"
      dataType="string"
      [caption]="'invoices.column.currency' | translate"
      caption="Deviza"
      [visible]="true"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="80"
    >
      <dxo-lookup [allowClearing]="true" [dataSource]="currencies"></dxo-lookup>
    </dxi-column>
    <dxi-column
      dataField="numberOfDownloads"
      dataType="number"
      [caption]="'invoices.column.numberOfDownloads' | translate"
      alignment="left"
      [visible]="isDesktop"
      cellTemplate="downloadNumbersTemplate"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="80"
    ></dxi-column>
    <dxi-column
      #archiveStatus
      dataField="archiveStatus"
      dataType="string"
      [caption]="'invoices.column.archiveStatus' | translate"
      [visible]="isDesktop"
      cellTemplate="archiveStatusTemplate"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="100"
    >
      <dxo-lookup
        [allowClearing]="true"
        [dataSource]="invoiceService.ARCHIVESTATUS_DATA"
        displayExpr="text"
        valueExpr="id"
      ></dxo-lookup>
    </dxi-column>
    <dxi-column
      dataField="invoiceType"
      dataType="string"
      [caption]="'invoices.column.invoiceType' | translate"
      [visible]="isDesktop"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="100"
    >
      <dxo-lookup
        [allowClearing]="true"
        [dataSource]="invoiceService.INVOICETYPE_DATA"
        displayExpr="text"
        valueExpr="id"
      ></dxo-lookup>
    </dxi-column>
    <dxi-column
      dataField="invoiceReferenceTarget"
      dataType="string"
      [caption]="'invoices.column.invoiceReferenceTarget' | translate"
      [visible]="isDesktop"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="150"
    ></dxi-column>
    <dxi-column
      dataField="firstDownloadDate"
      dataType="date"
      [caption]="'invoices.column.firstDownloadDate' | translate"
      alignment="left"
      [visible]="false"
      (filterValueChange)="setFullWidth()"
      (sortOrderChange)="setFullWidth()"
      [width]="100"
    ></dxi-column>
    <div
      class="invoice-status-cell"
      *dxTemplate="let d of 'invoiceStatusTemplate'"
    >
      <i class="bi bi-check-circle" *ngIf="d.value == 'PAID'"></i>
      <i class="bi bi-info-circle" *ngIf="d.value == 'UNPAID'"></i>
      <i class="bi bi-exclamation-circle" *ngIf="d.value == 'EXPIRED'"></i>
      <span *ngIf="isDesktop">
        {{
          invoiceService.getStatusCellText(
            d.value,
            invoiceService.INVOICESTATUS_DATA
          )
        }}</span
      >
    </div>
    <div
      class="invoice-status-cell"
      *dxTemplate="let d of 'archiveStatusTemplate'"
    >
      <span>{{
        invoiceService.getStatusCellText(
          d.value,
          invoiceService.ARCHIVESTATUS_DATA
        )
      }}</span>
    </div>
    <div
      class="download-numbers-cell"
      *dxTemplate="let d of 'downloadNumbersTemplate'"
    >
      <i class="bi bi-info-circle" *ngIf="d.value < 1"></i>
      <i class="bi bi-check-circle" *ngIf="d.value > 0"></i>
      <span>{{ d.value }}</span>
    </div>
  </dx-data-grid>
</div>
