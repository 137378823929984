import { Component } from '@angular/core';
import { DashboardService } from '../dashboard/dashboard.service';
import { SalesmanDto } from '../../../../../../shared/models/salesman-dto';

@Component({
  selector: 'app-connections',
  templateUrl: './connections.component.html',
  styleUrl: './connections.component.less',
})
export class ConnectionsComponent {
  salesmanProfile: SalesmanDto = <SalesmanDto>{};
  constructor(private dashboardService: DashboardService) {}

  ngOnInit(): void {
    this.dashboardService.getPartnerSalesman().subscribe({
      next: (salesman) => {
        this.salesmanProfile = salesman;
      },
    });
  }
}
