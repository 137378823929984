import { Component, ViewChild } from '@angular/core';
import { InvoiceDto } from '../../../../../../shared/models/invoice-dto';
import { InvoicesService } from './invoices.service';
import { InvoicesListComponent } from './invoices-list/invoices-list.component';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrl: './invoices.component.less',
})
export class InvoicesComponent {
  @ViewChild('invoiceList') invoiceList?: InvoicesListComponent;
  invoiceToDetails?: InvoiceDto;
  constructor(private invoiceService: InvoicesService) {}

  ngOnInit(): void {
    this.invoiceService.fetchInvoicesSummary();
  }

  setListFullWidth(e: any) {
    this.invoiceList?.setFullWidth();
  }

  getInvoiceToDetails(invoice: InvoiceDto) {
    this.invoiceToDetails = invoice;
  }
}
