import { Injectable } from '@angular/core';
import { QuestionnaireResultDto } from '../../../../../../shared/models/questionnaire-result-dto';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../../shared/environments/environment';

@Injectable({ providedIn: 'root' })
export class QuestionnaireService {
  constructor(private http: HttpClient) {}

  sendQuestionnaireResult(result: QuestionnaireResultDto) {
    return this.http.post(
      `${environment.apiUrl}api/questionnaire/send-result`,
      result
    );
  }
}
