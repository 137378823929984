import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AlertBoxService {
  alertSubject = new Subject<string>();
  infoSubject = new Subject<string>();

  showAlert(message: string) {
    this.alertSubject.next(message);
  }

  showInfo(message: string) {
    this.infoSubject.next(message);
  }
}
