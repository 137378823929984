import { Component } from '@angular/core';

@Component({
  selector: 'app-complaints-view',
  templateUrl: './complaints-view.component.html',
  styleUrl: './complaints-view.component.less'
})
export class ComplaintsViewComponent {

}
