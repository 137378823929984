import { Component, ViewChild } from '@angular/core';
import { AuthService } from '../../../AuthComponents/auth/auth.service';
import { DashboardService } from './dashboard.service';
import { OrderDashboardDto } from '../../../../../../shared/models/order-dashboard-dto';
import { InvoiceDashboardDto } from '../../../../../../shared/models/invoice-dashboard-dto';
import { StandDashboardDto } from '../../../../../../shared/models/stand-dashboard-dto';
import { NewsletterSubscriptionDto } from '../../../../../../shared/models/newsletter-subscription-dto';
import {
  AppConstants,
  STATUS_ICON,
} from '../../../../../../shared/app-constants';
import { AppRouteNames } from '../../../app-route-names';
import { AlertBoxService } from '../../UniversalViewComponents/alert-box/alert-box.service';
import { DxFormComponent } from 'devextreme-angular';
import { DataService } from '../../../data.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.less',
})
export class DashboardComponent {
  canReadOrders: boolean;
  canReadInvoices: boolean;
  canReadStands: boolean;
  canSubNewsletter: boolean;
  alreadySub: Boolean = false;
  orders?: OrderDashboardDto;
  orderStatus?: STATUS_ICON = STATUS_ICON.OK;
  invoices?: InvoiceDashboardDto;
  invoiceStatus?: STATUS_ICON;
  stands?: StandDashboardDto;
  standsStatus?: STATUS_ICON;
  newsletterFormData: NewsletterSubscriptionDto = <NewsletterSubscriptionDto>{};
  currentLang = '';
  newsletterError: boolean = false;
  companyName?: string = '';
  isPopupOpen: boolean = false;
  sendInterestedData: { message: string } = <{ message: string }>{};
  @ViewChild('sendInterestedForm') sendInterestedForm?: DxFormComponent;
  @ViewChild('newsletterForm') newsletterForm?: DxFormComponent;
  protected readonly AppRouteNames = AppRouteNames;

  fileSize: string = '0';

  orderData: {
    type: 'INTERESTED';
    subject: string;
    message: string;
    deliveryAddress: string;
    attachments?: File[];
  } = {
    type: 'INTERESTED',
    subject: '',
    message: '',
    deliveryAddress: '',
  };

  constructor(
    private authService: AuthService,
    private dashboardService: DashboardService,
    public dataService: DataService,
    private translate: TranslateService,
    private alertBoxService: AlertBoxService
  ) {
    this.canReadOrders = this.authService.partnerCanGetOrders();
    this.canReadInvoices = this.authService.partnerCanGetInvoices();
    this.canReadStands = this.authService.partnerCanGetStands();
    this.canSubNewsletter = this.authService.partnerCanSubNewsletter();
    this.currentLang = this.authService.getUserData().language;
  }

  ngOnInit(): void {
    if (this.canReadOrders) {
      this.dashboardService.getOrdersDashboard().subscribe((orders) => {
        this.orders = orders;
      });
    }

    if (this.canReadInvoices) {
      this.dashboardService.getInvoicesDashboard().subscribe((invoices) => {
        this.invoices = invoices;
        if (invoices.overdueAmount.count! > 0) {
          this.invoiceStatus = STATUS_ICON.ERROR;
        } else if (invoices.pendingPayment > 0) {
          this.invoiceStatus = STATUS_ICON.WARNING;
        } else {
          this.invoiceStatus = STATUS_ICON.OK;
        }
      });
    }

    if (this.canReadStands) {
      this.dashboardService.getStandsDashboard().subscribe((stands) => {
        this.stands = stands;
        this.standsStatus = STATUS_ICON.OK;
        if (stands.outsideStandCount > 0) {
          this.standsStatus = STATUS_ICON.WARNING;
        }
        if (stands.overdueOutsideStandCount > 0) {
          this.standsStatus = STATUS_ICON.ERROR;
        }
      });
    }

    if (this.canSubNewsletter) {
      this.dashboardService.isSubscribed().subscribe((isSub) => {
        this.alreadySub = isSub;
      });
    }

    this.authService.fetchPartnerProfile().subscribe({
      next: (partner) => {
        this.companyName = partner.name;
      },
    });
  }

  onSubmit() {
    if (!this.newsletterForm?.instance.validate().isValid) {
      return;
    }
    this.dashboardService
      .subscribeToNewsletter(this.newsletterFormData)
      .subscribe({
        next: () => {
          this.alreadySub = true;
        },
        error: () => {
          this.newsletterError = true;
          setTimeout(() => {
            this.newsletterError = false;
          }, 4000);
        },
      });
  }

  onInterestedSubmit() {
    if (!this.sendInterestedForm?.instance.validate().isValid) {
      return;
    }
    this.dashboardService
      .sendInterest(this.sendInterestedData.message)
      .subscribe({
        next: () => {
          this.alertBoxService.showInfo(
            this.translate.instant(
              'dashboard.priceLists.interestedPopup.successMessage'
            )
          );
          this.isPopupOpen = false;
          this.sendInterestedForm?.instance.reset();
        },
        error: (error) => {
          this.alertBoxService.showAlert(error.message);
        },
      });
  }

  togglePopup() {
    this.isPopupOpen = !this.isPopupOpen;
  }

  openPdf(name: string) {
    window.open(`/assets/sales_pdf/${name}_${this.currentLang}.pdf`, '_blank');
  }

  showInfo() {
    this.alertBoxService.showInfo(
      this.translate.instant('dashboard.priceLists.noSalePopupMessage')
    );
  }

  validateFiles = (e: any) => {
    let validator = this.dataService.validateFiles(e);
    this.fileSize = validator.fileSize;
    return validator.valid;
  };
  protected readonly AppConstants = AppConstants;
}
