<div class="invoices-summary-card ">
  <div class="summary-card-title m-bottom-1">
    <h3 class="card-title white no-padding shrink">
      <span [className]="invoiceStatus"></span>
      {{ invoicesSummary?.ceCompany?.name }}
    </h3>
    <dx-button
     class="tertiary font-lg lighter" (click)="filterBy('')">
     <i class="bi bi-filter"></i>
    <span>
      {{ "invoices.summary.filterButton" |translate }}
    </span>
    </dx-button>
  </div>
  <div class="generic-data-group col-3 in-highlight-block">
      <div class="generic-data-block">
      
        <p class="generic-data-block-title white">{{ "invoices.summary.nextOverdueDate" |translate }}</p>
        <p class="generic-data-block-value white">{{ invoicesSummary?.nextOverdueDate || "" }}</p>
      
      </div>
      <div class="generic-data-block has-icon-button">
        <p class="generic-data-block-title white">
            {{ "invoices.summary.expiredInvoices" |translate }}
        </p>
        <dx-button
          (click)="filterBy('EXPIRED')"
          class="tertiary lighter font-lg icon-only"
          >
            <i
            class="bi bi-filter"
          ></i>
          </dx-button>
        <p class="generic-data-block-value white">{{ invoicesSummary?.overdueInvoices?.count! | ceNumberFormat }}</p>
      </div>
      <div class="generic-data-block has-icon-button">
        <p class="generic-data-block-title white">
            {{ "invoices.summary.payableInvoicesCount" |translate }}
        </p>
        <dx-button
          (click)="filterBy('UNPAID')"
          class="tertiary lighter font-lg icon-only"
          >
            <i
            class="bi bi-filter"
          ></i>
          </dx-button>
        <p class="generic-data-block-value white">{{ invoicesSummary?.payableInvoices?.count! | ceNumberFormat }}</p>
      </div>
      <div class="generic-data-block">
        <p class="generic-data-block-title white">{{ "invoices.summary.nextOverduePrice" |translate }}</p>
        @if (invoicesSummary?.nextOverdueValue?.price) {
          <p class="generic-data-block-value white">
            {{ invoicesSummary?.nextOverdueValue?.price | ceNumberFormat }}
            {{ invoicesSummary?.currencies }}
          </p>
          } @else {
          <p class="generic-data-block-value white"></p>
          }
      </div>
      <div class="generic-data-block">
        <p class="generic-data-block-title white">{{ "invoices.summary.overdueInvoicesPrices" |translate }}</p>
        @if (invoicesSummary?.overdueInvoices!.prices.length > 0) {
        <p *ngFor="let value of invoicesSummary?.overdueInvoices?.prices" class="generic-data-block-value white">
          {{ value.price! | ceNumberFormat }} {{ value.currency }}
        </p>
        } @else {
        <p class="generic-data-block-value white"></p>
        }
      </div>
      <div class="generic-data-block">
        <p class="generic-data-block-title white">{{ "invoices.summary.payableInvoicesPrices" |translate }}</p>
        @if (invoicesSummary!.payableInvoices!.prices!.length > 0) {
        <p *ngFor="let value of invoicesSummary?.payableInvoices?.prices" class="generic-data-block-value white">
          {{ value.price! | ceNumberFormat }} {{ value.currency }}
        </p>
        } @else {
        <p class="generic-data-block-value white"></p>
        }
      </div>
  </div>
</div>
