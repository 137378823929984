<div class="breadcrumb-actions-row container-width">
  <app-breadcrumb class="breadcrumb-group"></app-breadcrumb>
</div>
<div class="single-invoice single-col-width m-bottom-2">
  <app-spinner *ngIf="!isLoaded"></app-spinner>
  <app-invoice-details
    *ngIf="isLoaded"
    [isSingleView]="true"
    [invoiceToDetails]="singleInvoice"
  ></app-invoice-details>
</div>
