import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { DxiItemComponent } from 'devextreme-angular/ui/nested';
import { ComplaintsService } from '../complaints.service';
import { ComplainDto } from '../../../../../../../shared/models/complain-dto';
import { AppConstants } from '../../../../../../../shared/app-constants';
import { DxButtonComponent, DxFormComponent } from 'devextreme-angular';
import { AlertBoxService } from '../../../UniversalViewComponents/alert-box/alert-box.service';
import {TranslateService} from "@ngx-translate/core";
import {DataService} from "../../../../data.service";

@Component({
  selector: 'app-complaints',
  templateUrl: './complaints.component.html',
  styleUrl: './complaints.component.less',
})
export class ComplaintsComponent {
  @Input() complaintData: {
    type: 'ORDER' | 'INVOICE' | 'STAND' | 'OTHER';
    id?: string;
    text?: string;
    attachments?: File[];
  } = {
    type: 'OTHER',
    id: '',
  };
  @ViewChild('complaintForm') complaintForm?: DxFormComponent;
  @ViewChild('dataType') dataType?: DxiItemComponent;
  @ViewChild('complaintType') complaintType?: DxiItemComponent;
  @ViewChild('submitComplaintButton') submitComplaintButton?: DxButtonComponent;
  COMPLAINT_TYPES?: {
    name: any;
    id: 'ORDER' | 'INVOICE' | 'STAND' | 'OTHER';
  }[];
  fileSize: string = '0';
  nameId: string = '';
  showId: boolean = true;
  isDisabled: boolean = true;
  isLoaded: boolean = true;
  @Output() formSent: EventEmitter<any> = new EventEmitter();

  constructor(
    private complaintsService: ComplaintsService,
    private translate: TranslateService,
    public dataService: DataService,
    private alertBoxService: AlertBoxService
  ) {}

  ngOnInit(): void {
    this.isDisabled = this.complaintData.type !== 'OTHER';
    this.loadComplaintTypeTranslate();
    this.onFieldDataChanged()
  }

  async loadComplaintTypeTranslate(){
    await this.translate.get("complaints.type.order").toPromise();
    this.COMPLAINT_TYPES=[
      { name: this.translate.instant("complaints.type.order"), id: 'ORDER' },
      { name: this.translate.instant("complaints.type.invoice"), id: 'INVOICE' },
      { name: this.translate.instant("complaints.type.stand"), id: 'STAND' },
      { name: this.translate.instant("complaints.type.other"), id: 'OTHER' },
    ]
  }

  onFieldDataChanged() {
    this.changeIdVisibility();
    this.nameId = `${this.translate.instant("complaints.type."+this.complaintData.type.toLowerCase())} azonosító`;
  }

  changeIdVisibility() {
    this.showId = this.complaintData.type !== 'OTHER';
  }

  onSubmit() {
    if (this.complaintForm?.instance.validate().isValid) {
      const dto: ComplainDto = {
        complaintType: this.complaintData.type,
        extraId: this.complaintData.id,
        message: this.complaintData.text!,
      };
      this.submitComplaintButton!.disabled = true;
      this.isLoaded = false;
      this.complaintsService
        .sendComplaint(dto, this.complaintData.attachments)
        .subscribe({
          next: () => {
            this.alertBoxService.showInfo(this.translate.instant("complaints.successMessage"));
            this.submitComplaintButton!.disabled = false;
            this.isLoaded = true;
            this.formSent.emit();
          },
          error: (error) => {
            this.alertBoxService.showAlert(error.message);
            this.isLoaded = true;
          },
        });
    }
  }
  validateFiles = (e: any) => {
    let validator=this.dataService.validateFiles(e);
    this.fileSize=validator.fileSize;
    return validator.valid;
  }

  protected readonly AppConstants = AppConstants;
}
