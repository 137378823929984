import { Component, ViewChild } from '@angular/core';
import { StandMoveDto } from '../../../../../../shared/models/stand-move-dto';
import { StandsListComponent } from './stands-list/stands-list.component';

@Component({
  selector: 'app-stands',
  templateUrl: './stands.component.html',
  styleUrl: './stands.component.less',
})
export class StandsComponent {
  @ViewChild('standsList') standsList?: StandsListComponent;
  standToDetails?: StandMoveDto;
  isLoaded: boolean = true;

  constructor() {}

  getStandToDetails(stand: StandMoveDto) {
    this.standToDetails = stand;
  }

  setListFullWidth(e: any) {
    this.standsList?.setFullWidth();
  }
}
