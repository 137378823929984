import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { OrderDetailsDto } from '../../../../../../../shared/models/order-details-dto';
import { AppConstants } from '../../../../../../../shared/app-constants';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../../../data.service';
import { OrdersService } from '../orders.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRouteNames } from '../../../../app-route-names';
import { DxiColumnComponent } from 'devextreme-angular/ui/nested';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrl: './orders-list.component.less',
})
export class OrdersListComponent {
  @ViewChild('ordersListGrid') ordersListGrid?: DxDataGridComponent;
  @ViewChild('simpleStatus') simpleStatus?: DxiColumnComponent;
  @ViewChild('orderStatus') orderStatus?: DxiColumnComponent;
  @ViewChild('invoices') invoices?: DxiColumnComponent;
  @Output() orderToDetails = new EventEmitter<OrderDetailsDto>();
  ordersList = {} as CustomStore;
  isDesktop: boolean = false;
  isFullWidth: boolean = true;
  private readonly TYPE = 'order/list';
  private readonly LIST_ID = 'id';
  isFiltered: boolean = false;
  readonly AppConstants = AppConstants;

  constructor(
    public ordersService: OrdersService,
    public http: HttpClient,
    public dataService: DataService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService
  ) {
    this.loadList();
  }

  ngOnInit(): void {
    this.isDesktop = this.dataService.isDesktop();
    this.ordersService.filteredOrders.subscribe((filter) => {
      this.setFilter(filter);
    });
  }

  ngAfterViewInit(): void {
    this.dataService.defaultGrid(this.ordersListGrid!, this.TYPE);
    this.activatedRoute.queryParamMap.subscribe((params) => {
      if (params.has('id')) {
        this.invoices!.filterValue = params.get('id');
      }
    });
    this.simpleStatus!.filterValue = 'ACTUAL';
  }

  loadList() {
    this.ordersList = this.dataService.fetchCustomStore(
      this.http,
      this.TYPE,
      this.LIST_ID
    );
  }

  setFilter(status: { status: string }) {
    if (status.status === 'ACTUAL') {
      this.ordersListGrid!.filterValue = [
        ['status', '=', 'IN_PROGRESS'],
        'or',
        ['status', '=', 'CONFIRMED'],
        'or',
        ['status', '=', 'UNDER_DELIVERY'],
      ];
      this.orderStatus!.cssClass = 'disable-filter';
    }
    if (status.status === 'CLOSED') {
      this.ordersListGrid!.filterValue = [['status', '=', 'COMPLETED']];
    }
    this.isFiltered = true;
  }

  getStatusText(id: string) {
    return this.ordersService.ORDERSTATUS_DATA.find(
      (status) => status.id === id
    )?.text;
  }

  getSimpleStatusText(id: string) {
    return this.ordersService.SIMPLE_ORDERSTATUS_DATA.find(
      (status) => status.id === id
    )?.text;
  }

  setFullWidth() {
    this.orderToDetails.emit(undefined);
    this.ordersListGrid!.focusedRowIndex = -1;
    this.isFullWidth = true;
  }
  excelExport() {
    this.dataService.exportToExcel(
      this.ordersListGrid?.instance,
      this.translate.instant('orders.fileName')
    );
  }

  resetFilters() {
    this.ordersListGrid?.instance.beginUpdate();

    this.ordersListGrid?.instance.clearFilter();
    this.ordersListGrid?.instance.clearSelection();
    this.ordersListGrid?.instance.clearGrouping();
    this.ordersListGrid?.instance.clearSorting();

    this.ordersListGrid?.instance.endUpdate();

    this.orderStatus!.cssClass = '';

    if (this.ordersListGrid?.filterValue)
      this.ordersListGrid!.filterValue = undefined;

    this.isFiltered = false;
  }

  checkOrder(event: any) {
    if (event.row) {
      this.ordersService
        .fetchOrderDetails(event.row.data.confirmationName)
        .subscribe({
          next: (order) => {
            if (this.dataService.isMobile()) {
              this.router.navigate([
                `${AppRouteNames.PARTNER_ORDERS}/${order.order.confirmationName}`,
              ]);
              return;
            }
            this.orderToDetails.emit(order);
            this.ordersService.isSummaryVisible.next(false);
            this.isFullWidth = false;
          },
        });
    }
  }
  stateSave = (state: any) => {
    this.dataService.stateStoringSave(this.TYPE, state);
  };

  protected readonly DataService = DataService;
}
