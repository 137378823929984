import { Component, ViewChild } from '@angular/core';
import { AuthService } from '../../AuthComponents/auth/auth.service';
import { PartnerDetailsDto } from '../../../../../shared/models/partner-details-dto';
import { AppConstants } from '../../../../../shared/app-constants';
import { DataService } from '../../data.service';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
  selector: 'app-salesman-view',
  templateUrl: './salesman-view.component.html',
  styleUrl: './salesman-view.component.less',
})
export class SalesmanViewComponent {
  @ViewChild('partnersGrid') partnersGrid?: DxDataGridComponent;
  partnerList: any = [];
  informationPage: boolean;
  partnerData: PartnerDetailsDto = <PartnerDetailsDto>{};
  allowedPageSizes = [10, 25, 50, 100];
  defaultPageSize = 25;

  constructor(
    private authService: AuthService,
    public dataService: DataService
  ) {
    this.informationPage = this.authService.salesCanSeePartnerInformationPage();
  }

  ngOnInit(): void {
    this.authService.getPartnerList().subscribe((list) => {
      this.partnerList = list;
    });
  }

  ngAfterViewInit(): void {
    this.dataService.defaultGrid(this.partnersGrid!, 'partners');
  }

  startWorkflow = (event: any, e: any) => {
    if (
      confirm(
        'Amennyiben elindítja ezt a munkamenetet, akkor csak az abból való kijelentkezést követően tud újra belépni a mostani felhasználójába!'
      )
    ) {
      this.authService.fetchPartnerToken(e.row.data.username);
    } else {
      return;
    }
  };
  authParamTranslate = (value: string) => {
    return AppConstants.ALL_AUTH_PARAMS.find((x) => x.id == value)?.text;
  };
  onFocusedRowChanged = (e: any) => {
    if (e.row) {
      this.authService.fetchPartnerDetails(e.row.data.username).subscribe({
        next: (partnerDetails) => {
          this.partnerData = partnerDetails;
        },
      });
    }
  };
  protected readonly AppConstants = AppConstants;
}
