<div class="generic-block generic-block-highlight m-bottom-1">
  <div class="generic-block-header-row no-padding">
    <h3 class="card-title white">{{ "stands.summary.title" |translate }}</h3>
    <div class="button-row gap-2">
      @if (isSummaryVisible) {
      <dx-button (click)="toggleSummary()" class="tertiary font-lg lighter"
        ><i class="bi bi-arrows-angle-contract"></i
        ><span>{{ "common.summary.close" |translate }}</span></dx-button
      >
      } @else {
      <dx-button (click)="toggleSummary()" class="tertiary font-lg lighter"
        ><i class="bi bi-arrows-angle-expand"></i
        ><span>{{ "common.summary.open" |translate }}</span></dx-button
      >
      }
    </div>
  </div>
  <div
    style="
      background-color: rgba(255, 255, 255, 0.1);
      padding: 1rem;
      border-radius: 0.5rem;
    "
    class="m-top-1-5"
    *ngIf="isSummaryVisible"
  >
    <div
      class="del-orders-summary-data generic-data-group in-highlight-block col-6"
      [ngClass]="{ 'mobile-view': isMobile }"
    >
      <div class="generic-data-block">
        <p class="generic-data-block-title white">
            {{ "stands.summary.outsideStandCount" |translate }}
        </p>
        <p class="generic-data-block-value white">{{ summaryDto?.outsideStandCount }}</p>
      </div>
      <div class="generic-data-block">
        <p class="generic-data-block-title white">
            {{ "stands.summary.firstDeadline" |translate }}
        </p>
        <p class="generic-data-block-value white">{{ summaryDto?.firstDeadline| ceDateFormat }}</p>
      </div>
      <div class="generic-data-block">
        <p class="generic-data-block-title white">
            {{ "stands.summary.returnStandIndicatedCount" |translate }}
        </p>
        <p class="generic-data-block-value white">{{ summaryDto?.returnStandIndicatedCount }}</p>
      </div>
      <div class="generic-data-block">
        <p class="generic-data-block-title white">
            {{ "stands.summary.nextReturnStandIndicatedDate" |translate }}
        </p>
        <p class="generic-data-block-value white">{{ summaryDto?.nextReturnStandIndicatedDate| ceDateFormat }}</p>
      </div>
    </div>
  </div>
</div>
