import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { FilterInvoiceObject, InvoicesService } from '../invoices.service';
import { HttpClient } from '@angular/common/http';
import { DxDataGridComponent } from 'devextreme-angular';
import { InvoiceDto } from '../../../../../../../shared/models/invoice-dto';
import { AppConstants } from '../../../../../../../shared/app-constants';
import { DxiDataGridColumn } from 'devextreme-angular/ui/nested/base/data-grid-column-dxi';
import { DataService } from '../../../../data.service';
import { Router } from '@angular/router';
import { AppRouteNames } from '../../../../app-route-names';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-invoices-list',
  templateUrl: './invoices-list.component.html',
  styleUrl: './invoices-list.component.less',
})
export class InvoicesListComponent {
  @ViewChild('invoicesListGrid') invoicesListGrid?: DxDataGridComponent;
  @ViewChild('companyName') companyName?: DxiDataGridColumn;
  @ViewChild('invoiceStatus') invoiceStatus?: DxiDataGridColumn;
  @ViewChild('archiveStatus') archiveStatus?: DxiDataGridColumn;
  @Output()
  invoiceToDetails = new EventEmitter<InvoiceDto>();
  invoicesList = {} as CustomStore;
  isDesktop: boolean = false;
  isFullWidth: boolean = true;
  companiesList: string[] = [];
  currencies: string[] = [];
  private readonly TYPE = 'invoice/list';
  private readonly LIST_ID = 'id';
  isFiltered: boolean = false;

  constructor(
    public invoiceService: InvoicesService,
    private http: HttpClient,
    public dataService: DataService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.invoicesList = dataService.fetchCustomStore(
      http,
      this.TYPE,
      this.LIST_ID
    );
  }

  ngOnInit(): void {
    this.invoiceService.companiesList.subscribe((list) => {
      this.companiesList = [...new Set(list)];
    });
    this.invoiceService.currencies.subscribe((currencies) => {
      this.currencies = [...new Set(currencies)];
    });
    this.invoiceService.filteredCompany.subscribe((filter) => {
      this.setFilter(filter);
    });
    this.isDesktop = this.dataService.isDesktop();
  }

  ngAfterViewInit(): void {
    this.dataService.defaultGrid(this.invoicesListGrid!, this.TYPE);
    this.archiveStatus!.filterValue = 'ACTUAL';
  }

  setFilter(filterValue: FilterInvoiceObject) {
    if (filterValue.status === '' && filterValue.company === '') return;
    this.resetFilters();
    if (filterValue.status == '') {
      this.companyName!.filterValue = filterValue.company;
    }
    if (filterValue.status == 'UNPAID') {
      this.invoicesListGrid!.filterValue = [
        ['invoiceStatusSimple', '=', 'EXPIRED'],
        'or',
        ['invoiceStatusSimple', '=', 'UNPAID'],
      ];
      this.companyName!.filterValue = filterValue.company;
      this.invoiceStatus!.cssClass = 'disable-filter';
    }
    if (filterValue.status == 'EXPIRED') {
      this.invoiceStatus!.filterValue = 'EXPIRED';
      this.companyName!.filterValue = filterValue.company;
    }
    this.isFiltered = true;
    const element = document.getElementById('invoiceList');
    element!.scrollIntoView();
  }
  setFullWidth() {
    this.invoiceToDetails.emit(undefined);
    this.invoicesListGrid!.focusedRowIndex = -1;
    this.isFullWidth = true;
  }
  excelExport() {
    this.dataService.exportToExcel(
      this.invoicesListGrid?.instance,
      this.translate.instant('invoices.fileName')
    );
  }
  resetFilters() {
    this.invoicesListGrid?.instance.beginUpdate();

    this.invoicesListGrid?.instance.clearFilter();
    this.invoicesListGrid?.instance.clearSelection();
    this.invoicesListGrid?.instance.clearGrouping();
    this.invoicesListGrid?.instance.clearSorting();

    if (this.invoicesListGrid?.filterValue)
      this.invoicesListGrid!.filterValue = undefined;
    this.invoiceStatus!.filterValue = undefined;
    this.companyName!.filterValue = undefined;

    this.invoicesListGrid?.instance.endUpdate();

    this.invoiceStatus!.cssClass = '';
    this.isFiltered = false;
  }

  checkInvoice(event: any) {
    if (event.row) {
      this.invoiceService
        .getInvoiceDetails(event.row.data.invoiceNumber)
        .subscribe({
          next: (invoice) => {
            if (this.dataService.isMobile()) {
              this.router.navigate([
                `${AppRouteNames.PARTNER_INVOICES}/${invoice.invoiceNumber}`,
              ]);
              return;
            }
            this.invoiceToDetails.emit(invoice);
            this.invoiceService.isSummaryVisible.next(false);
            this.isFullWidth = false;
          },
        });
    }
  }

  stateSave = (state: any) => {
    this.dataService.stateStoringSave(this.TYPE, state);
  };

  protected readonly AppConstants = AppConstants;
}
