import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../../shared/environments/environment';
import { PartnerDto } from '../../../../../shared/models/partner-dto';
import { SimpleUserDto } from '../../../../../shared/models/simple-user-dto';
import { PartnerDetailsDto } from '../../../../../shared/models/partner-details-dto';
import { UserDto } from '../../../../../shared/models/user-dto';
import { LoginDto } from '../../../../../shared/models/login-dto';
import { AppRouteNames } from '../../app-route-names';
import { TokenDto } from '../../../../../shared/models/token-dto';
import { ForgottenPasswordDto } from '../../../../../shared/models/forgotten-password-dto';
import { ChangePasswordDto } from '../../../../../shared/models/change-password-dto';

@Injectable({ providedIn: 'root' })
export class AuthService {
  isLoggedIn = new BehaviorSubject<boolean>(false);
  private readonly USER_TOKEN_KEY = 'userToken';
  private readonly USER_DATA_KEY = 'userData';

  constructor(private router: Router, private http: HttpClient) {}

  successLogin(token: TokenDto, path: string | null) {
    this.setLoginToken(token);
    this.fetchUserData().subscribe((user) => {
      this.saveUserToLocalStorage(user);
      this.isLoggedIn.next(true);
      this.navigateToFirstPage(path);
    });
  }

  saveUserToLocalStorage(user: SimpleUserDto) {
    localStorage.setItem(this.USER_DATA_KEY, JSON.stringify(user));
  }

  autoLogin() {
    if (!localStorage.getItem(this.USER_TOKEN_KEY)) return;
    this.isLoggedIn.next(true);
  }

  logout(withReturn = false) {
    if (!this.isLoggedIn.getValue()) return;
    this.isLoggedIn.next(false);
    let navigateExtra: NavigationExtras = {};
    if (withReturn)
      navigateExtra.queryParams = {
        'return-path': this.router.routerState.snapshot.url.split('?')[0],
      };
    if (!withReturn) localStorage.clear();
    this.router.navigate([AppRouteNames.LOGIN], navigateExtra);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.isLoggedIn.asObservable();
  }

  forgottenPassword(dto: ForgottenPasswordDto) {
    return this.http.post(
      `${environment.apiUrl}api/user/forgotten-password`,
      dto
    );
  }

  changePassword(dto: ChangePasswordDto) {
    return this.http.post(`${environment.apiUrl}api/user/change-password`, dto);
  }

  navigateToFirstPage(path: string | null = null) {
    let user = this.getUserData();
    if (user.needPasswordChange) {
      this.router.navigate([AppRouteNames.CHANGE_PASSWORD]);
      return;
    }
    if (path) {
      this.router.navigate([path]);
      return;
    }
    if (user.authLevel === 'PARTNER') {
      let url = this.setUrlByPermission(user);
      this.router.navigate([url]);
    }
    if (user.authLevel === 'SALES' || user.authLevel === 'ADMIN') {
      this.router.navigate([AppRouteNames.SALES_MAIN_COMPONENT]);
    }
  }

  getToken() {
    const tokenObj = JSON.parse(
      localStorage.getItem(this.USER_TOKEN_KEY) || '{}'
    );
    return tokenObj.token;
  }

  fetchPartnerToken(id: number) {
    return this.http
      .get<TokenDto>(`${environment.apiUrl}api/salesman/token/${id}`)
      .subscribe({
        next: (token) => {
          localStorage.setItem(this.USER_TOKEN_KEY, JSON.stringify(token));
          this.fetchUserData().subscribe((user) => {
            localStorage.setItem(this.USER_DATA_KEY, JSON.stringify(user));
            this.isLoggedIn.next(true);
            this.navigateToFirstPage();
          });
        },
      });
  }

  setLoginToken(token: TokenDto) {
    localStorage.setItem(this.USER_TOKEN_KEY, JSON.stringify(token));
  }

  fetchLoginToken(loginData: LoginDto) {
    return this.http.post<TokenDto>(
      `${environment.apiUrl}api/user/login`,
      loginData
    );
  }

  fetchUserData(): Observable<SimpleUserDto> {
    return this.http.get<SimpleUserDto>(`${environment.apiUrl}api/user`);
  }

  getUserData(): SimpleUserDto {
    return JSON.parse(localStorage.getItem(this.USER_DATA_KEY)!);
  }

  getPartnerList(): Observable<PartnerDto> {
    return this.http.get<PartnerDto>(
      `${environment.apiUrl}api/salesman/partners`
    );
  }

  fetchPartnerDetails(id: number): Observable<PartnerDetailsDto> {
    return this.http.get<PartnerDetailsDto>(
      `${environment.apiUrl}api/salesman/details/${id}`
    );
  }

  fetchPartnerProfile(): Observable<PartnerDto> {
    return this.http.get<PartnerDto>(
      `${environment.apiUrl}api/user/partner-profile`
    );
  }

  fetchUserProfile(): Observable<UserDto> {
    return this.http.get<UserDto>(`${environment.apiUrl}api/user/profile`);
  }

  partnerCanGetOrders(): boolean {
    return this.getUserData().partnerParams.includes('ORDERS');
  }

  partnerCanGetInvoices(): boolean {
    return this.getUserData().partnerParams.includes('INVOICES');
  }

  partnerCanGetStands(): boolean {
    return this.getUserData().partnerParams.includes('STAND_GLASSES');
  }

  salesCanSeePartnerInformationPage(): boolean {
    return this.getUserData().salesmanParams.includes('PARTNER_PAGE');
  }

  partnerCanSubNewsletter(): boolean {
    return this.getUserData().partnerParams.includes('NEWSLETTER_SUBSCRIPTION');
  }

  partnerCanSendBackStand(): boolean {
    return this.getUserData().partnerParams.includes('STAND_GLASSES_RETURN');
  }

  partnerHasQuestionnaire(): boolean {
    return this.getUserData().questionnaireFormStatus === 'AVAILABLE';
  }

  partnerCanAddCommentToOrder(): boolean {
    return this.getUserData().partnerParams.includes(
      'ADD_REMOVE_PARTNERS_COMMENT'
    );
  }

  partnerCanGetRequest(): boolean {
    return this.getUserData().partnerParams.includes('REQUEST_FOR_ORDER');
  }

  setUrlByPermission(user: SimpleUserDto): string {
    switch (user.partnerParams[0]) {
      case 'DASHBOARD':
        return AppRouteNames.PARTNER_DASHBOARD;
      case 'ORDERS':
        return AppRouteNames.PARTNER_ORDERS;
      case 'INVOICES':
        return AppRouteNames.PARTNER_INVOICES;
      case 'STAND_GLASSES':
        return AppRouteNames.PARTNER_STAND_GLASSES;
      case 'PROFILE':
        return AppRouteNames.PARTNER_PROFILE;
      case 'DOCUMENTS':
        return AppRouteNames.PARTNER_DOCUMENTS;
      case 'CONNECTIONS':
        return AppRouteNames.PARTNER_CONNECTIONS;
      case 'QUESTIONNAIRE':
        return AppRouteNames.PARTNER_QUESTIONNAIRE;
      default:
        alert(
          'Nincs felület rendelve a felhasználójához. Kérjük keresse a CE Glass ügyfélszolgálatát!'
        );
        this.logout();
        throw new Error('No permission!');
    }
  }
}
