import { Component } from '@angular/core';
import { DataService } from '../../../data.service';
import { QuestionnaireDto } from '../../../../../../shared/models/questionnaire-dto';
import { AuthService } from '../../../AuthComponents/auth/auth.service';
import { CustomFormType } from '../../../../../../shared/app-shared-utilities';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrl: './questionnaire.component.less',
})
export class QuestionnaireComponent {
  questionnaireStatus: string = '';
  activeQuestionnaire: QuestionnaireDto = <QuestionnaireDto>{};
  activeForm?: CustomFormType;

  constructor(
    private dataService: DataService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.questionnaireStatus = JSON.parse(
      localStorage.getItem('userData')!
    ).questionnaireFormStatus;
    if (this.authService.partnerHasQuestionnaire()) this.loadQuestionnaire();
  }

  loadQuestionnaire() {
    this.dataService.getActiveQuestionnaire().subscribe({
      next: (questionnaire) => {
        this.activeQuestionnaire = questionnaire;
        this.activeForm = this.questionnaireToForm(this.activeQuestionnaire);
      },
    });
  }

  questionnaireToForm(
    activeQuestionnaire: QuestionnaireDto
  ): CustomFormType | undefined {
    if (activeQuestionnaire.form) {
      return {
        ...activeQuestionnaire,
        form: JSON.parse(activeQuestionnaire.form),
      };
    }
    return;
  }
}
