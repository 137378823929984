import { Component, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from '../../../AuthComponents/auth/auth.service';
import { PartnerDto } from '../../../../../../shared/models/partner-dto';
import { UserDto } from '../../../../../../shared/models/user-dto';
import { DashboardService } from '../dashboard/dashboard.service';
import { ChangeProfileDto } from '../../../../../../shared/models/change-profile-dto';
import { DxFormComponent } from 'devextreme-angular';
import { AlertBoxService } from '../../UniversalViewComponents/alert-box/alert-box.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-partner-profile',
  templateUrl: './partner-profile.component.html',
  styleUrl: './partner-profile.component.less',
})
export class PartnerProfileComponent {
  @ViewChild('sendMsgForm') sendMsgForm?: DxFormComponent;
  isPopupOpen: boolean = false;
  isPasswordPopupOpen: boolean = false;
  partnerProfile: PartnerDto = <PartnerDto>{};
  userProfile: UserDto = <UserDto>{};
  sendMsgData: ChangeProfileDto = <ChangeProfileDto>{};
  isLoaded: boolean = true;

  constructor(
    private authService: AuthService,
    private dashboardService: DashboardService,
    private translate: TranslateService,
    private alertBoxService: AlertBoxService
  ) {}

  ngOnInit(): void {
    this.authService.fetchPartnerProfile().subscribe({
      next: (partner) => {
        this.partnerProfile = partner;
      },
    });
    this.authService.fetchUserProfile().subscribe({
      next: (user) => {
        this.userProfile = user;
      },
    });
  }

  togglePopup() {
    this.isPopupOpen = !this.isPopupOpen;
  }

  togglePasswordPopup() {
    this.isPasswordPopupOpen = !this.isPasswordPopupOpen;
  }

  onSubmit = () => {
    if (this.sendMsgForm?.instance.validate().isValid) this.isLoaded = false;
    this.dashboardService.sendChangeProfileMessage(this.sendMsgData).subscribe({
      next: () => {
        this.alertBoxService.showInfo(this.translate.instant("profile.submitDataChangePopup.dataChangeSuccess"));
        this.togglePopup();
        this.isLoaded = true;
        this.sendMsgForm?.instance.reset();
      },
      error: (error) => {
        this.alertBoxService.showInfo(error.message);
        this.isLoaded = true;
      },
    });
  };
}
