import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DxFormComponent, DxButtonComponent } from 'devextreme-angular';
import { ChangePasswordDto } from '../../../../../shared/models/change-password-dto';
import { TokenDto } from '../../../../../shared/models/token-dto';
import { AppRouteNames } from '../../app-route-names';
import { AuthService } from '../auth/auth.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-pass-change-form',
  templateUrl: './pass-change-form.component.html',
  styleUrl: './pass-change-form.component.less',
})
export class PassChangeFormComponent {
  @ViewChild(DxFormComponent, { static: false })
  passChangeForm?: DxFormComponent;
  @ViewChild('submitPassBtn') submitPassBtn?: DxButtonComponent;
  passChangeData: ChangePasswordDto = <ChangePasswordDto>{};
  minNumCheck: boolean = false;
  minSmallCharCheck: boolean = false;
  minBigCharCheck: boolean = false;
  minSpecCharCheck: boolean = false;
  passwordCompare: boolean = false;
  passwordSuccess: boolean = false;
  tokenValue?: TokenDto = <TokenDto>{};

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.route.snapshot.queryParamMap.has('token')) {
      this.tokenValue = {
        token: this.route.snapshot.queryParamMap.get('token')!,
      };
      this.authService.setLoginToken(this.tokenValue);
    }
  }

  handlePropChanged = (event: any) => {
    this.minNumCheck = event.value.length >= 8;
    this.minSmallCharCheck = event.value.toUpperCase() !== event.value;
    this.minBigCharCheck = event.value.toLowerCase() !== event.value;
    this.minSpecCharCheck = /[^A-Za-z0-9]/.test(event.value);
  };

  newPasswordEditorOptions: any = {
    mode: 'password',
    valueChangeEvent: 'keyup',
    onValueChanged: this.handlePropChanged,
    buttons: [
      {
        name: 'password',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'eyeopen',
          onClick: () => this.changePasswordMode('password'),
        },
      },
    ],
  };

  confirmPasswordEditorOptions: any = {
    mode: 'password',
    buttons: [
      {
        name: 'password',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'eyeopen',
          onClick: () => this.changePasswordMode('confirmPassword'),
        },
      },
    ],
  };

  changePasswordMode = (name: any) => {
    let editor = this.passChangeForm?.instance.getEditor(name);
    editor?.option(
      'mode',
      editor.option('mode') === 'text' ? 'password' : 'text'
    );
  };

  passwordComparison = () => this.passChangeData.password;

  onSubmit = () => {
    if (
      !this.minNumCheck ||
      !this.minSmallCharCheck ||
      !this.minBigCharCheck ||
      !this.minSpecCharCheck
    )
      return;
    if (this.passChangeForm?.instance.validate().isValid) {
      this.submitPassBtn!.disabled = true;
      this.authService.changePassword(this.passChangeData).subscribe({
        next: () => {
          this.passwordSuccess = true;
          setTimeout(() => {
            this.submitPassBtn!.disabled = false;
            if (this.authService.isLoggedIn) {
              this.authService.logout();
            } else {
              this.router.navigate([AppRouteNames.LOGIN]);
            }
          }, 3000);
        },
        error: (error) => {
          if (error.status == 401 && this.tokenValue?.token !== '') {
            alert(this.translate.get("changePassword.expiredLink"));
          } else if (error.status == 500) {
            alert(this.translate.get("common.internalError"));
            this.router.navigate(['/']);
          }
          this.submitPassBtn!.disabled = false;
        },
      });
    }
  };
}
