import { Component, ViewChild } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { AppRouteNames } from '../../app-route-names';
import {ForgottenPasswordDto} from "../../../../../shared/models/forgotten-password-dto";
import {DxFormComponent} from "devextreme-angular";

@Component({
  selector: 'app-forgotten-pass',
  templateUrl: './forgotten-pass.component.html',
  styleUrl: './forgotten-pass.component.less',
})
export class ForgottenPassComponent {
  successfulRequest: boolean = false;
  pendingRequest: boolean = false;
  forgottenPassData: ForgottenPasswordDto  = <ForgottenPasswordDto>{};
  @ViewChild("passChangeForm") passChangeForm?: DxFormComponent;
  constructor(private authService: AuthService, private router: Router) {}

  onSubmit() {
    if (!this.passChangeForm?.instance.validate().isValid) {
      return;
    }
    this.pendingRequest = true;
    this.authService.forgottenPassword(this.forgottenPassData).subscribe({
      next: () => {
        this.successfulRequest = true;
      },
    });
  }
  protected readonly AppRouteNames = AppRouteNames;
}
