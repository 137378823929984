import { Component, Input } from '@angular/core';
import { StandMoveDto } from '../../../../../../../shared/models/stand-move-dto';
import { DataService } from '../../../../data.service';
import { StandsService } from '../stands.service';
import { AuthService } from '../../../../AuthComponents/auth/auth.service';

@Component({
  selector: 'app-stand-details',
  templateUrl: './stand-details.component.html',
  styleUrl: './stand-details.component.less',
})
export class StandDetailsComponent {
  @Input() standToDetails?: StandMoveDto;
  @Input() isSingleView?: boolean;
  sendBackStandData: { standId: number } = {
    standId: 0,
  };

  isSendBackPopup: boolean = false;
  isComplaintsPopup: boolean = false;
  isBarcodePopup: boolean = false;

  constructor(
    public dataService: DataService,
    public standsService: StandsService,
    protected authService: AuthService
  ) {}

  ngOnInit(): void {
    console.log(this.standToDetails?.status);
  }

  getStatusText(statusId: string) {
    return this.standsService.STANDSTATUS_DATA.find(
      (status) => status.id === statusId
    )?.text;
  }

  closePopup() {
    this.isSendBackPopup = false;
    this.isBarcodePopup = false;
    this.isComplaintsPopup = false;
  }

  showBarcode() {
    this.isBarcodePopup = true;
  }

  toggleSendBackStand() {
    this.isSendBackPopup = true;
  }

  sendBackStand() {
    this.sendBackStandData.standId = this.standToDetails!.id;
    console.log(this.sendBackStandData);
  }
}
