import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SpinnerData } from './spinner.component';

@Injectable({ providedIn: 'root' })
export class SpinnerService {
  isLoading = new BehaviorSubject<SpinnerData>({
    text: 'Betöltés',
    isLoading: false,
  });
}
