<div class="loading-container">
  <div class="loading">
    <div class="dx-loadpanel-indicator dx-loadindicator dx-widget">
      <div class="dx-loadindicator-wrapper">
        <div class="dx-loadindicator-content">
          <div class="dx-loadindicator-icon">
            <div
              class="dx-loadindicator-segment dx-loadindicator-segment7"
            ></div>
            <div
              class="dx-loadindicator-segment dx-loadindicator-segment6"
            ></div>
            <div
              class="dx-loadindicator-segment dx-loadindicator-segment5"
            ></div>
            <div
              class="dx-loadindicator-segment dx-loadindicator-segment4"
            ></div>
            <div
              class="dx-loadindicator-segment dx-loadindicator-segment3"
            ></div>
            <div
              class="dx-loadindicator-segment dx-loadindicator-segment2"
            ></div>
            <div
              class="dx-loadindicator-segment dx-loadindicator-segment1"
            ></div>
            <div
              class="dx-loadindicator-segment dx-loadindicator-segment0"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="dx-loadpanel-message">{{ text }}...</div>
  </div>
</div>
