<form>
  <dx-form
    #complaintForm
    [formData]="complaintData"
    labelLocation="top"
    [showColonAfterLabel]="false"
    (onFieldDataChanged)="onFieldDataChanged()"
  >
    <dxi-item itemType="group" [colCount]="1">
      <dxi-item
        #dataType
        dataField="type"
        editorType="dxSelectBox"
        [disabled]="isDisabled"
        [editorOptions]="{
          dataSource: COMPLAINT_TYPES,
          displayExpr: 'name',
          valueExpr: 'id'
        }"
        ><dxo-label [text]="'complaints.typeLabel' | translate"></dxo-label>
        <dxi-validation-rule
          type="required"
          [message]="'error.requiredMessage' | translate"
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="id" *ngIf="showId" [disabled]="isDisabled"
        ><dxo-label [text]="nameId"></dxo-label>
      </dxi-item>
      <dxi-item dataField="text" editorType="dxTextArea" [editorOptions]="{height:200}"
        ><dxo-label [text]="'complaints.messageLabel' | translate"></dxo-label>
        <dxi-validation-rule
          type="required"
          [message]="'error.requiredMessage' | translate"
        ></dxi-validation-rule>
        <dxi-validation-rule
          type="stringLength"
          [min]="25"
          [message]="'error.minCharMessage' | translate:{number:25}"
        >
        </dxi-validation-rule>
        <dxi-validation-rule
          type="stringLength"
          [max]="2001"
          [message]="'error.maxCharMessage' | translate:{number:2001}"
        >
        </dxi-validation-rule>
      </dxi-item>
      <dxi-item
        #fileUploader
        dataField="attachments"
        editorType="dxFileUploader"
        [editorOptions]="{
              maxFileSize: 10000000000,
              accept: AppConstants.ACCEPTED_FILE_TYPE.join(','),
              uploadMode: 'useButton',
              multiple: true,
            }"
        ><dxo-label [text]="'common.fileUploader.attachments' | translate"></dxo-label>
        <dxi-validation-rule
          #fileValidation
          type="custom"
          [validationCallback]="validateFiles"
          [message]="'error.attachmentsInvalidMessage' | translate"
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item type="group">
        <p>
            {{ "common.fileUploader.attachmentsMessage" | translate:{fileCount:AppConstants.FILE_COUNT_LIMIT, fileSize:fileSize, maxFileSize:AppConstants.FILE_SIZE_MAXIMUM_MB} }}
        </p>
      </dxi-item>
    </dxi-item>
    <dxi-item type="group">
      <div class="button-row right">
        <dx-button
          #submitComplaintButton
          class="primary bg-teal-5 btn-sm font-lg"
          [useSubmitBehavior]="true"
          (onClick)="onSubmit()"
        >
          <i class="bi bi-envelope-arrow-up"></i>
          <span>{{ "complaints.sendButton" | translate }}</span>
        </dx-button>
      </div>
    </dxi-item>
  </dx-form>
</form>
<app-spinner *ngIf="!isLoaded"></app-spinner>
