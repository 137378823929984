import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StandMoveDto } from '../../../../../../shared/models/stand-move-dto';
import { environment } from '../../../../../../shared/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { StandSummaryDto } from '../../../../../../shared/models/stand-summary-dto';
import {AuthService} from "../../../AuthComponents/auth/auth.service";
import {TranslateService} from "@ngx-translate/core";

@Injectable({ providedIn: 'root' })
export class StandsService {
  STANDSTATUS_DATA:{id:string,text:string}[]=[];

  isSummaryVisible = new BehaviorSubject<boolean>(true);
  isPartnerCanSendBackStand: boolean = false;

  constructor(
      private http: HttpClient,
      private authService: AuthService,
      private translate: TranslateService,
  ) {
    this.isPartnerCanSendBackStand=this.authService.partnerCanSendBackStand();
    this.loadTranslates();
  }

  async loadTranslates(){
    await this.translate.get("header.title").toPromise();
    this.STANDSTATUS_DATA = [
      { id: 'OUTSIDE', text: this.translate.instant("stands.status.OUTSIDE")  },
      { id: 'INSIDE', text: this.translate.instant("stands.status.INSIDE")  },
    ];
  }

  fetchStandDetails(standId: number) {
    return this.http.get<StandMoveDto>(
      `${environment.apiUrl}api/stand_move/details/${standId}`
    );
  }
  fetchStandSummary() {
    return this.http.get<StandSummaryDto>(
      `${environment.apiUrl}api/stand/summary`
    );
  }
}
