import { Component, ViewChild } from '@angular/core';
import { OrderDetailsDto } from '../../../../../../shared/models/order-details-dto';
import {
  DxButtonComponent,
  DxFormComponent,
} from 'devextreme-angular';
import {
  DxiValidationRuleComponent,
} from 'devextreme-angular/ui/nested';
import { OrdersService } from './orders.service';
import { AppConstants } from '../../../../../../shared/app-constants';
import { OrdersListComponent } from './orders-list/orders-list.component';
import { AlertBoxService } from '../../UniversalViewComponents/alert-box/alert-box.service';
import { OrderRequestDto } from '../../../../../../shared/models/order-request-dto';
import {AuthService} from "../../../AuthComponents/auth/auth.service";
import {DataService} from "../../../data.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrl: './orders.component.less',
})
export class OrdersComponent {
  @ViewChild('ordersList') ordersList?: OrdersListComponent;
  @ViewChild('addOrderForm') addOrderForm?: DxFormComponent;
  @ViewChild('fileValidate') fileValidate?: DxiValidationRuleComponent;
  @ViewChild('submitRequestOrderButton')
  submitRequestOrderButton?: DxButtonComponent;
  orderToDetails?: OrderDetailsDto;
  isPopup: boolean = false;
  isLoaded: boolean = true;
  orderData: {
    type: 'REQUEST' | 'ORDER';
    subject: string;
    message: string;
    deliveryAddress: string;
    attachments?: File[];
  } = {
    type: 'REQUEST',
    subject: '',
    message: '',
    deliveryAddress: '',
  };
  fileSize: string = '0';

  NEW_TYPES:{name:string,id:string}[] = [];

  constructor(
    public authService: AuthService,
    private ordersService: OrdersService,
    public dataService: DataService,
    private translate: TranslateService,
    private alertBoxService: AlertBoxService
  ) {}

  ngOnInit(): void {
    this.loadTranslates();
  }

  async loadTranslates() {
    await this.translate.get("header.title").toPromise();
    this.NEW_TYPES = [
      {id: 'REQUEST' ,name: this.translate.instant("orders.newOfferPopup.typeDropdown.REQUEST")},
      {id: 'ORDER', name:  this.translate.instant("orders.newOfferPopup.typeDropdown.ORDER") },
    ];
  }

  getOrderToDetails(order: OrderDetailsDto) {
    this.orderToDetails = order;
  }

  setListFullWidth(e: any) {
    this.ordersList?.setFullWidth();
  }

  addNewOffer() {
    this.isPopup = true;
  }

  closePopup() {
    this.isPopup = false;
  }

  reloadList(e: any) {
    this.ordersList?.loadList();
  }

  onSubmit() {
    if (this.addOrderForm?.instance.validate().isValid) {
      const dto: OrderRequestDto = {
        orderRequestType: this.orderData?.type,
        message: this.orderData.message,
        shippingAddress: this.orderData.deliveryAddress,
        subject: this.orderData.subject,
      };
      this.submitRequestOrderButton!.disabled = true;
      this.isLoaded = false;
      this.ordersService
        .sendOrderRequest(dto, this.orderData.attachments)
        .subscribe({
          next: () => {
            this.alertBoxService.showInfo(this.translate.instant("orders.newOfferPopup.successMessage"));
            this.submitRequestOrderButton!.disabled = false;
            this.closePopup();
            this.isLoaded = true;
            this.addOrderForm!.instance.reset();
          },
          error: (error) => {
            this.alertBoxService.showInfo(error.message);
            this.isLoaded = true;
          },
        });
    }
  }
  validateFiles = (e: any) => {
    let validator=this.dataService.validateFiles(e);
    this.fileSize=validator.fileSize;
    return validator.valid;
  };

  protected readonly AppConstants = AppConstants;
}
