<div class="footer">
  <div class="footer-container">
    <div class="col-1">
      <img
        src="../../../assets/img/ceglass-logo.svg"
        alt="CE Glass logo"
        class="footer-logo m-bottom-2"
      />
      <p>
        <i class="bi bi-geo-alt"></i>
        <span>{{ "common.ceCompanyData.address" | translate }}</span>
      </p>
      <a href='tel:{{ "common.ceCompanyData.phone" | translate }}'>
        <i class="bi bi-telephone"></i>
        <span>{{ "common.ceCompanyData.phone" | translate }}</span>
      </a>
      <a href='https://{{ "common.ceCompanyData.webpage" | translate }}' target="_blank">
        <i class="bi bi-globe"></i>
        <span>{{ "common.ceCompanyData.webpage" | translate }}</span>
      </a>
      <a href='mailto:{{ "common.ceCompanyData.email" | translate }}'>
        <i class="bi bi-envelope"></i>
        <span>{{ "common.ceCompanyData.email" | translate }}</span>
      </a>
      <p>
        <i class="bi bi-door-open"></i>
        <span>{{ "footer.openHours" | translate }}</span>
      </p>
      <p>{{ "footer.reply" | translate }}</p>
    </div>
    <div class="col-2">
      <a
        [href]='"footer.policyLink" | translate'
        class="m-bottom-1"
        target="_blank"
      >
        <span>{{ "footer.policy" | translate }}</span>
      </a>
      <div class="social-icons m-bottom-1">
        <a
          href="https://www.facebook.com/ceglassindustriesszeged/"
          target="_blank"
        >
          <i class="bi bi-facebook"></i>
        </a>
        <a href="https://www.instagram.com/ceglass_industries/" target="_blank">
          <i class="bi bi-instagram"></i>
        </a>
        <a
          href="https://www.linkedin.com/company/ceglassindustries/"
          target="_blank"
        >
          <i class="bi bi-linkedin"></i>
        </a>
        <a href="https://www.tiktok.com/@ceglassindustries" target="_blank">
          <i class="bi bi-tiktok"></i>
        </a>
        <a
          href="https://www.youtube.com/channel/UCdiTDzXzDBSnpL8upzUihwA"
          target="_blank"
        >
          <i class="bi bi-youtube"></i>
        </a>
        <a href='https://{{ "common.ceCompanyData.webpage" | translate }}' target="_blank">
          <i class="bi bi-globe"></i>
        </a>
      </div>
    </div>
  </div>
</div>
