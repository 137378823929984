<div class="alert-box-container" *ngIf="showAlert">
  <div class="alert-box">
    <p>{{ alertMessage }}</p>
    <dx-button (click)="closeAlert()">OK</dx-button>
  </div>
</div>
<div class="info-box-container" *ngIf="showInfo">
  <div class="info-box">
    <p>{{ infoMessage }}</p>
    <i class="bi bi-x-lg close-info" (click)="closeInfoBox()"></i>
  </div>
</div>
