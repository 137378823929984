import { Component, ViewChild } from '@angular/core';
import { NgxGlideComponent } from 'ngx-glide';
import { DashboardService } from '../dashboard.service';
import { AuthService } from '../../../../AuthComponents/auth/auth.service';

@Component({
  selector: 'app-quote-slider',
  templateUrl: './quote-slider.component.html',
  styleUrl: './quote-slider.component.less',
})
export class QuoteSliderComponent {
  quotes: any[];
  lang: string = '';

  constructor(
    private dashboardService: DashboardService,
    private authService: AuthService
  ) {
    this.quotes = [];
  }

  ngOnInit(): void {
    this.dashboardService.getQuotes().subscribe((quotes) => {
      const quotesArray = Object.values(quotes);
      this.quotes = this.dashboardService.getRandomItems(quotesArray, 5);
    });
    this.lang = this.authService.getUserData().language.toLocaleLowerCase();
  }
}
