<div class="breadcrumb-actions-row container-width">
  <app-breadcrumb class="breadcrumb-group"></app-breadcrumb>
</div>
<div class="questionnaire-container main-grid container-width">

    <h3 class="no-questionnaire-title card-title teal-5" style="text-align: center; margin: auto;" *ngIf="questionnaireStatus == 'NOT_EXIST'">
      Jelenleg nincs elérhető kérdőív!
    </h3>
    <h3 class="no-questionnaire-title card-title teal-5" style="text-align: center; margin: auto"  *ngIf="questionnaireStatus == 'FILLED'">
      Köszönjük a kérdőív kitöltését!
    </h3>


  <div class="questionnaire" *ngIf="questionnaireStatus == 'AVAILABLE'">
    <app-questionnaire-form
      *ngIf="activeForm"
      [customForm]="activeForm"
    ></app-questionnaire-form>
  </div>
</div>
