import { Component, EventEmitter, Output } from '@angular/core';
import { InvoicesService } from '../invoices.service';
import { InvoiceSummaryDto } from '../../../../../../../shared/models/invoice-summary-dto';

@Component({
  selector: 'app-invoices-summary',
  templateUrl: './invoices-summary.component.html',
  styleUrl: './invoices-summary.component.less',
})
export class InvoicesSummaryComponent {
  isSummaryVisible: boolean = true;
  invoicesSummary?: InvoiceSummaryDto[];
  @Output() setListFullWidth: EventEmitter<any> = new EventEmitter();
  constructor(private invoicesService: InvoicesService) {}

  ngOnInit(): void {
    this.invoicesService.invoicesSummary.subscribe({
      next: (invoices) => {
        this.invoicesSummary = invoices;
      },
    });
    this.invoicesService.isSummaryVisible.subscribe((isVisible) => {
      this.isSummaryVisible = isVisible;
    });
  }

  toggleSummary() {
    this.isSummaryVisible = !this.isSummaryVisible;
    if (this.isSummaryVisible) this.setListFullWidth.emit();
  }
}
