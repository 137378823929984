import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRouteNames } from '../../../../app-route-names';
import { OrdersService } from '../orders.service';
import { OrderDetailsDto } from '../../../../../../../shared/models/order-details-dto';

@Component({
  selector: 'app-order-single-view',
  templateUrl: './order-single-view.component.html',
  styleUrl: './order-single-view.component.less',
})
export class OrderSingleViewComponent {
  isLoaded: boolean = false;
  singleOrder?: OrderDetailsDto;
  constructor(
    private route: ActivatedRoute,
    private ordersService: OrdersService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.ordersService.fetchOrderDetails(params['id']).subscribe({
        next: (order) => {
          this.singleOrder = order;
          this.isLoaded = true;
        },
        error: (err) => {
          this.router.navigate([AppRouteNames.PARTNER_INVOICES]);
        },
      });
    });
  }
}
