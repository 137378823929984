<div class="breadcrumb-actions-row container-width">
  <app-breadcrumb class="breadcrumb-group"></app-breadcrumb>
  <div class="action-buttons">
    <dx-button (onClick)="togglePopup()" class="primary bg-teal-3 btn-lg">
      <i class="bi bi-question-circle"></i><span>{{ 'profile.submitDataChangeButton' | translate }}</span>
    </dx-button>
    <dx-button
      (onClick)="togglePasswordPopup()"
      class="primary bg-teal-5 btn-lg"
    >
      <i class="bi bi-question-circle"></i><span>{{ 'profile.changePasswordButton' | translate }}</span>
    </dx-button>
  </div>
</div>
<div class="partner-profile generic-block-grid col-3 container-width">
  <div class="generic-block">
    <h3 class="card-title teal-5">{{ 'profile.companySectionTitle' | translate }}</h3>
    <div class="generic-data-group col-2">
      <div class="generic-data-block">
        <p class="generic-data-block-title">
          {{ "profile.partner.name" | translate }}
        </p>
        <p class="generic-data-block-value">{{ partnerProfile.name }}</p>
      </div>
      <div class="generic-data-block">
        <p class="generic-data-block-title">
          {{ "profile.partner.address" | translate }}
        </p>
        <p class="generic-data-block-value">{{ partnerProfile.headquarter }}</p>
      </div>
      <div class="generic-data-block">
        <p class="generic-data-block-title">
          {{ "profile.partner.vat" | translate }}
        </p>
        <p class="generic-data-block-value">{{ partnerProfile.vat }}</p>
      </div>
    </div>
  </div>
  <div class="generic-block">
    <h3 class="card-title teal-5">{{ 'profile.contactSectionTitle' | translate }}</h3>
    <div class="generic-data-group col-2">
      <div class="generic-data-block">
        <p class="generic-data-block-title">
          {{ "profile.partner.contact" | translate }}
        </p>
        <p class="generic-data-block-value">{{ partnerProfile.contactName }}</p>
      </div>
      <div class="generic-data-block">
        <p class="generic-data-block-title">
          {{ "profile.partner.contactPhone" | translate }}
        </p>
        <p class="generic-data-block-value">{{ partnerProfile.contactTel }}</p>
      </div>
      <div class="generic-data-block">
        <p class="generic-data-block-title">
          {{ "profile.partner.contactEmail" | translate }}
        </p>
        <p class="generic-data-block-value">
          {{ partnerProfile.contactEmail }}
        </p>
      </div>
    </div>
  </div>
  <div class="generic-block">
    <div class="generic-block-header-row">
      <h3 class="card-title teal-5">{{ 'profile.userSectionTitle' | translate }}</h3>
    </div>
    <div class="generic-data-group col-2">
      <div class="generic-data-block">
        <p class="generic-data-block-title">
          {{ "profile.partner.username" | translate }}
        </p>
        <p class="generic-data-block-value">{{ userProfile.username }}</p>
      </div>
      <div class="generic-data-block">
        <p class="generic-data-block-title">
          {{ "profile.partner.email" | translate }}
        </p>
        <p class="generic-data-block-value">{{ userProfile.email }}</p>
      </div>
      <div class="generic-data-block">
        <p class="generic-data-block-title">
          {{ "profile.partner.defaultLanguage" | translate }}
        </p>
        <p class="generic-data-block-value">
          {{ "lang." + userProfile.language | translate }}
        </p>
      </div>
      <div class="generic-data-block">
        <p class="generic-data-block-title">
          {{ "profile.partner.notificationEmails" | translate }}
        </p>
        <ul *ngIf="userProfile.notificationEmailAddresses">
          <li *ngFor="let email of userProfile.notificationEmailAddresses">
            {{ email }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="popup" *ngIf="isPopupOpen">
  <div class="popup-container">
    <dx-button
      (onClick)="togglePopup()"
      class="primary bg-teal-5 close icon-only"
    >
      <i class="bi bi-x-lg"></i>
    </dx-button>
    <div class="generic-block" (click)="$event.stopPropagation()">
      <h3 class="card-title teal-5">{{ 'profile.submitDataChangePopup.title' | translate }}</h3>
      <form>
        <dx-form #sendMsgForm [formData]="sendMsgData" labelLocation="top">
          <dxi-item
            dataField="message"
            editorType="dxTextArea"
            [editorOptions]="{ height: 200 }"
            ><dxo-label [text]="'profile.submitDataChangePopup.messageLabel' | translate"
            ></dxo-label>
            <dxi-validation-rule
              type="stringLength"
              [min]="25"
              [message]="'error.minCharMessage' | translate:{number:25}"
            >
            </dxi-validation-rule>
          </dxi-item>
          <dxi-item type="group">
            <div class="button-row right">
              <dx-button
                [useSubmitBehavior]="true"
                (onClick)="onSubmit()"
                class="btn-sm font-lg primary bg-teal-5"
              >
                <i class="bi bi-envelope-arrow-up"></i>
                <span>{{ "complaints.sendButton" | translate }}</span>
              </dx-button>
            </div>
          </dxi-item>
        </dx-form>
      </form>
    </div>
  </div>
</div>
<div class="popup" *ngIf="isPasswordPopupOpen">
  <div class="popup-container">
    <dx-button
      (onClick)="togglePasswordPopup()"
      class="primary bg-teal-5 close icon-only"
    >
      <i class="bi bi-x-lg"></i>
    </dx-button>
    <div class="generic-block" (click)="$event.stopPropagation()">
      <app-pass-change-form></app-pass-change-form>
    </div>
  </div>
</div>
<app-spinner *ngIf="!isLoaded"></app-spinner>
