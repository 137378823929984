<ng-container *ngIf="images && images.length">
  <ngx-glide
    #ngxGlide
    [perView]="1"
    [autoplay]="true"
    [startAt]="0"
    [focusAt]="0"
    [autoplay]="5000"
    [animationDuration]="400"
    [showArrows]="false"
    animationTimingFunc="ease-in-out"
  >
    <div *ngFor="let image of images">
      <img src="{{ image.src }}" alt="" />
      <div class="ce-logo"><img src="../../../../../assets/img/ceglass-logo-header.png" alt=""></div>
      <div class="text">
        <p>
          <span>{{ image.location }}</span> - {{ image.name }}
        </p>
      </div>
    </div>
  </ngx-glide>
</ng-container>
