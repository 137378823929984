import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  HttpBackend,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './AuthComponents/auth/auth.component';
import { DashboardComponent } from './ViewComponents/partner-view/dashboard/dashboard.component';
import { HeaderComponent } from './ViewComponents/header/header.component';
import { PassChangeComponent } from './AuthComponents/pass-change/pass-change.component';

import {
  DxButtonModule,
  DxDataGridModule,
  DxFileUploaderModule,
  DxListModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxTabPanelModule,
  DxTextAreaModule,
  DxTextBoxModule,
} from 'devextreme-angular';
import { DxMenuModule } from 'devextreme-angular';
import { DxFormModule } from 'devextreme-angular';
import { ForgottenPassComponent } from './AuthComponents/forgotten-pass/forgotten-pass.component';
import { AuthInterceptor } from './AuthComponents/auth/auth.interceptor';
import { SalesmanViewComponent } from './ViewComponents/salesman-view/salesman-view.component';
import { PartnerViewComponent } from './ViewComponents/partner-view/partner-view.component';
import { OrdersComponent } from './ViewComponents/partner-view/orders/orders.component';
import { ComplaintsComponent } from './ViewComponents/partner-view/complaints-view/complaints/complaints.component';
import { QuestionnaireComponent } from './ViewComponents/partner-view/questionnaire/questionnaire.component';
import { InvoicesComponent } from './ViewComponents/partner-view/invoices/invoices.component';
import { DocumentsComponent } from './ViewComponents/partner-view/documents/documents.component';
import { ConnectionsComponent } from './ViewComponents/partner-view/connections/connections.component';
import { DefaultRouteComponent } from './AuthComponents/default-route/default-route.component';
import { NgxGlideModule } from 'ngx-glide';
import { QuoteSliderComponent } from './ViewComponents/partner-view/dashboard/quote-slider/quote-slider.component';
import { LangSelectPipe } from './pipe/lang-select.pipe';
import { ImageSliderComponent } from './ViewComponents/partner-view/dashboard/image-slider/image-slider.component';
import { PartnerProfileComponent } from './ViewComponents/partner-view/partner-profile/partner-profile.component';
import { FooterComponent } from './ViewComponents/footer/footer.component';
import { DxiValidationRuleModule } from 'devextreme-angular/ui/nested';
import { InvoicesSummaryComponent } from './ViewComponents/partner-view/invoices/invoices-summary/invoices-summary.component';
import { InvoicesListComponent } from './ViewComponents/partner-view/invoices/invoices-list/invoices-list.component';
import { InvoiceDetailsComponent } from './ViewComponents/partner-view/invoices/invoice-details/invoice-details.component';
import { InvoicesSummaryCardComponent } from './ViewComponents/partner-view/invoices/invoices-summary/invoices-summary-card/invoices-summary-card.component';
import { InvoiceSingleViewComponent } from './ViewComponents/partner-view/invoices/invoice-single-view/invoice-single-view.component';
import { BreadcrumbComponent } from './ViewComponents/UniversalViewComponents/breadcrumb/breadcrumb.component';
import { CeNumberFormatPipe } from './pipe/ce-number-format.pipe';
import { CeDateFormatPipe } from './pipe/ce-date-format.pipe';
import { OrdersListComponent } from './ViewComponents/partner-view/orders/orders-list/orders-list.component';
import { OrdersSummaryComponent } from './ViewComponents/partner-view/orders/orders-summary/orders-summary.component';
import { OrderDetailsComponent } from './ViewComponents/partner-view/orders/order-details/order-details.component';
import { OrderSingleViewComponent } from './ViewComponents/partner-view/orders/order-single-view/order-single-view.component';
import { ComplaintsViewComponent } from './ViewComponents/partner-view/complaints-view/complaints-view.component';
import { SpinnerComponent } from './ViewComponents/UniversalViewComponents/spinner/spinner.component';
import { AlertBoxComponent } from './ViewComponents/UniversalViewComponents/alert-box/alert-box.component';
import { CeDateTimeFormatPipe } from './pipe/ce-datetime-format.pipe';
import { StandsComponent } from './ViewComponents/partner-view/stands/stands.component';
import { StandsListComponent } from './ViewComponents/partner-view/stands/stands-list/stands-list.component';
import { StandDetailsComponent } from './ViewComponents/partner-view/stands/stand-details/stand-details.component';
import { StandsSummaryComponent } from './ViewComponents/partner-view/stands/stands-summary/stands-summary.component';
import { PassChangeFormComponent } from './AuthComponents/pass-change-form/pass-change-form.component';
import { StandSingleViewComponent } from './ViewComponents/partner-view/stands/stand-single-view/stand-single-view.component';
import { Utilities } from '../../../shared/app-shared-utilities';
import { QuestionnaireFormComponent } from './ViewComponents/partner-view/questionnaire/questionnaire-form/questionnaire-form.component';

export function HttpLoaderFactory(httpHandler: HttpBackend) {
  return new TranslateHttpLoader(new HttpClient(httpHandler));
}

@NgModule({
  declarations: [
    AppComponent,
    OrdersComponent,
    InvoicesComponent,
    AuthComponent,
    HeaderComponent,
    PassChangeComponent,
    DashboardComponent,
    ForgottenPassComponent,
    SalesmanViewComponent,
    PartnerViewComponent,
    OrdersComponent,
    ComplaintsComponent,
    QuestionnaireComponent,
    DocumentsComponent,
    ConnectionsComponent,
    DefaultRouteComponent,
    QuoteSliderComponent,
    LangSelectPipe,
    ImageSliderComponent,
    PartnerProfileComponent,
    FooterComponent,
    InvoicesSummaryComponent,
    InvoicesListComponent,
    InvoiceDetailsComponent,
    InvoicesSummaryCardComponent,
    InvoiceSingleViewComponent,
    BreadcrumbComponent,
    CeNumberFormatPipe,
    CeDateFormatPipe,
    CeDateTimeFormatPipe,
    OrdersListComponent,
    OrdersSummaryComponent,
    OrderDetailsComponent,
    OrderSingleViewComponent,
    ComplaintsViewComponent,
    SpinnerComponent,
    AlertBoxComponent,
    StandsComponent,
    StandsListComponent,
    StandDetailsComponent,
    StandsSummaryComponent,
    QuestionnaireFormComponent,
    PassChangeFormComponent,
    StandSingleViewComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
    DxButtonModule,
    DxMenuModule,
    DxFormModule,
    DxTextBoxModule,
    DxTabPanelModule,
    DxDataGridModule,
    NgxGlideModule,
    DxiValidationRuleModule,
    DxScrollViewModule,
    DxFileUploaderModule,
    DxTextAreaModule,
    DxSelectBoxModule,
    DxListModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    Utilities,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
