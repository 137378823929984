<app-header></app-header>
<div class="partners-list container-width">
  <div class="partners">
    <div class="partners-list-grid">
      <h3 class="card-title teal-5">{{ 'salesmanView.title' | translate }}</h3>
      <dx-data-grid
        #partnersGrid
        id="partnersContainer"
        [dataSource]="partnerList"
        [focusedRowIndex]="0"
        (onFocusedRowChanged)="onFocusedRowChanged($event)"
        [customizeColumns]="dataService.defaultColumns"
      >
        <dxo-column-chooser #columnChooser [enabled]="false" mode="select">
          <dxo-position />
        </dxo-column-chooser>
        <dxo-paging [pageSize]="dataService.PAGE_SIZE"> </dxo-paging>

        <dxi-column
          dataField="name"
          [caption]="'salesmanView.column.name' | translate"
          dataType="string"
        >
        </dxi-column>
        <dxi-column
          dataField="username"
          [caption]="'salesmanView.column.username' | translate"
          dataType="string"
        >
        </dxi-column>
        <dxi-column
            dataField="email"
            [caption]="'salesmanView.column.email' | translate"
            dataType="string">
        </dxi-column>
        <dxi-column
          dataField="headquarter"
          [caption]="'salesmanView.column.headquarter' | translate"
          dataType="string"
        >
        </dxi-column>
        <dxi-column
            dataField="vat"
            [caption]="'salesmanView.column.vat' | translate"
            dataType="string">
        </dxi-column>
        <dxi-column cellTemplate="workflowCellTemplate"></dxi-column>
        <div *dxTemplate="let d of 'workflowCellTemplate'">
          <dx-button
            class="secondary btn-xs right"
            (onClick)="startWorkflow($event, d)"
          >
            <i class="bi bi-arrow-right-square"></i
            ><span>{{ 'salesmanView.column.startSessionButton' | translate }}</span>
          </dx-button>
        </div>
      </dx-data-grid>
    </div>
    <div class="partner-details">
      <div>
        <h3 class="card-title teal-5">{{ 'salesmanView.details.title' | translate }}</h3>
        <dx-tab-panel
          class="dx-theme-background-color"
          width="100%"
          [animationEnabled]="true"
          [swipeEnabled]="true"
          tabsPosition="top"
          stylingMode="primary"
          iconPosition="top"
        >
          <dxi-item
            *ngIf="informationPage"
            [title]="'salesmanView.details.companySection.title' | translate"
            class="partner-details-inner"
          > 
            <div class="generic-data-group col-2">
              <div class="generic-data-block">
                <p class="generic-data-block-title">{{ 'salesmanView.details.companySection.name' | translate }}</p>
                <p class="generic-data-block-value">{{ partnerData.name }}</p>
              </div>
              <div class="generic-data-block">
                <p class="generic-data-block-title">{{ 'salesmanView.details.companySection.vat' | translate }}</p>
                <p class="generic-data-block-value">{{ partnerData.vat }}</p>
              </div>
              <div class="generic-data-block">
                <p class="generic-data-block-title">{{ 'salesmanView.details.companySection.contactName' | translate }}</p>
                <p class="generic-data-block-value">{{ partnerData.contactName }}</p>
              </div>
              <div class="generic-data-block">
                <p class="generic-data-block-title">{{ 'salesmanView.details.companySection.contactTel' | translate }}</p>
                <p class="generic-data-block-value">{{ partnerData.contactTel }}</p>
              </div>
              <div class="generic-data-block">
                <p class="generic-data-block-title">{{ 'salesmanView.details.companySection.currentOrdersCount' | translate }}</p>
                <p class="generic-data-block-value">{{ partnerData.currentOrders! | ceNumberFormat }}</p>
              </div>
              <div class="generic-data-block" *ngIf="partnerData.lastYearOrders">
                <p class="generic-data-block-title">{{ 'salesmanView.details.companySection.lastYearOrdersCount' | translate }}</p>
                <p class="generic-data-block-value">{{ partnerData.lastYearOrders.count! | ceNumberFormat }}</p>
              </div>
              <div class="generic-data-block" *ngIf="partnerData.lastYearOrders">
                <p class="generic-data-block-title">{{ 'salesmanView.details.companySection.lastYearOrdersSum' | translate }}</p>
                <ul class="generic-data-block-value">
                  <li *ngFor="let price of partnerData.lastYearOrders?.prices">
                    {{ price.price! | ceNumberFormat }} {{ price.currency }}
                  </li>
                </ul>
              
              </div>
              <div class="generic-data-block" *ngIf="partnerData.currentYearOrders">
                <p class="generic-data-block-title">{{ 'salesmanView.details.companySection.currentYearOrdersSum' | translate }}</p>
                <ul class="generic-data-block-value">
                  <li *ngFor="let price of partnerData.currentYearOrders?.prices">
                    {{ price.price! | ceNumberFormat }} {{ price.currency }}
                  </li>
                </ul>
              </div>
              <div class="generic-data-block" *ngIf="partnerData.allOrders">
                <p class="generic-data-block-title">{{ 'salesmanView.details.companySection.allOrdersSum' | translate }}</p>
                <ul class="generic-data-block-value">
                  <li *ngFor="let price of partnerData.allOrders?.prices">
                    {{ price.price! | ceNumberFormat }} {{ price.currency }}
                  </li>
                </ul>
              </div>
            </div>
          </dxi-item>
          <dxi-item [title]="'salesmanView.details.userSection.title' | translate" class="partner-details-inner">
            <div class="generic-data-group col-2">
              <div class="generic-data-block">
                <p class="generic-data-block-title">{{ 'salesmanView.details.userSection.username' | translate }}</p>
                <p class="generic-data-block-value">{{ partnerData.partnerUser?.username }}</p>
              </div>
              <div class="generic-data-block">
                <p class="generic-data-block-title">{{ 'salesmanView.details.userSection.email' | translate }}l</p>
                <p class="generic-data-block-value">{{ partnerData.partnerUser?.email }}</p>
              </div>
              <div class="generic-data-block">
                <p class="generic-data-block-title">{{ 'salesmanView.details.userSection.language' | translate }}</p>
                <p class="generic-data-block-value">{{ partnerData.partnerUser?.language }}</p>
              </div>
              <div class="generic-data-block">
                <p class="generic-data-block-title">{{ 'salesmanView.details.userSection.comment' | translate }}</p>
                <p class="generic-data-block-value">{{ partnerData.partnerUser?.comment }}</p>
              </div>
              <div class="generic-data-block">
                <p class="generic-data-block-title">{{ 'salesmanView.details.userSection.rights' | translate }}</p>
                <ul class="generic-data-block-value">
                  <li
                    *ngFor="let authParams of partnerData.partnerUser?.authParams"
                  >
                    {{ authParamTranslate(authParams) }}
                  </li>
                </ul>
              </div>
              <div class="generic-data-block">
                <p class="generic-data-block-title">{{ 'salesmanView.details.userSection.notificationEmailAddresses' | translate }}</p>
                <ul class="generic-data-block-value">
                  <li
                    *ngFor="
                      let email of partnerData.partnerUser
                        ?.notificationEmailAddresses
                    "
                  >
                    {{ email }}
                  </li>
                </ul>
              </div>

            </div>

          </dxi-item>
        </dx-tab-panel>
      </div>
    </div>
  </div>
</div>
