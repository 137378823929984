import { Component } from '@angular/core';
import { StandsService } from '../stands.service';
import { StandMoveDto } from '../../../../../../../shared/models/stand-move-dto';
import { AppRouteNames } from '../../../../app-route-names';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-stand-single-view',
  templateUrl: './stand-single-view.component.html',
  styleUrl: './stand-single-view.component.less',
})
export class StandSingleViewComponent {
  isLoaded: boolean = false;
  singleStand?: StandMoveDto;
  constructor(
    private route: ActivatedRoute,
    private standsService: StandsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.standsService.fetchStandDetails(params['id']).subscribe({
        next: (stand) => {
          this.singleStand = stand;
          this.isLoaded = true;
        },
        error: (err) => {
          this.router.navigate([AppRouteNames.PARTNER_INVOICES]);
        },
      });
    });
  }
}
