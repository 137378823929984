import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { DxiDataGridColumn } from 'devextreme-angular/ui/nested/base/data-grid-column-dxi';
import { LoadOptions } from 'devextreme/data';
import CustomStore from 'devextreme/data/custom_store';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { environment } from '../../../shared/environments/environment';
import { lastValueFrom } from 'rxjs';
import { TableFilter } from '../../../shared/models/table-filter';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppRouteNames } from './app-route-names';
import { AppConstants } from '../../../shared/app-constants';
import { TranslateService } from '@ngx-translate/core';
import { QuestionnaireResultDto } from '../../../shared/models/questionnaire-result-dto';
import { QuestionnaireDto } from '../../../shared/models/questionnaire-dto';

export interface StateColumn {
  dataField: string;
  visible: boolean;
  visibleIndex: number;
  width: number;
}
export interface ValidateFile {
  valid: boolean;
  fileSize: string;
}
@Injectable({ providedIn: 'root' })
export class DataService {
  readonly ALLOWED_PAGE_SIZES = [10, 25, 50, 100];
  readonly PAGE_SIZE = 25;
  readonly DEFAULT_DATEFORMAT = 'yyyy.MM.dd.';
  readonly MENU = [
    {
      menuName: 'header.main',
      iconClassName: 'house-door',
      routerLink: AppRouteNames.PARTNER_DASHBOARD,
      menuParam: 'DASHBOARD',
    },
    {
      menuName: 'header.orders',
      iconClassName: 'cart4',
      routerLink: AppRouteNames.PARTNER_ORDERS,
      menuParam: 'ORDERS',
    },
    {
      menuName: 'header.invoices',
      iconClassName: 'file-earmark-medical',
      routerLink: AppRouteNames.PARTNER_INVOICES,
      menuParam: 'INVOICES',
    },
    {
      menuName: 'header.stands',
      iconClassName: 'bookshelf',
      routerLink: AppRouteNames.PARTNER_STAND_GLASSES,
      menuParam: 'STAND_GLASSES',
    },
    {
      menuName: 'header.documents',
      iconClassName: 'file-earmark',
      routerLink: AppRouteNames.PARTNER_DOCUMENTS,
      menuParam: 'DOCUMENTS',
    },
    {
      menuName: 'header.contacts',
      iconClassName: 'telephone',
      routerLink: AppRouteNames.PARTNER_CONNECTIONS,
      menuParam: 'CONNECTIONS',
    },
    {
      menuName: 'header.complaints',
      iconClassName: 'chat-left-quote',
      routerLink: AppRouteNames.PARTNER_COMPLAINTS,
      menuParam: 'COMPLAINTS',
    },
    {
      menuName: 'header.questionnaire',
      iconClassName: 'question-circle',
      routerLink: AppRouteNames.PARTNER_QUESTIONNAIRE,
      menuParam: 'QUESTIONNAIRE',
    },
    {
      menuName: 'header.profile',
      iconClassName: 'person-circle',
      routerLink: AppRouteNames.PARTNER_PROFILE,
      menuParam: 'PROFILE',
    },
  ];

  constructor(
    private http: HttpClient,
    private deviceService: DeviceDetectorService,
    private translate: TranslateService
  ) {}

  defaultGrid = (grid: DxDataGridComponent, storageKey: string) => {
    grid.instance.beginUpdate();

    grid.showBorders = true;
    grid.focusedRowEnabled = true;
    grid.remoteOperations = true;
    grid.syncLookupFilterValues = false;
    grid.allowColumnReordering = true;
    grid.allowColumnResizing = true;
    grid.columnResizingMode = 'widget';
    if (this.isMobile()) {
      grid.columnMinWidth = 40;
    } else {
      grid.columnMinWidth = 40;
    }
    grid.width = '100%';
    grid.height = '800px';

    grid.scrolling.showScrollbar = 'always';

    grid.pager.visible = true;
    grid.pager.allowedPageSizes = this.ALLOWED_PAGE_SIZES;
    grid.pager.displayMode = 'full';
    grid.pager.showPageSizeSelector = true;
    grid.pager.showInfo = false;
    grid.pager.showNavigationButtons = true;

    grid.filterRow.visible = true;
    grid.instance.endUpdate();

    let columns = this.stateStoringLoad(storageKey);
    if (columns != undefined) {
      columns.forEach((sc: StateColumn) => {
        grid.instance.columnOption(sc.dataField, 'visible', sc.visible);
        grid.instance.columnOption(
          sc.dataField,
          'visibleIndex',
          sc.visibleIndex
        );
        grid.instance.columnOption(sc.dataField, 'width', sc.width);
      });
    }
  };

  defaultColumns = (columns: Array<DxiDataGridColumn>) => {
    columns.forEach((column) => {
      column.filterOperations = [];
      column.editorOptions = { showClearButton: 'true' };
      column.alignment = 'left';
      if (column.dataType == 'date') {
        column.filterOperations = ['=', 'between', '<', '>'];
      }
      if (column.dataType == 'date') {
        column.format = this.DEFAULT_DATEFORMAT;
      }
      if (column.dataType == 'number') {
        column.filterOperations = ['=', '<', '>'];
        column.format = '###,###,###,##0.##';
      }
      if (column.dataType == 'string') {
        if (column.lookup == undefined) {
          column.filterOperations = ['contains', '='];
        } else {
          column.editorOptions = {};
        }
      }
      if (column.dataType == 'boolean') {
        column.cellTemplate = (container: any, options: any): any => {
          if (options.displayValue == true) {
            let i = document.createElement('i');
            i.className = 'bi bi-check-circle';
            container.appendChild(i);
          }
        };
        column.showEditorAlways = false;
        column.falseText = this.translate.instant('common.no');
        column.trueText = this.translate.instant('common.yes');
        column.editorOptions = {};
      }
    });
  };
  stateStoringSave = (key: string, state: any) => {
    let columns: StateColumn[] = [];
    state.columns.forEach((sc: StateColumn) => {
      let nc: StateColumn = {
        dataField: sc.dataField,
        visibleIndex: sc.visibleIndex,
        width: sc.width,
        visible: sc.visible,
      };
      columns.push(nc);
    });
    localStorage.setItem(key, JSON.stringify(columns));
  };

  private stateStoringLoad = (key: string): any => {
    let data = localStorage.getItem(key);
    if (data == undefined) return;
    return JSON.parse(data);
  };

  exportToExcel(dataGridInstance: any, name: string) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(name);
    const currentDate = this.formatDateToFileName();

    exportDataGrid({
      component: dataGridInstance,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer: any) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `ce_glass_${name}_${currentDate}.xlsx`
        );
      });
    });
  }

  formatDateToFileName() {
    const today = new Date();
    return (
      today.getFullYear() +
      '_' +
      String(today.getMonth() + 1).padStart(2, '0') +
      '_' +
      String(today.getDate()).padStart(2, '0')
    );
  }

  fetchCustomStore = (
    http: HttpClient,
    getUrl: string = '',
    listId: string = ''
  ): CustomStore => {
    const isNotEmpty = (value: unknown) =>
      value !== undefined && value !== null && value !== '';
    return new CustomStore({
      key: listId,
      async load(loadOptions: LoadOptions | any) {
        try {
          const url = `${environment.apiUrl}api/${getUrl}`;

          const paramNames: string[] | any[] = [
            'skip',
            'take',
            'requireTotalCount',
          ];

          let urlBody: TableFilter | any = <TableFilter>{};

          paramNames
            .filter((paramName) => isNotEmpty(loadOptions[paramName]))
            .forEach((paramName: string) => {
              urlBody[paramName] = JSON.stringify(loadOptions[paramName]);
            });

          if (loadOptions.sort != undefined) {
            urlBody.sortFiled = loadOptions.sort[0].selector;
            urlBody.sortDesc = loadOptions.sort[0].desc;
          }
          if (isNotEmpty(loadOptions['filter'])) {
            if (Array.isArray(loadOptions['filter'][0])) {
              urlBody['filter'] = loadOptions['filter'];
            } else {
              urlBody['filter'] = new Array(loadOptions['filter']);
            }
          }

          const result = (await lastValueFrom(
            http.post(url, urlBody)
          )) as Record<string, unknown>;

          return {
            data: result['data'],
            totalCount: result['totalCount'],
            summary: result['summary'],
          };
        } catch (err) {
          throw new Error('Data Loading Error');
        }
      },
    });
  };

  downloadFile(blob: Blob, name: any, extension: string = 'pdf') {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${name}.${extension}`;
    link.click();
    window.URL.revokeObjectURL(url);
  }

  getActiveQuestionnaire() {
    return this.http.get<QuestionnaireDto>(
      `${environment.apiUrl}api/questionnaire/get`
    );
  }

  isMobile() {
    return this.deviceService.isMobile();
  }

  isTablet() {
    return this.deviceService.isTablet();
  }

  isDesktop() {
    return this.deviceService.isDesktop();
  }

  validateFiles = (e: any): ValidateFile => {
    let returnData: ValidateFile = { valid: true, fileSize: '0' };
    let fileTypeIsValid;
    let fileSizeIsValid;
    let sum = 0;
    if (e.value.length > 0) {
      fileTypeIsValid = e.value.every((file: File) => {
        AppConstants.ACCEPTED_FILE_TYPE.includes(file.type);
      });
      sum = e.value.reduce((acc: any, num: any) => acc + num['size'], 0);
      returnData.fileSize = (sum / 1048576).toFixed(2);
      fileSizeIsValid = sum > AppConstants.FILE_SIZE_MAXIMUM_MB * 1000000;
    }
    if (e.value.length > AppConstants.FILE_COUNT_LIMIT) {
      e.rule.message = this.translate.instant(
        'common.fileUploader.attachmentsCountError',
        { fileCount: AppConstants.FILE_COUNT_LIMIT }
      );
      returnData.valid = false;
    }
    if (fileTypeIsValid) {
      e.rule.message = this.translate.instant(
        'common.fileUploader.fileTypeIsInvalidError'
      );
      returnData.valid = false;
    }
    if (fileSizeIsValid) {
      e.rule.message = this.translate.instant(
        'common.fileUploader.fileSizeMaxError',
        { fileCount: AppConstants.FILE_SIZE_MAXIMUM_MB }
      );
      returnData.valid = false;
    }
    return returnData;
  };
}
