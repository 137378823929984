import { Component } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-default-route',
  templateUrl: './default-route.component.html',
  styleUrl: './default-route.component.less',
})
export class DefaultRouteComponent {
  user = {
    authLevel: '',
  };
  constructor(private authService: AuthService) {}
}
