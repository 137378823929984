import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OrderSummaryDto } from '../../../../../../../shared/models/order-summary-dto';
import { OrdersService } from '../orders.service';
import { DataService } from '../../../../data.service';

@Component({
  selector: 'app-orders-summary',
  templateUrl: './orders-summary.component.html',
  styleUrl: './orders-summary.component.less',
})
export class OrdersSummaryComponent {
  isSummaryVisible?: boolean = true;
  ordersSummary?: OrderSummaryDto;
  isMobile: boolean = false;
  @Output() setListFullWidth: EventEmitter<any> = new EventEmitter();
  constructor(
    private ordersService: OrdersService,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.ordersService.fetchOrderSummary().subscribe({
      next: (orders) => {
        this.ordersSummary = orders;
      },
    });
    this.ordersService.isSummaryVisible.subscribe((isVisible) => {
      this.isSummaryVisible = isVisible;
    });
    this.isMobile = this.dataService.isMobile();
  }

  toggleSummary() {
    this.isSummaryVisible = !this.isSummaryVisible;
    if (this.isSummaryVisible) this.setListFullWidth.emit();
  }

  filterBy(status: string) {
    this.ordersService.filteredOrders.next({
      status: status,
    });
  }
}
