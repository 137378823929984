<div class="breadcrumb-actions-row container-width">
  <app-breadcrumb class="breadcrumb-group"></app-breadcrumb>
</div>
<div class="single-stand single-col-width m-bottom-2">
  <app-spinner *ngIf="!isLoaded"></app-spinner>
  <app-stand-details
    *ngIf="isLoaded"
    [isSingleView]="true"
    [standToDetails]="singleStand"
  ></app-stand-details>
</div>
