import { Injectable } from '@angular/core';
import { environment } from '../../../../../../shared/environments/environment';
import { HttpClient } from '@angular/common/http';
import { SalesmanDto } from '../../../../../../shared/models/salesman-dto';
import { StandDashboardDto } from '../../../../../../shared/models/stand-dashboard-dto';
import { OrderDashboardDto } from '../../../../../../shared/models/order-dashboard-dto';
import { InvoiceDashboardDto } from '../../../../../../shared/models/invoice-dashboard-dto';
import { NewsletterSubscriptionDto } from '../../../../../../shared/models/newsletter-subscription-dto';
import { ChangeProfileDto } from '../../../../../../shared/models/change-profile-dto';

@Injectable({ providedIn: 'root' })
export class DashboardService {
  constructor(private http: HttpClient) {}
  getOrdersDashboard() {
    return this.http.get<OrderDashboardDto>(
      `${environment.apiUrl}api/order/dashboard`
    );
  }
  getInvoicesDashboard() {
    return this.http.get<InvoiceDashboardDto>(
      `${environment.apiUrl}api/invoice/dashboard`
    );
  }
  getStandsDashboard() {
    return this.http.get<StandDashboardDto>(
      `${environment.apiUrl}api/stand/dashboard`
    );
  }
  isSubscribed() {
    return this.http.get<Boolean>(
      `${environment.apiUrl}api/newsletter/is-subscribed`
    );
  }
  subscribeToNewsletter(dto: NewsletterSubscriptionDto) {
    return this.http.post(`${environment.apiUrl}api/newsletter/subscribe`, dto);
  }
  getQuotes() {
    return this.http.get('/assets/quotes.json');
  }
  getImages() {
    return this.http.get('/assets/references.json');
  }
  getDocuments() {
    return this.http.get('/assets/documents.json');
  }

  sendChangeProfileMessage(dto: ChangeProfileDto) {
    return this.http.post(`${environment.apiUrl}api/user/change-profile`, dto);
  }

  getPartnerSalesman() {
    return this.http.get<SalesmanDto>(
      `${environment.apiUrl}api/partner/my-salesman`
    );
  }

  sendInterest(message: string) {
    return this.http.post(
      `${environment.apiUrl}api/order/interest?message=${message}`,
      ''
    );
  }

  getRandomItems(array: any[], count: number) {
    let newArray: any[] = [];
    while (newArray.length < count) {
      let randomIndex = Math.floor(Math.random() * array.length);
      if (!newArray.includes(array[randomIndex]))
        newArray.push(array[randomIndex]);
    }
    return newArray;
  }
}
