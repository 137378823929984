import { QuestionnaireDto } from './models/questionnaire-dto';

export interface CustomFormType {
  id?: number;
  name: string;
  description: string;
  internalName: string;
  status: 'RUNNING' | 'STOPPED' | 'PREVIEW';
  language: 'HU' | 'EN' | 'RO' | 'SR';
  form: FormObject[];
}

export interface FormObject {
  type: string;
  title: string;
  isRequired?: boolean;
  max?: number;
  values?: any[];
}

export class Utilities {
  convertDtoToCustomType(dtoObj: QuestionnaireDto): CustomFormType {
    return {
      ...dtoObj,
      form: JSON.parse(dtoObj.form),
    };
  }

  convertCustomTypeToDto(customObj: CustomFormType): QuestionnaireDto {
    return {
      ...customObj,
      form: JSON.stringify(customObj.form),
    };
  }
}
